import React, { useState } from 'react';
import { Typography, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const QuestionsAndAnswersItem = ({ question, answer, index }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box style={{ 
      padding: '10px 15px', 
      marginBottom: '10px', 
      backgroundColor: (index % 2 == 0) ? '#ffffff' : '#f4f4f4',
      borderRadius: '8px'
    }}
    onClick={() => setExpanded(!expanded)}>
      <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
      >
        <Typography style={{ fontSize: '0.8em', fontWeight: '700', color: '#444444' }} variant="body1">{question}</Typography>
        <div style={{ 
            paddingLeft: '5px' 
          }}>
          {expanded ? <ExpandLessIcon style={{ color: '#888888' }} /> : <ExpandMoreIcon style={{ color: '#888888' }} />}
        </div>
      </div>
      {
        expanded &&
        (
          <div>
            <Typography style={{ fontSize: '0.8em', fontWeight: '500', color: '#777777' }} variant="body1">{answer}</Typography>
          </div>
        )
      }
    </Box>
  );
};

const QuestionsAndAnswers = () => {
  const data = [
    {
      "question": "O que é a Estudo Inteligente?",
      "answer": "A Estudo Inteligente é uma plataforma que utiliza inteligência artificial para criar materiais de estudo personalizados, como mapas mentais, resumos e flashcards, ideal para concurseiros, universitários e estudantes em geral."
    },
    {
      "question": "Como funciona a Estudo Inteligente?",
      "answer": "Você informa o assunto que deseja estudar, e nossa ferramenta gera materiais didáticos personalizados no formato de PDF ou arquivo para o app Anki. Cada material custa 1 crédito, que pode ser adquirido na plataforma."
    },
    {
      "question": "Como posso adquirir créditos?",
      "answer": "Créditos podem ser comprados a qualquer momento e quanto mais você compra, mais barato fica. O pagamento é feito via Pix, e os créditos são adicionados automaticamente à sua conta."
    },
    {
      "question": "Os créditos têm validade?",
      "answer": "Não, os créditos adquiridos na Estudo Inteligente não expiram. Use-os quando achar necessário!"
    },
    {
      "question": "Existe uma forma de testar a Estudo Inteligente gratuitamente?",
      "answer": "Sim, ao se cadastrar você recebe 5 créditos gratuitos para testar a plataforma."
    },
    {
      "question": "O que acontece se houver um erro no material gerado?",
      "answer": "Embora nossa taxa de acerto seja elevada, a possibilidade de erros existe, pois trabalhamos com inteligência artificial. Caso encontre algum erro, você pode utilizar seus créditos para gerar um novo material."
    },
    {
      "question": "Os materiais gerados ficam salvos?",
      "answer": "Sim, todo material gerado fica salvo na sua conta para consulta futura a qualquer momento."
    },
    {
      "question": "Posso importar os flashcards para outros aplicativos?",
      "answer": "Sim, os flashcards são gerados em um formato compatível com o app Anki, permitindo fácil importação."
    },
    {
      "question": "Como o Estudo Inteligente garante a qualidade do conteúdo?",
      "answer": "Utilizamos algoritmos avançados e uma base de dados extensa para garantir que os conteúdos gerados sejam de alta qualidade e confiáveis."
    },
    {
      "question": "Posso sugerir melhorias ou reportar problemas na plataforma?",
      "answer": "Claro! Estamos sempre abertos a feedbacks e sugestões. Entre em contato conosco pelo suporte no site."
    },
    {
      "question": "Como a Estudo Inteligente pode otimizar meu tempo de estudo?",
      "answer": "A plataforma é ideal para quem tem pouco tempo disponível. Em segundos, e com apenas alguns toques na tela do seu celular, você obtém materiais de estudo personalizados que economizam horas de preparação."
    },
    {
      "question": "Existe alguma dica para tornar o Estudo Inteligente mais eficaz?",
      "answer": "Para um uso eficiente, recomendamos copiar e colar informações diretamente do edital do concurso no Estudo Inteligente. Assim, você garante que o material gerado esteja alinhado com o que será cobrado na prova."
    },
    {
      "question": "Como o Estudo Inteligente garante a qualidade do conteúdo gerado?",
      "answer": "Utilizamos tecnologia de IA avançada que, juntamente com uma extensa base de dados, cria conteúdos de alta qualidade e confiáveis, prontos para auxiliar em sua preparação."
    },
    {
      "question": "Posso personalizar os materiais de estudo?",
      "answer": "Sim, ao inserir o conteúdo exatamente como você precisa, a plataforma adapta os materiais de estudo para atender às suas especificações."
    },
    {
      "question": "Como posso entrar em contato para suporte ou para dar feedback?",
      "answer": "Estamos sempre prontos para ajudar e ouvir suas sugestões. Para qualquer dúvida, crítica ou elogio, você pode entrar em contato diretamente conosco pelo suporte na plataforma."
    }
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container component="main" maxWidth="md"> 
      <div style={{ margin: '30px 0 20px 0' }}>
        <Typography component="h3" style={{ color: '#556c8a', fontWeight: '600', marginBottom: '20px', fontSize: (isMobile ? '16px' : '18px') }}>
          Perguntas e Respostas
        </Typography>
        {
          data.map((item, index) => (
            <QuestionsAndAnswersItem 
              question={item.question}
              answer={item.answer}
              index={index}
              key={index}
            />
          ))
        }
      </div>
    </Container>
  );
};

export default QuestionsAndAnswers;
