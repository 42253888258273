import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Button, Tooltip, IconButton, Divider, Box, TextField, Container, Typography, Card, CardHeader, CardContent, CardActions, FormControl, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import useSnackbar from './useSnackbar';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useUser } from './UserContext';
import { useLocation } from 'react-router-dom';
import SelectFolder from './SelectFolder';
import { MindMapIcon } from './MainIcons';

function NewMindMapFromConten() {
  const token = localStorage.getItem('token');
  const [text, setText] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const { user, fetchUser } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folder = queryParams.get('folder');
  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(folder);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pasta.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pasta.');
    }

    setFetchingAllFolders(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipToggle = () => {
    if (!openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 5000);
    }

    setOpenTooltip(!openTooltip);
  };

  const CONTENT_LIMIT = 2500;

  const handleGeneratePDF = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests', { type: 'mindmap', text, content, folder: currentFolderId }, {
        headers: { Authorization: token }
      });

      const requestsIds = response.data.requestsIds;

      setTimeout(() => {
        navigate(`/app/folders${currentFolderId ? ('?folder=' + currentFolderId) : ''}`);
      }, 3000);

      setMessageInfo('Em alguns segundos seu material estará pronto...');

      setTimeout(async () => {
        try {
          await fetchUser();
        } catch(err) { }
      }, 200);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }

    setLoading(false);
  };

  const handleContentChange = (e) => {
    const newContent = e.target.value;
    if (newContent.length <= CONTENT_LIMIT) {
      setContent(newContent);
    } else if (newContent.length > (CONTENT_LIMIT + 1) ) {
      setContent(newContent.substring(0, CONTENT_LIMIT));
    }
  };

  useEffect(() => {
    fetchAllFolders();
  }, []);

  useEffect(() => {
    if (!currentFolderId) {
      setCurrentFolderId((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);

  return (
      <>
      {
        /*
        <Typography component="h3" variant="h3" style={{ textAlign: 'center', fontSize: (isMobile ? '1.05rem' : '1.18rem'), fontWeight: '400', color: '#1671dc', marginTop: '30px', marginBottom: '15px' }}>
          { (user && user.name) ? `${user.name.split(' ')[0]}` : '...' }, você tem <span style={{ fontWeight: '700', marginLeft: '5px' }}>{(user && user.credits != null) ? `${user.credits} ` : '... ' } crédito{(user && user.credits == 1) ? '' : 's' }</span>
        </Typography>
        */
      } 
      <Container component="main" maxWidth="sm" style={{ marginBottom: '30px' }}>
        <Card sx={{ 
          marginTop: '15px',
          '@media (min-width:600px)': {
            marginTop: '40px' 
          } 
        }}>
          <CardHeader
            sx={{
              position: 'relative',
              paddingLeft: '20px',
              '&::before': {
                content: '""', 
                position: 'absolute',
                left: '-3px',
                top: '9px',
                bottom: '9px',
                width: '6px',
                backgroundColor: '#6937ea',
                borderRadius: '3px'
              }
            }}
            title={
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MindMapIcon color="#000000" style={{ marginRight: '6px' }} /> <span>Novo Mapa Inteligente</span>
                </div>
              </>
            }
            subheader={`Insira abaixo o seu ${isMobile ? '' : 'próprio'} conteúdo (${isMobile ? 'máx 2500 caracteres' : 'limitado a 2500 caracteres'}) e em ${isMobile ? '' : 'alguns'} segundos você terá o seu mapa inteligente`}
          />
          <CardContent>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <CustomTextField
                variant="outlined"
                autoComplete="off"
                margin="normal"
                style={{ margin: '0' }}
                fullWidth
                id="text"
                label="Título"
                placeholder="Digite aqui o título"
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </FormControl>

            <FormControl variant="outlined" margin="normal" fullWidth>
              <CustomTextField
                variant="outlined"
                autoComplete="off"
                margin="normal"
                style={{ margin: '0' }}
                fullWidth
                id="content"
                label="Conteúdo"
                placeholder="Copie e cole aqui o seu conteúdo"
                name="content"
                value={content}
                multiline
                rows={8} 
                onChange={handleContentChange}
              />
              <Typography align="right" style={{ fontSize: '12px', marginTop: '8px', color: '#666666', fontWeight: '700' }}>
                {`${content.length}/${CONTENT_LIMIT}`}
              </Typography>
            </FormControl>

            <SelectFolder 
              isLoading={fetchingAllFolders}
              folders={folders} 
              currentFolderId={currentFolderId}
              onSelect={setCurrentFolderId}
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={handleGeneratePDF}
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            >
                {loading ? 'Aguarde...' : 'Gerar Mapa Inteligente'}
            </Button>

            <Typography variant="body1" style={{ fontSize: '12px', fontWeight: '500', color: '#666666', paddingRight: '8px', alignSelf: 'flex-end', paddingTop: '5px' }}>
              Essa operação custa 1 crédito
            </Typography>

            {user && user.credits == 0 && (
              <Button
                onClick={() => navigate('/app/buy-credits')}
                fullWidth
                variant="contained"
                color="info"
                size="medium"
                style={{ marginTop: '8px', marginLeft: '0' }}
              >
                Comprar Créditos
              </Button>
            )}
          </CardActions>
        </Card>

        <Button
          onClick={() => navigate(`/app/new-smartmap${currentFolderId ? ('?folder=' + currentFolderId) : ''}`)}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '8px' }}
        >
          Voltar
        </Button>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />

      </Container>
      </>
  );
}


function NewMindMapFromContentWrapper() {
  return (
    <Suspense fallback={<div>Carregando créditos...</div>}>
      <NewMindMapFromConten />
    </Suspense>
  );
}

export default NewMindMapFromContentWrapper;
