import React, { useState, useRef, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';

function VerificationCodeInput({ onChange }) {
  const [inputs, setInputs] = useState(Array(6).fill(''));
  const firstInputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 100);
  }, []);

  const handleChange = (value, index) => {
    if (!value || (value.length === 1 && /^\d$/.test(value))) {
      const newInputs = [...inputs];
      newInputs[index] = value;
      setInputs(newInputs);
      onChange(newInputs.join(''));

      if (value && index < 5) {
        document.getElementById(`code-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').split('').filter(char => /^\d$/.test(char));
    if (pasteData.length > 0) {
      const newInputs = [...inputs];
      for (let i = 0; i < pasteData.length && i < 6; i++) {
        newInputs[i] = pasteData[i];
      }
      setInputs(newInputs);
      onChange(newInputs.join(''));
      document.getElementById(`code-${pasteData.length - 1}`).focus();
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && inputs[index] === '') {
      const newInputs = [...inputs];
      newInputs[index - 1] = '';
      setInputs(newInputs);
      document.getElementById(`code-${index - 1}`).focus();
    }
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      {inputs.map((input, index) => (
        <Grid item key={index} xs style={{ maxWidth: '50px' }}>
          <TextField
            ref={index === 0 ? firstInputRef : null}
            autoFocus={index === 0}
            id={`code-${index}`}
            type="tel"
            inputProps={{ maxLength: 1, style: { textAlign: 'center', width: '100%' } }}
            value={input}
            onChange={(e) => handleChange(e.target.value, index)}
            onPaste={handlePaste}
            onKeyUp={(e) => handleKeyUp(e, index)}
            onFocus={(e) => e.target.select()}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default VerificationCodeInput;
