import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Link, Button, Tooltip, IconButton, FormControl, InputLabel, Select, MenuItem, Divider, Box, TextField, Container, Typography, Card, CardHeader, CardContent, CardActions, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTextField from './CustomTextField';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useUser } from './UserContext';
import { useLocation } from 'react-router-dom';
import SelectFolder from './SelectFolder';
import { FlashcardIcon } from './MainIcons';
import LinkToMaterial from './LinkToMaterial';

function NewFlashcard() {
  const token = localStorage.getItem('token');
  const [text, setText] = useState('');
  const [type, setType] = useState('gaps');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const { user, fetchUser } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folder = queryParams.get('folder');
  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(folder);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pasta.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pasta.');
    }

    setFetchingAllFolders(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipToggle = () => {
    if (!openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 6000);
    }

    setOpenTooltip(!openTooltip);
  };

  const handleGeneratePDF = async () => {
    if (text.length > 200) {
      setMessageError('O assunto deve ter no máximo 200 caracteres!');
    }

    setLoading(true);

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests', { type: 'flashcard', text, flashcardType: type, flashcardQuantity: quantity, folder: currentFolderId }, {
        headers: { Authorization: token }
      });

      const requestsIds = response.data.requestsIds;

      setTimeout(() => {
        navigate(`/app/folders${currentFolderId ? ('?folder=' + currentFolderId) : ''}`);
      }, 3500);

      setMessageInfo('Em alguns segundos seu material estará pronto...');

      setTimeout(async () => {
        try {
          await fetchUser();
        } catch(err) { }
      }, 200);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAllFolders();
  }, []);

  useEffect(() => {
    if (!currentFolderId) {
      setCurrentFolderId((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);

  return (
      <>
      {
        /*
        <Typography component="h3" variant="h3" style={{ textAlign: 'center', fontSize: (isMobile ? '1.05rem' : '1.18rem'), fontWeight: '400', color: '#1671dc', marginTop: '30px', marginBottom: '15px' }}>
          { (user && user.name) ? `${user.name.split(' ')[0]}` : '...' }, você tem <span style={{ fontWeight: '700', marginLeft: '5px' }}>{(user && user.credits != null) ? `${user.credits} ` : '... ' } crédito{(user && user.credits == 1) ? '' : 's' }</span>
        </Typography>
        */
      }
      <Container component="main" maxWidth="xs" style={{ marginBottom: '30px' }}> 
        <Card sx={{ 
          marginTop: '15px',
          '@media (min-width:600px)': {
            marginTop: '40px' 
          } 
        }}>
          <CardHeader
            sx={{
              position: 'relative',
              paddingLeft: '20px',
              '&::before': {
                content: '""', 
                position: 'absolute',
                left: '-3px',
                top: '9px',
                bottom: '9px',
                width: '6px',
                backgroundColor: '#499c6a',
                borderRadius: '3px'
              }
            }}
            title={
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FlashcardIcon color="#000000" style={{ marginRight: '6px' }} /> <span>Novo Flashcard</span>
                  <Tooltip 
                    title="Os flashcards são cartões criados para serem utilizados no aplicativo Anki. Nossa aplicação gera arquivos no formato .apkg que precisarão ser importados no app."
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <IconButton size="small" style={{ verticalAlign: 'middle', color: '#888888' }} onClick={handleTooltipToggle}>
                      <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.95rem'}} />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            }
            subheader={`Insira abaixo um assunto ${isMobile ? '' : 'de sua escolha'} e criaremos em segundos o seu baralho compativel com o Anki ${isMobile ? '' : '(.apkg)'}`}
          />
          <CardContent style={{ padding: '0' }}>
            <Box style={{ padding: '16px' }}>
              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <FormControl variant="outlined" margin="normal" fullWidth style={{ marginRight: '4px'}}>
                  <InputLabel id="type-label">Tipo do flashcard</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={type}
                    required
                    onChange={(e) => setType(e.target.value)}
                    label="Tipo do flashcard"
                  >
                    <MenuItem value={"gaps"}>Lacunas</MenuItem>
                    <MenuItem value={"questions_and_answers"}>Perguntas e Respostas</MenuItem>
                  </Select>
                </FormControl>
                </Box>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <FormControl variant="outlined" margin="normal" fullWidth style={{ marginLeft: '4px'}}>
                    <InputLabel id="quantity-label">Cartas</InputLabel>
                    <Select
                      labelId="quantity-label"
                      id="quantity"
                      value={quantity}
                      required
                      onChange={(e) => setQuantity(e.target.value)}
                      label="Cartas"
                    >
                      <MenuItem value={"10"}>10</MenuItem>
                      <MenuItem value={"20"}>20</MenuItem>
                      <MenuItem value={"30"}>30</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <CustomTextField
                variant="outlined"
                autoComplete="off"
                margin="normal"
                fullWidth
                id="text"
                label="Assunto"
                placeholder="Digite aqui o assunto"
                name="text"
                value={text}
                style={{ marginBottom: '0' }}
                onChange={(e) => setText(e.target.value)}
              />

              <div style={{ marginTop: '20px' }}>
                <SelectFolder 
                  isLoading={fetchingAllFolders}
                  folders={folders} 
                  currentFolderId={currentFolderId}
                  onSelect={setCurrentFolderId}
                />
              </div>
            </Box>

            <Box style={{ 
              flexDirection: 'column', 
              padding: '12px',
              paddingLeft: '24px', 
              backgroundColor: '#f6f6f6'
            }}>
              <Typography variant="body1" style={{ fontSize: '11px', fontWeight: '600', color: '#222222' }}>
                Exemplos:
              </Typography>
              <Typography variant="body1" style={{ fontSize: '11px', fontWeight: '500', color: '#666666' }}>
                <LinkToMaterial isMobile={isMobile} text="Poder Constituinte (Direito Constitucional)" url="https://aprend-pedidos.s3.amazonaws.com/pdfs/poder_constituinte_(direito_constitucion_20231128040635_0.pdf" />
              </Typography>
              <Typography variant="body1" style={{ fontSize: '11px', fontWeight: '500', color: '#666666' }}>
                <LinkToMaterial isMobile={isMobile} text="Direito Administrativo - Conceitos e Definições" url="https://aprend-pedidos.s3.amazonaws.com/pdfs/direito_administrativo_-_conceitos_e_def_20231128023427_0.pdf" />
              </Typography>
            </Box>

          </CardContent>
          <CardActions>
            <Button
              onClick={handleGeneratePDF}
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            >
                {loading ? 'Aguarde...' : 'Gerar Flashcard'}
            </Button>

            <Typography variant="body1" style={{ fontSize: '12px', fontWeight: '500', color: '#666666', paddingRight: '8px', alignSelf: 'flex-end', paddingTop: '5px' }}>
              Essa operação custa 1 crédito
            </Typography>

            {user && user.credits == 0 && (
              <Button
                onClick={() => navigate('/app/buy-credits')}
                fullWidth
                variant="contained"
                color="info"
                size="medium"
                style={{ marginTop: '8px', marginLeft: '0' }}
              >
                Comprar Créditos
              </Button>
            )}
          </CardActions>
        </Card>

        <Button
          onClick={() => navigate(`/app/folders${currentFolderId ? ('?folder=' + currentFolderId) : ''}`)}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '8px' }}
        >
          Voltar
        </Button>

        <Button
          onClick={() => navigate(`/app/new-flashcard-from-content${currentFolderId ? ('?folder=' + currentFolderId) : ''}`)}
          fullWidth
          color="info"
          variant="contained"
          size="medium"
          style={{ marginTop: '8px' }}
        >
          Quero usar meu próprio conteúdo
        </Button>

        {
          /*
          <div style={{ fontSize: '13px', textAlign: 'justify', background: '#dbf0f3', color: '#134850', padding: '10px 18px', borderRadius: '8px', marginTop: '15px',  marginBottom: '20px' }}>
            <h4 style={{ fontSize: '13px', fontWeight: '700', margin: '0' }}>Como usar?</h4>
            Os flashcards são cartões criados para serem utilizados no aplicativo <strong>Anki</strong>. Nossa aplicação gera arquivos no formato <strong>.apkg</strong> que precisarão ser importados no app.
          </div>
          */
        }

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
        
      </Container>
      </>
  );
}

function NewFlashcardWrapper() {
  return (
    <Suspense fallback={<div>Carregando créditos...</div>}>
      <NewFlashcard />
    </Suspense>
  );
}

export default NewFlashcardWrapper;
