import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { createRoot } from "react-dom/client";
import MindElixir from "mind-elixir";
import { useNavigate, useParams } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import useSnackbar from './useSnackbar';
import { useLayout } from './LayoutContext';
import { Link, Menu, MenuItem, Skeleton, IconButton, Tooltip, Button, Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme, useMediaQuery } from '@mui/material';
import { MindMapLeftIcon, MindMapRightIcon, MindMapSideIcon } from './ToolbarIcons';
import { Pdf16Icon, Summary16Icon, Edit16Icon, FolderIcon, 
  HierachicalStructureIcon, RenameIcon, SummaryIcon, PdfIcon,
  CommandIcon, MindMapIcon, AddBranchIcon, LeftArrowIcon, 
  RightArrowIcon, LeftRightArrowIcon, MaximizeIcon, MinimizeIcon,
  ClassModeOffIcon, ClassModeOnIcon, LoupeIcon, CancelLoupeIcon } from './MainIcons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import SelectFolder from './SelectFolder';
import VirtualizedAutocomplete from './VirtualizedAutocomplete';
import '@toast-ui/editor/dist/toastui-editor.css';

import { Editor } from '@toast-ui/react-editor';

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const DetailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={18}
    height={18}
    viewBox="0 0 24 24"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    {...props}
  >
    <path
      fill="#fff"
      d="M18 1H6a5.006 5.006 0 0 0-5 5v8a5.009 5.009 0 0 0 4 4.9V22a1 1 0 0 0 1.555.832L12.3 19H18a5.006 5.006 0 0 0 5-5V6a5.006 5.006 0 0 0-5-5zm-2 12H8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2zm2-4H6a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z"
      data-original="#000000"
    />
  </svg>
);

const getColorFromType = (type) => {
  switch (type) {
    case 'mindmap':
      return '#6937ea';
    case 'summary':
      return '#b1900e';
    case 'flashcard':
      return '#499c6a';
    default:
      return '#666666';
  }
};

const ToolbarButton = ({ id, color, iconColor, icon, svgIcon, subItemsMultiSelect, text, tooltip, textMobile, isMobile, disabled, subItems, subMenuAnchorOrigin, subMenuTransformOrigin, onClick }) => {
  let iconStyle = {};
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickSubmenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSubmenu = () => {
    setAnchorEl(null);
  };
  
  if (iconColor) {
    iconStyle.color = iconColor;    
  }

  const handleClick = async (e) => {
    if (subItems && subItems.length > 0) {
      await handleClickSubmenu(e);
    }

    if (onClick) {
      await onClick(e);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title={tooltip}>
        <span className={`btn-toolbar ${disabled?'btn-toolbar-disabled':''}`} id={id} onClick={handleClick}>
          {
            icon && (
              <i style={iconStyle} className={`bx ${icon.startsWith('bx') ? icon : ('bx-'+icon)}`} />
            )
          }
          {
            !icon && svgIcon && svgIcon()
          }
          {(text) && (!isMobile ? <span>{text}</span> : (textMobile && <span>{textMobile}</span>))}
        </span>
      </Tooltip>
      {
        subItems && (subItems.length > 0) &&
        (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseSubmenu}
            anchorOrigin={subMenuAnchorOrigin || {
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={subMenuTransformOrigin || {
              vertical: 'top',
              horizontal: 'left',
            }}
          >
          {
            subItems.filter(subItem => !!subItem && subItem != null).map((subItem, index) => (
              <MenuItem 
                key={index} 
                onClick={(e) => { if (subItem.onClick) { subItem.onClick(e); } handleCloseSubmenu(); } }
                sx={{
                  ...(subItemsMultiSelect ? { padding: '6px 10px 6px 24px' } : {}),
                  ...(subItem.selected ? (
                    subItemsMultiSelect ?
                      {
                      '&::before': {
                        content: '""', 
                        position: 'absolute',
                        left: '8px',
                        top: '7px',
                        bottom: '7px',
                        width: '6px',
                        backgroundColor: subItem.color ? subItem.color : '#2838c2',
                        borderRadius: '4px'
                      },
                    } : {
                      color: '#2838c2',
                      '&::before': {
                        content: '""', 
                        position: 'absolute',
                        left: '-4px',
                        top: '5px',
                        bottom: '5px',
                        width: '8px',
                        backgroundColor: subItem.color ? subItem.color : '#2838c2',
                        borderRadius: '4px'
                      },
                    }) : (subItemsMultiSelect ?
                      {
                      '&::before': {
                        content: '""', 
                        position: 'absolute',
                        left: '8px',
                        top: '7px',
                        bottom: '7px',
                        width: '6px',
                        backgroundColor: '#ffffff',
                        border: '0.5px solid #999999',
                        borderRadius: '4px'
                      },
                    } : {
                    '&::before': {
                      content: '', 
                      left: '0',
                      top: '0',
                      width: '0',
                    },
                  }) ),
                  '&.Mui-focusVisible': {
                    '&::before': {
                      content: '', 
                      left: '0',
                      top: '0',
                      width: '0',
                    },
                  },
                  '&.Mui-focusVisible.Mui-selected': {
                    '&::before': {
                      content: '', 
                      left: '0',
                      top: '0',
                      width: '0',
                    },
                  }
                }}
              >
                {
                  (subItem.icon || subItem.svgIcon) &&
                  (
                    <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                      {
                        subItem.icon && (
                          <i style={subItem.iconStyle} className={`bx ${subItem.icon.startsWith('bx') ? subItem.icon : ('bx-'+subItem.icon)}`} />
                        )
                      }
                      {
                        !subItem.icon && subItem.svgIcon && subItem.svgIcon()
                      }
                    </span>
                  )
                }
                {
                  (
                    (subItem.text && subItem.text != '') ||
                    (subItem.textMobile && subItem.textMobile != '')
                  ) &&
                  <span>{ (isMobile && subItem.textMobile && subItem.textMobile != '') ? subItem.textMobile : subItem.text }</span>  
                }
              </MenuItem>
            ))
          }
          </Menu>
        )
      }       
    </div>
  );
};

const mindmapChanged = (initialMindmapData, _mind) => {
  const currentMindmapData = getImportantDataFromNodeDataStringified(_mind.getData().nodeData);
  return (initialMindmapData != currentMindmapData);
};

const getImportantDataFromNodeData = nodeData => {
  return {
    id: nodeData.id,
    topic: nodeData.topic,
    detail: nodeData.detail,
    children: (nodeData.children || []).map(child => getImportantDataFromNodeData(child))
  };
};

const getImportantDataFromNodeDataStringified = nodeData => {
  return nodeData ? JSON.stringify(getImportantDataFromNodeData(nodeData)) : null;
};

const MindmapToolbar = ({ mind, navigate, initialMindmapData, classMode, isMobile, isPortrait, saving, onHandleMaximizeChild, onHandleMinimizeChild, onHandleFocus, onHandleCancelFocus, onHandleChangeClassMode, onHandleChangeDirection, onHandleSave, onOpenEditLink, onOpenEditTitle, onOpenDetail, onOpenEditSummary, onOpenEditFlashcards, onOpenShortcuts, onCreateNewMindmap, onOpenLinkMindmap, onOpenLinkSummary, onOpenLinkFlashcard, selectedNode, selectedNodeData, data, setMessageError, setMessageInfo }) => {

  
  const currentOnKeydown = mind.map.onkeydown;

  mind.map.onkeydown = e => {
    currentOnKeydown(e);

    if (e.key === '.' && (e.metaKey || e.ctrlKey)) {
      onHandleSave({ redirect: false });
    }
  };

  const [fetchingPdf, setFetchingPdf] = useState(false);
  const [dark, setDark] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  //const [moreActionsVisible, setMoreActionsVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(true);

  const handleAddChild = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }
    mind.addChild();
  };

  const handleAddSibling = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    mind.insertSibling();
  };

  const handleAddParent = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    mind.insertParent();
  };

  const handleRemove = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    mind.removeNode();
  };

  const handleEdit = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    mind.beginEdit();
  };

  const handleLink = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    onOpenEditLink();
  };

  const handleLinkMindmap = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    onOpenLinkMindmap();
  };

  const handleLinkSummary = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    onOpenLinkSummary();
  };

  const handleLinkFlashcard = () => {
    if (!mind.currentNode) {
      setMessageError('Selecione um item');
      return;
    }

    onOpenLinkFlashcard();
  };

  const handleDetail = () => {
    if (!classMode) {
      if (!mind.currentNode) {
        setMessageError('Selecione um item');
        return;
      }

      onOpenDetail();
    }
  };

  const handleViewPdf = async () => {
    await onHandleSave({ redirect: false, openPdf: true });
  };

  const isRoot = node => {
    return node.parentElement.tagName === 'ME-ROOT';
  };

  const hasDetail = (selectedNodeData && selectedNodeData.detail && selectedNodeData.detail != '');
  const hasLink = (selectedNodeData && selectedNodeData.hyperLink && selectedNodeData.hyperLink != '');
  const hasMindmap = (selectedNodeData && selectedNodeData.mindmapId && selectedNodeData.mindmapId != '');
  const hasSummary = (selectedNodeData && selectedNodeData.summaryId && selectedNodeData.summaryId != '');
  const hasFlashcard = (selectedNodeData && selectedNodeData.flashcardId && selectedNodeData.flashcardId != '');
  const hasSomeAttachment = (hasDetail || hasLink || hasMindmap || hasSummary || hasFlashcard);

  const cancelFocusItem = (mind && mind.isFocusMode == true) ? { id: 'unfocus', svgIcon: () => <CancelLoupeIcon size={16} color={(selectedNode && !classMode) ? '#bbbbbb' : '#ffffff'} />, tooltip: 'Cancelar foco', onClick: onHandleCancelFocus } : null;
  const moreActionsItem = { 
    id: 'moreAction', 
    icon: 'dots-horizontal-rounded', 
    tooltip: 'Mais ações', 
    subMenuAnchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    }, 
    subMenuTransformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    }, 
    subItems: [
      { text: 'Maximizar filhos', svgIcon: () => <MaximizeIcon color="#30445a" />, onClick: onHandleMaximizeChild },
      { text: 'Minimizar filhos', svgIcon: () => <MinimizeIcon color="#30445a" />, onClick: onHandleMinimizeChild }
    ] 
  };

  const actionsBar = [
    selectedNode && !isRoot(selectedNode) ? { id: 'add_sibling', icon: 'plus', tooltip: 'Adicionar irmão', onClick: handleAddSibling } : null,
    selectedNode ? { id: 'add_child', icon: 'plus-circle', tooltip: 'Adicionar filho', onClick: handleAddChild } : null,
    selectedNode ? { id: 'edit', icon: 'pencil', tooltip: 'Editar', onClick: handleEdit } : null,
    selectedNode ? { id: 'attachments', icon: 'link-alt', iconColor: hasSomeAttachment ? '#5dbae8' : '#ffffff', tooltip: 'Vincular', subItemsMultiSelect: true, subItems: [
      { 
        text: 'Complemento', 
        selected: hasDetail, 
        onClick: handleDetail,
        color: '#2838c2'
      },
      { 
        text: 'URL (weblink)', 
        selected: hasLink, 
        onClick: handleLink,
        color: '#2838c2'
      },
      { 
        text: 'Mapa inteligente', 
        selected: hasMindmap, 
        onClick: handleLinkMindmap,
        color: getColorFromType('mindmap')
      },
      { 
        text: 'Resumo', 
        selected: hasSummary, 
        onClick: handleLinkSummary,
        color: getColorFromType('summary')
      },
      { 
        text: 'Flashcard', 
        selected: hasFlashcard, 
        onClick: handleLinkFlashcard,
        color: getColorFromType('flashcard')
      },
    ] } : null,
    selectedNode ? cancelFocusItem : null,
    (selectedNode && !isRoot(selectedNode)) ? { id: 'focus', svgIcon: () => <LoupeIcon size={16} color="#ffffff" />, tooltip: 'Foco', onClick: onHandleFocus } : null,
    (selectedNode && !isRoot(selectedNode)) ? { id: 'delete', tooltip: 'Remover', icon: 'trash', onClick: handleRemove } : null,
    selectedNode ? moreActionsItem : null
  ];

  const ltBars = {
    color: '#111111',
    items: [
      selectedNode ? actionsBar : null
    ],
    position: { left: '20px', top: '20px' }
  };

  const historyBar = [
    { id: 'undo', icon: 'undo', tooltip: 'Desfazer', onClick: () => mind.undo() },
    { id: 'redo', icon: 'redo', tooltip: 'Refazer', onClick: () => mind.redo() }
  ];

  const lightAction = { 
    id: 'light', 
    icon: 'sun', 
    tooltip: 'Modo claro', 
    onClick: () => { 
      let theme = MindElixir.THEME;
      theme.cssVar['--bgcolor'] = '#ffffff';
      theme.cssVar['--main-bgcolor'] = '#ffffff';
      theme.cssVar['--root-bgcolor'] = '#2838c2';
      theme.cssVar['--color'] = '#444444';
      theme.cssVar['--main-color'] = '#2938c2';
      theme.cssVar['--selected'] = '#19e0ff';
      mind.changeTheme(theme); 
      setDark(false); 
    } 
  };

  const darkAction = { 
    id: 'dark', 
    icon: 'bxs-sun', 
    tooltip: 'Modo escuro', 
    onClick: () => { 
      let theme = MindElixir.DARK_THEME;
      theme.cssVar['--bgcolor'] = '#222222';
      theme.cssVar['--main-bgcolor'] = '#222222';
      theme.cssVar['--color'] = '#eeeeee';
      theme.cssVar['--main-color'] = '#ffffff';
      theme.cssVar['--selected'] = '#19e0ff';
      mind.changeTheme(theme); 
      setDark(true); 
    } 
  };

  const classModeAction = { 
    id: 'class-mode', 
    svgIcon: classMode ? (() => <ClassModeOnIcon size={16} color="#ffffff" />) : (() => <ClassModeOffIcon size={16} color="#ffffff" />),
    tooltip: classMode ? 'Desativar modo aula' : 'Ativar modo aula', 
    onClick: () => { 
      onHandleChangeClassMode();
    } 
  };

  const layoutBar = [
    cancelFocusItem,
    classModeAction,
    { ...(dark ? lightAction : darkAction), color: '#2938c2' },
    { id: 'layout', svgIcon: () => <HierachicalStructureIcon size={16} color="#ffffff" />, tooltip: 'Organização', subItems: [
      { text: 'Esquerda', svgIcon: () => <LeftArrowIcon size={16} color="#888888" />, selected: mind.direction == 0, onClick: () => { 
        onHandleChangeDirection(MindElixir.LEFT);
        mind.initLeft();
        mind.toCenter();
      } },
      { text: 'Direita', svgIcon: () => <RightArrowIcon size={16} color="#888888" />, selected: mind.direction == 1, onClick: () => { 
        onHandleChangeDirection(MindElixir.RIGHT);
        mind.initRight();
        mind.toCenter();
      } },
      { text: 'Ambos', svgIcon: () => <LeftRightArrowIcon size={16} color="#888888" />, selected: mind.direction == 2, onClick: () => { 
        onHandleChangeDirection(MindElixir.SIDE);
        mind.initSide();
        mind.toCenter();
      } }
    ] }
  ];

  const lt2Bars = {
    items: [
      layoutBar,
      historyBar,
      settingsVisible ? (dark ? lightBar : darkBar) : null,
      (settingsVisible && (!isMobile || !isPortrait)) ? (!(saving || data.request.generatingPdf) ? directionBar : null) : null
    ],
    position: { left: '20px', top: '20px' }
  };

  const lt0Bars = {
    items: [
      [
        classModeAction, 
        cancelFocusItem, 
        selectedNode ? moreActionsItem : null
      ]
    ],
    position: { left: '20px', top: '20px' }
  };

  const detail = (selectedNodeData && selectedNodeData.detail && selectedNodeData.detail != '') ? selectedNodeData.detail : '';
  let detailMobile = detail;

  if (isPortrait) {
    if (detailMobile.length > 103) {
      detailMobile = detailMobile.slice(0, 100) + '...';  
    }
  } else {
    if (detailMobile.length > 223) {
      detailMobile = detailMobile.slice(0, 220) + '...';  
    }
  }

  const detailBar = [
    { id: 'detail-icon', svgIcon: () => <DetailIcon />, tooltip: 'Complemento', onClick: handleDetail },
    (detail != '' && detailVisible) ? { 
      id: 'detail', 
      text: detailVisible ? detail : null, 
      textMobile: detailVisible ? detailMobile : null, 
      onClick: handleDetail 
    } : null,
    (detail != '' && detailVisible) ? { id: 'closeDetail', color: '#2a91a1', icon: 'x', tooltip: 'Fechar', onClick: () => setDetailVisible(false) } : null,
    (detail != '' && !detailVisible) ? { id: 'showDetail', icon: 'chevron-right', tooltip: 'Complemento', onClick: () => setDetailVisible(true) } : null
  ];

  const lt3Bars = {
    items: [
      detailBar
    ],
    color: '#2938c2',
    position: { left: '20px', top: '70px' }
  };

  const menuBar = [
    { id: 'menu', 
      color: '#0e1d51', 
      icon: 'menu', 
      tooltip: 'Menu', 
      subMenuAnchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      }, 
      subMenuTransformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      }, 
      subItems: [
        { text: 'Ir para pasta', svgIcon: () => <FolderIcon size="18" color="#30445a" />, onClick: () => onHandleSave({ redirect: true }) },
        { text: 'Novo mapa', svgIcon: () => <MindMapIcon size="18" color="#30445a" />, onClick: onCreateNewMindmap }
      ] 
    },
    { id: 'shortcuts', icon: 'info-circle', tooltip: 'Atalhos', onClick: onOpenShortcuts }
  ];

  const lbBars = {
    items: [
      !saving && menuBar
    ],
    position: { left: '20px', bottom: '20px' }
  };


  const generalBar = [
    !isMobile ? ({ id: 'fullscreen', icon: 'expand', tooltip: 'Tela cheia', onClick: () => { 
      mind.container.requestFullscreen(); 
      setTimeout(() => { 
        mind.toCenter(); 
      }, 10); 
    } }) : null,
    { id: 'toCenter', icon: 'map', tooltip: 'Centralizar', onClick: () => mind.toCenter() }
  ];

  const zoomBar = [
    { id: 'zoomOut', icon: 'minus', tooltip: 'Zoom -', onClick: () => { if (mind.scaleVal < 0.2) return; mind.scale((mind.scaleVal -= 0.1)); } },
    { id: 'zoomOut', icon: 'plus', tooltip: 'Zoom +', onClick: () => { if (mind.scaleVal > 1.8) return; mind.scale((mind.scaleVal += 0.1)); } },
  ];

  const rbBars = {
    items: [
      !classMode ? generalBar : null,
      zoomBar
    ],
    position: { right: '20px', bottom: '20px' }
  };

  const moreActionsBar = [
    { id: 'moreAction', 
      color: '#0e1d51', 
      icon: 'dots-horizontal-rounded', 
      tooltip: 'Mais ações', 
      subMenuAnchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      }, 
      subMenuTransformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      }, 
      subItems: [
        { text: 'Ver PDF', svgIcon: () => <PdfIcon color="#30445a" />, onClick: handleViewPdf },
        { text: 'Renomear', svgIcon: () => <RenameIcon color="#30445a" />, onClick: onOpenEditTitle }
      ] 
    },
    { id: 'summary', 
      tooltip: 'Resumo', 
      icon: 'file', 
      onClick: onOpenEditSummary 
    },
    { id: 'flashcards', 
      tooltip: 'Perguntas e respostas', 
      icon: 'message-check', 
      onClick: onOpenEditFlashcards 
    },
    { id: 'save', icon: 'save', tooltip: 'Salvar', onClick: () => onHandleSave({ redirect: false }) }
  ];

  const savingBar = [
    { id: 'saving', color: '#000000', svgIcon: () => <CircularProgress style={{ width: '20px', height: '20px', color: '#ffffff', marginRight: '3px' }} />, text: 'Salvando...', disabled: true }
  ];

  const rtBars = {
    items: [
      !(saving || data.request.generatingPdf) && moreActionsBar,
      (saving || data.request.generatingPdf) ? savingBar : null
    ],
    position: { right: '20px', top: '20px' }
  };

  const bars = [
    classMode ? lt0Bars : (selectedNode ? ltBars : lt2Bars),
    selectedNode ? lt3Bars : null,
    !classMode ? lbBars : null,
    !classMode ? rtBars : null,
    rbBars
  ];

  return (
    <div>
      {
        bars.filter(i => !!i && i != null).map((bar, idxBar) => (
          (bar && bar.items && bar.items.length > 0) &&
          (
            <div className="ei-mindmap-toolbar-container" style={{...bar.position}}>
              {
                bar.items.filter(i => !!i && i != null).map((barItems, idxBarItems) => (
                  barItems && (barItems.length > 0) &&
                  (
                    <div key={idxBarItems} className="ei-mindmap-toolbar" style={{ marginRight: '5px', background: (bar.color ? bar.color : (barItems.length > 0 && barItems[0] && barItems[0].color) ? barItems[0].color : null) }}>
                    {
                      barItems.filter(i => !!i && i != null).map((item, idxItem) => (
                        item &&
                        <ToolbarButton 
                          key={idxItem} 
                          id={item.id} 
                          isMobile={isMobile} 
                          tooltip={item.tooltip} 
                          textMobile={item.textMobile} 
                          icon={item.icon} 
                          iconColor={item.iconColor} 
                          svgIcon={item.svgIcon} 
                          text={item.text} 
                          disabled={item.disabled} 
                          subItems={item.subItems}
                          subItemsMultiSelect={item.subItemsMultiSelect}
                          subMenuAnchorOrigin={item.subMenuAnchorOrigin}
                          subMenuTransformOrigin={item.subMenuTransformOrigin}
                          onClick={item.onClick} 
                        />
                      ))
                    }
                    </div>
                  ) 
                ))
              }
            </div>
          )
        ))
      }
    </div>
  );
};

const EditMindmap = () => {
  const { id } = useParams(); 

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);
  
  const [data, setData] = useState({});
  const [title, setTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState(false);
  const [savingDetail, setSavingDetail] = useState(false);
  const [creatingNew, setCreatingNew] = useState(false);

  const [summary, setSummary] = useState('');
  const [editingSummary, setEditingSummary] = useState(false);
  
  const [changingDirection, setChangingDirection] = useState(false);
  
  const token = localStorage.getItem('token');

  const [openEditTitle, setOpenEditTitle] = useState(false);
  const [formDataEditTitle, setFormDataEditTitle] = useState('');

  const [openEditLink, setOpenEditLink] = useState(false);
  const [formDataEditLink, setFormDataEditLink] = useState('');

  const [openCreateNewMindmap, setOpenCreateNewMindmap] = useState(false);
  const [formDataCreateNewMindmap, setFormDataCreateNewMindmap] = useState('');

  const [openLinkMindmap, setOpenLinkMindmap] = useState(false);
  const [openLinkSummary, setOpenLinkSummary] = useState(false);
  const [openLinkFlashcard, setOpenLinkFlashcard] = useState(false);
  const [openEditFlashcards, setOpenEditFlashcards] = useState(false);

  const [detailText, setDetailText] = useState('');

  const [openEditSummary, setOpenEditSummary] = useState(false);

  const [openEditDetail, setOpenEditDetail] = useState(false)

  const [openShortcuts, setOpenShortcuts] = useState(false);

  const [initialMindmapData, setInitialMindmapData] = useState(null);

  const [saving, setSaving] = useState(false);

  const [linkMindmapId, setLinkMindmapId] = useState(null);
  const [initialLinkMindmapId, setInitialLinkMindmapId] = useState(null);

  const [linkSummaryId, setLinkSummaryId] = useState(null);
  const [initialLinkSummaryId, setInitialLinkSummaryId] = useState(null);

  const [linkFlashcardId, setLinkFlashcardId] = useState(null);
  const [initialLinkFlashcardId, setInitialLinkFlashcardId] = useState(null);

  const [flashcards, setFlashcards] = useState([]);
  const [flashcardsBackup, setFlashcardsBackup] = useState(null);
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [flashcardFrontVisible, setFlashcardFrontVisible] = useState(true);
  const [savingFlashcards, setSavingFlashcards] = useState(false);

  const { toggleMenu } = useLayout();

  useEffect(() => {
    toggleMenu(false);
    return () => {
      toggleMenu(true);
    };
  }, []);
  
  useEffect(() => {
    if (data && data.request) {
      setTitle(data.request.text);
      setCurrentFolderId(data.request.folder);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.mindmapSummary) {
      setSummary(data.mindmapSummary);
    }
  }, [data.mindmapSummary]);

  const [fetchingRequest, setFetchingRequest] = useState(false);

  const handleClickOpenEditTitle = () => {
    setFormDataEditTitle(title);
    setOpenEditTitle(true);
  };

  const handleCloseEditTitle = () => {
    setFormDataEditTitle('');
    setOpenEditTitle(false);
  };

  const handleChangeEditTitleData = (event) => {
    setFormDataEditTitle(event.target.value);
  };

  const handleClickOpenCreateNewMindmap = () => {
    setTimeout(() => fetchAllFolders());

    setFormDataCreateNewMindmap('Sem título');
    setOpenCreateNewMindmap(true);
  };

  const handleCloseCreateNewMindmap = () => {
    setFormDataCreateNewMindmap('');
    setOpenCreateNewMindmap(false);
  };

  const handleClickOpenLinkMindmap = () => {
    setTimeout(() => fetchAllMindmapLean());
    
    if (selectedNodeData) {
      setLinkMindmapId(selectedNodeData.mindmapId);
      setInitialLinkMindmapId(selectedNodeData.mindmapId);
    }

    setOpenLinkMindmap(true);
  };

  const handleCloseLinkMindmap = () => {
    setInitialLinkMindmapId(null);
    setOpenLinkMindmap(false);
  };

  const handleClickOpenLinkSummary = () => {
    setTimeout(() => fetchAllSummaryLean());
    
    if (selectedNodeData) {
      setLinkSummaryId(selectedNodeData.summaryId);
      setInitialLinkSummaryId(selectedNodeData.summaryId);
    }

    setOpenLinkSummary(true);
  };

  const handleCloseLinkSummary = () => {
    setInitialLinkSummaryId(null);
    setOpenLinkSummary(false);
  };

  const handleClickOpenLinkFlashcard = () => {
    setTimeout(() => fetchAllFlashcardLean());
    
    if (selectedNodeData) {
      setLinkFlashcardId(selectedNodeData.flashcardId);
      setInitialLinkFlashcardId(selectedNodeData.flashcardId);
    }

    setOpenLinkFlashcard(true);
  };

  const handleCloseLinkFlashcard = () => {
    setInitialLinkFlashcardId(null);
    setOpenLinkFlashcard(false);
  };

  const handleChangeCreateNewMindmapData = (event) => {
    setFormDataCreateNewMindmap(event.target.value);
  };

  const handleClickOpenEditLink = () => {
    if (!selectedNodeData || !selectedNodeData.hyperLink || selectedNodeData.hyperLink == '') {
      setLinkEditMode(true);
    }

    setFormDataEditLink(selectedNodeData.hyperLink);
    setOpenEditLink(true);
  };

  const handleCloseEditLink = () => {
    setFormDataEditLink(null);
    setOpenEditLink(false);
    setLinkEditMode(false);
  };

  const handleClickOpenDetail = () => {
    if (!selectedNodeData || !selectedNodeData.detail || selectedNodeData.detail == '') {
      setDetailEditMode(true);
    }

    setDetailText((selectedNodeData && selectedNodeData.detail) || '');
    setOpenEditDetail(true);
  };

  const handleCloseDetail = () => {
    setDetailText('');
    setOpenEditDetail(false);
    setDetailEditMode(false);
  };
  
  const handleChangeDetailText = (event) => {
    setDetailText(event.target.value);
  };

  const handleClickOpenShortcuts = () => {
    setOpenShortcuts(true);
  };


  const handleCloseShortcuts = () => {
    setOpenShortcuts(false);
  };

  const reloadData = async function({ data, nodeData, message, redirect, newDirection, autoSave, onGetResponse }) {
    let responseRequest = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}`, {
      headers: { Authorization: token }
    });

    if (onGetResponse) {
      onGetResponse();
    }

    if (responseRequest && responseRequest.data) {
      setData({
        ...data,
        request: responseRequest.data
      });

      if (nodeData) {
         setInitialMindmapData(getImportantDataFromNodeDataStringified(nodeData));  
      }

      if (redirect == true) {
        setTimeout(() => navigate(`/app/folders${data.request.folder ? ('?folder=' + data.request.folder) : ''}`), 700);
      } else {
        if (responseRequest.data.generatingPdf) {
          for (let i = 0; i <= 30; i++) {
            responseRequest = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}`, {
              headers: { Authorization: token }
            });

            if (!responseRequest.data.generatingPdf) {
              setData({
                ...data,
                request: responseRequest.data
              });

              break;
            }

            await sleep(2000);
          }
        }

        if (message && message != '') {
          setMessageInfo(message);
        }
      }
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const loadAndOpenPdfInNewTab = async (requestId) => {
    setMessageInfo('Aguarde! Abrindo PDF...');
    
    const token = localStorage.getItem('token');
    const url = `https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/pdf?type=request&id=${requestId}&token=${token}`;
    const resp = await axios.get(url);

    if (resp.data.pdf) {  
      const binaryString = window.atob(resp.data.pdf);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      openInNewTab(pdfUrl);
    } else if (resp.data.redirectUrl) {
      openInNewTab(resp.data.redirectUrl);
    }
  };

  const handleSaveDetail = async () => {
    await _changeDetail(detailText, 'Complemento salvo!');
  };

  const handleDeleteDetail = async () => {
    await _changeDetail('', 'Complemento excluído!');
  };

  const _changeDetail = async (text, sucessMessage) => {
    setSavingDetail(true);

    if (text != selectedNodeData.detail) {
      selectedNodeData.detail = text;

      if (mindInstance) {
        mindInstance.refresh();
      } 

      try {
        const inputData = { nodeData: mindInstance.getData().nodeData };

        const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}/update-mindmap-data`, inputData, {
          headers: { Authorization: token }
        });

        await reloadData({ data, nodeData: inputData.nodeData, message: sucessMessage, onGetResponse: () => {
          mindInstance.unselectNode();
          mindInstance.unselectNodes();
          mindInstance.unselectSummary();
          mindInstance.unselectLink();

          handleCloseDetail();
        } });
      } catch (error) {
        setMessageError('Erro ao salvar complemento. Por favor, tente novamente.');
      }
    } else {
      handleCloseDetail();
    }

    setSavingDetail(false);
  };

  const handleSaveEditTitle = async () => {
    setEditingTitle(true);

    try {
      const input = { text: formDataEditTitle };

      if (mindmapChanged(initialMindmapData, mindInstance)) {
        input.nodeData = mindInstance.getData().nodeData;
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}/update-text`, input, {
        headers: { Authorization: token }
      });

      setTitle(formDataEditTitle);

      let message = 'Mapa inteligente renomeado!';

      if (response.data.message) {
        message = response.data.message
      }

      await reloadData({ data, nodeData: input.nodeData, message, onGetResponse: () => {
        setTimeout(() => {
          setFormDataEditTitle('');
          setOpenEditTitle(false);
        }, 100);
      } });
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao renomear mapa inteligente. Por favor, tente novamente.');
      }
    }

    setEditingTitle(false);
  };

  const handleSaveCreateNewMindmap = async () => {
    setCreatingNew(true);

    try {
      const input = { type: 'mindmap', text: formDataCreateNewMindmap, folder: currentFolderId };
      const token = localStorage.getItem('token');

      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/blank', input, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.requestId) {
        const requestId = response.data.requestId;
        setOpenCreateNewMindmap(false);
        setMessageInfo('Novo mapa criado!');
        onHandleSave({ redirectToMindmap: requestId });
      } else {
        setMessageError('Erro ao criar novo mapa!');
      }
    } catch(error) {
      setMessageError('Erro ao criar novo mapa!');
    }

    setCreatingNew(false);
  };

  function isValidUrl(string) {
    var regex = /^(http|https):\/\/[^ "]+$/;
    return regex.test(string);
  }

  const handleSaveEditLink = async () => {
    if (!isValidUrl(formDataEditLink)) {
      setMessageError('Informe uma URL válida!');
      return;
    }

    await _changeLink(formDataEditLink, 'Link alterado!');
  };

  const handleDeleteLink = async () => {
    await _changeLink('', 'Link excluído!');
  };

  const _changeLink = async (url, sucessMessage) => {
    selectedNodeData.hyperLink = url;
    mindInstance.refresh();
    setFormDataEditLink(null);
    setOpenEditLink(false);
    setLinkEditMode(false);

    mindInstance.unselectNode();
    mindInstance.unselectNodes();
    mindInstance.unselectSummary();
    mindInstance.unselectLink();

    setMessageInfo(sucessMessage) ;
  };

  const handleSaveLinkMindmap = async () => {
    await _changeLinkMindmap(linkMindmapId ? linkMindmapId.toString() : null, `Mapa inteligente ${linkMindmapId ? 'vinculado' : 'desvinculado'}!`);
  };

  const _changeLinkMindmap = async (id, sucessMessage) => {
    selectedNodeData.mindmapId = id;
    selectedNodeData.mindmapUrl = id ? `/app/view-smartmap/${id}` : null;
    mindInstance.refresh();
    setOpenLinkMindmap(false);

    mindInstance.unselectNode();
    mindInstance.unselectNodes();
    mindInstance.unselectSummary();
    mindInstance.unselectLink();

    setMessageInfo(sucessMessage) ;
  };

  const handleSaveLinkSummary = async () => {
    await _changeLinkSummary(linkSummaryId ? linkSummaryId.toString() : null, `Resumo ${linkSummaryId ? 'vinculado' : 'desvinculado'}!`);
  };

  const _changeLinkSummary = async (id, sucessMessage) => {
    selectedNodeData.summaryId = id;
    selectedNodeData.summaryUrl = id ? `/app/view-summary/${id}` : null;
    mindInstance.refresh();
    setOpenLinkSummary(false);

    mindInstance.unselectNode();
    mindInstance.unselectNodes();
    mindInstance.unselectSummary();
    mindInstance.unselectLink();

    setMessageInfo(sucessMessage) ;
  };

  const handleSaveLinkFlashcard = async () => {
    await _changeLinkFlashcard(linkFlashcardId ? linkFlashcardId.toString() : null, `Flashcard ${linkFlashcardId ? 'vinculado' : 'desvinculado'}!`);
  };

  const _changeLinkFlashcard = async (id, sucessMessage) => {
    selectedNodeData.flashcardId = id;
    selectedNodeData.flashcardUrl = id ? `/app/view-flashcard/${id}` : null;
    mindInstance.refresh();
    setOpenLinkFlashcard(false);

    mindInstance.unselectNode();
    mindInstance.unselectNodes();
    mindInstance.unselectSummary();
    mindInstance.unselectLink();

    setMessageInfo(sucessMessage) ;
  };

  const handleChangeEditLinkData = (event) => {
    setFormDataEditLink(event.target.value);
  };

  const handleClickOpenEditFlashcards = async () => {
    setFetchingRequest(true);
    setOpenEditFlashcards(true);

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${id}/full-mindmap`, {
        headers: { Authorization: token }
      });

      let flashcards = response.data.flashcards;

      if (!flashcards || flashcards.length == 0) {
        flashcards = [{ front: '', back: '' }];
        setFlashcardsEditMode(true);
      }

      setData({
        ...data,
        flashcards
      });

      setFlashcards(flashcards);
      setCurrentFlashcardIndex(0);
    } catch (error) {
      
    }

    setFetchingRequest(false);
  };

  const handleClickOpenEditSummary = async () => {
    setFetchingRequest(true);
    setOpenEditSummary(true);

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${id}/full-mindmap`, {
        headers: { Authorization: token }
      });

      setData({
        ...data,
        mindmapSummary: response.data.mindmapSummary
      });
    } catch (error) {
      
    }

    setFetchingRequest(false);
  };

  const handleCloseEditSummary = () => {
    setSummary(data.mindmapSummary);
    setOpenEditSummary(false);
  };

  const handleSaveEditSummary = async () => {
    setEditingSummary(true);

    try {
      const input = { summary };

      if (mindmapChanged(initialMindmapData, mindInstance)) {
        input.nodeData = mindInstance.getData().nodeData;
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}/update-summary-data`, input, {
        headers: { Authorization: token }
      });

      let message = 'Resumo alterado!';

      if (response.data.message) {
        message = response.data.message
      }

      await reloadData({ data, nodeData: input.nodeData, message, onGetResponse: () => {
        setTimeout(() => {
          setEditingSummary(false);
          setOpenEditSummary(false);
        }, 100);
      } });
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar resumo. Por favor, tente novamente.');
      }
    }
  };

  const [flashcardsEditMode, setFlashcardsEditMode] = useState(false);

  const handleCloseEditFlashcards = () => {
    setFlashcards([]);
    setFlashcardsEditMode(false);
    setCurrentFlashcardIndex(0);
    setOpenEditFlashcards(false);
  };

  const handleChangeQuestion = e => {
    setFlashcards(prevFlashcards => {
      if (currentFlashcardIndex >= 0 && currentFlashcardIndex < prevFlashcards.length) {
        const updatedFlashcards = [...prevFlashcards];
        const updatedFlashcard = { ...updatedFlashcards[currentFlashcardIndex] };
        updatedFlashcard.front = e.target.value;
        updatedFlashcards[currentFlashcardIndex] = updatedFlashcard;
        return updatedFlashcards;
      }

      return prevFlashcards;
    });
  };

  const handleChangeAnswer = e => {
    setFlashcards(prevFlashcards => {
      if (currentFlashcardIndex >= 0 && currentFlashcardIndex < prevFlashcards.length) {
        const updatedFlashcards = [...prevFlashcards];
        const updatedFlashcard = { ...updatedFlashcards[currentFlashcardIndex] };
        updatedFlashcard.back = e.target.value;
        updatedFlashcards[currentFlashcardIndex] = updatedFlashcard;
        return updatedFlashcards;
      }

      return prevFlashcards;
    });
  };

  const handleAddFlashcard = () => { 
    setFlashcards(prevFlashcards => {
      const updatedFlashcards = [...prevFlashcards];
      const insertPosition = currentFlashcardIndex + 1;
      updatedFlashcards.splice(insertPosition, 0, { front: '', back: '' });
      return updatedFlashcards;
    })
    setCurrentFlashcardIndex(currentFlashcardIndex+1);
    if (!flashcardsEditMode)  {
      if (!flashcardsBackup) {
        setFlashcardsBackup([...flashcards]);  
      }
      setFlashcardsEditMode(true); 
    }
  };

  const handleEditFlashcard = () => { 
    setFlashcardsBackup([...flashcards]);
    setFlashcardsEditMode(true);
  };

  const handleCancelFlashcard = () => { 
    if (flashcardsBackup && currentFlashcardIndex >= flashcardsBackup.length) {
      setCurrentFlashcardIndex(flashcardsBackup.length - 1);
    }

    if (!flashcardsBackup || (flashcardsBackup.length == 0)) {
      setOpenEditFlashcards(false);
    }

    setFlashcards(flashcardsBackup);
    setFlashcardsBackup(null);
    setFlashcardsEditMode(false);
  };

  const handlePreviousFlashcard = () => {
    setFlashcardFrontVisible(true);
    setCurrentFlashcardIndex(currentFlashcardIndex - 1);
  };

  const handleNextFlashcard = () => {
    setFlashcardFrontVisible(true);
    setCurrentFlashcardIndex(currentFlashcardIndex + 1);
  };

  const handleDeleteFlashcard = () => {
    setFlashcards(prevFlashcards => {
      if (currentFlashcardIndex >= 0 && currentFlashcardIndex < prevFlashcards.length) {
        const updatedFlashcards = [...prevFlashcards];
        updatedFlashcards.splice(currentFlashcardIndex, 1);
        return updatedFlashcards;
      }

      return prevFlashcards;
    });

    setCurrentFlashcardIndex(prevIndex => {
      if (prevIndex >= 0 && prevIndex < flashcards.length) {
        if (prevIndex === flashcards.length - 1) {
          return Math.max(flashcards.length - 2, 0);
        }
        return prevIndex;
      }
      return prevIndex;
    });
  };

  const handleSaveFlashcards = async () => {
    setSavingFlashcards(true);

    try {
      const input = { flashcards };

      if (mindmapChanged(initialMindmapData, mindInstance)) {
        input.nodeData = mindInstance.getData().nodeData;
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}/update-mindmap-data`, input, {
        headers: { Authorization: token }
      });

      let message = 'Perguntas e respostas alteradas!';

      if (response.data.message) {
        message = response.data.message
      }

      await reloadData({ data, nodeData: input.nodeData, message, onGetResponse: () => {
        setTimeout(() => {
          setSavingFlashcards(false);
          handleCloseEditFlashcards();
        }, 100);
      } });
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar perguntas e respostas. Por favor, tente novamente.');
      }
    }
  };

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const fetchRequest = async () => {
    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${id}/full-mindmap`, {
        headers: { Authorization: token }
      });

      setData(response.data);

      if (response.data.mindmapNode) {
        setInitialMindmapData(getImportantDataFromNodeDataStringified(response.data.mindmapNode));
      }
    } catch (error) {
      console.log(error);

      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Erro ao carregar dados do material. Por favor, tente novamente.');
      }
    }
  };

  function adjustMapSize() {
    var screenHeight = window.innerHeight;
    var screenWidth = window.innerWidth;
    var map = document.getElementById('map');
    map.style.height = screenHeight + 'px';
    map.style.width = screenWidth + 'px';
    window.scrollTo(0, 0);
    setIsPortrait(screenWidth < screenHeight);
  }

  useEffect(() => {
    fetchRequest();

    setTimeout(() => {
      adjustMapSize();

      window.addEventListener('resize', adjustMapSize);
      window.addEventListener('orientationchange', adjustMapSize);
    }, 500);

    return () => {
      window.removeEventListener('resize', adjustMapSize);
      window.removeEventListener('orientationchange', adjustMapSize);
    };
  }, [token]);
  
  const minMapRef = useRef();
  const intervalSaveID = useRef();
  const [mindInstance, setMindInstance] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  
  const [detailEditMode, setDetailEditMode] = useState(false);
  const [linkEditMode, setLinkEditMode] = useState(false);


  useEffect(() => {
    if (data && data.mindmapNode) {
      let theme = MindElixir.THEME;
      theme.cssVar['--bgcolor'] = '#ffffff';
      theme.cssVar['--main-bgcolor'] = '#ffffff';
      theme.cssVar['--root-bgcolor'] = '#2838c2';
      theme.cssVar['--color'] = '#444444';
      theme.cssVar['--main-color'] = '#2938c2';
      theme.cssVar['--selected'] = '#19e0ff';

      let direction = data.mindmapDirection;

      if (direction == null || direction == undefined) {
        direction = MindElixir.SIDE;  
      }

      const instance = new MindElixir({
        el: "#map",
        locale: 'pt',
        direction,
        draggable: true, 
        contextMenu: false,
        toolBar: false,
        nodeMenu: true, 
        keypress: true, 
        allowUndo: true,
        newTopicName: 'novo',
        mouseSelectionButton: 2,
        mainLinkStyle: 1,
        subLinkStyle: 1,
        theme: theme

        /*theme: {
          name: 'Dark',
          // main lines color palette
          palette: ['#848FA0', '#748BE9', '#D2F9FE', '#4145A5', '#789AFA', '#706CF4', '#EF987F', '#775DD5', '#FCEECF', '#DA7FBC'],
          // overwrite css variables
          cssVar: {
            '--main-color': '#ffffff',
            '--main-bgcolor': '#4c4f69',
            '--color': '#cccccc',
            '--bgcolor': '#252526',
            '--panel-color': '255, 255, 255',
            '--panel-bgcolor': '45, 55, 72',
          }
        },*/
      });
      
      instance.init({ nodeData: data.mindmapNode });
      instance.scale(0.6);
      instance.toCenter();
      
      instance.bus.addListener('selectNode', node => {
        setSelectedNodeData(node);
        setSelectedNode(minMapRef.current.currentNode);
      });

      instance.bus.addListener('selectNewNode', node => {
        setSelectedNodeData(node);
        setSelectedNode(minMapRef.current.currentNode);
      });

      instance.bus.addListener('selectNodes', nodes => {
        setSelectedNodeData(null);
        setSelectedNode(minMapRef.current.currentNode);
      });

      instance.bus.addListener('unselectNode', () => {
        setSelectedNodeData(null);
        setSelectedNode(minMapRef.current.currentNode);
      });

      instance.bus.addListener('unselectNodes', node => {
        setSelectedNodeData(null);
        setSelectedNode(minMapRef.current.currentNode);
      });

      instance.bus.addListener('expandNode', node => {
        setSelectedNode(minMapRef.current.currentNode);
      });

      window.mindmapInstance = instance;
      minMapRef.current = instance;
      setMindInstance(instance);
    }
  }, [data.mindmapNode]);

  const editorRef = useRef();

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().changeMode('wysiwyg', true);  
    }
  }, [openEditSummary]);

  const handleChangeEditSummary = () => {
    const editorInstance = editorRef.current.getInstance();
    setSummary(editorInstance.getMarkdown());
  };

  const toolbarItems = [
    ['heading', 'bold', 'italic', 'hr'],
    ['ul', 'ol'],
    ['table']
  ];

  const shortcuts = [
    { description: 'Adicionar filho', shortcut: 'Tab' },
    { description: 'Adicionar irmão', shortcut: 'Enter' },
    { description: 'Salvar', shortcut: 'Ctrl + .' },
    { description: 'Desfazer', shortcut: 'Ctrl + Z' },
    { description: 'Refazer', shortcut: 'Ctrl + Shift + Z' },
    { description: 'Editar selecionado', shortcut: 'F2' },
    { description: 'Remover selecionado', shortcut: 'Delete' },
    { description: 'Copiar selecionado', shortcut: 'Ctrl + C' },
    { description: 'Colar', shortcut: 'Ctrl + V' },
    { description: 'Zoom +', shortcut: 'Ctrl + "+"' },
    { description: 'Zoom -', shortcut: 'Ctrl + -' }
  ];

  useEffect(() => {    
    const mapElement = document.getElementById('map');
    const intervalID = setInterval(() => {
      if (window.scrollX != 0 || window.scrollY != 0) {
        window.scrollTo(0, 0);
      }
    }, 200);

    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    mapElement.addEventListener('touchmove', preventZoom, { passive: false });

    return () => {
      clearInterval(intervalID);
      mapElement.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  useEffect(() => {
    if (mindInstance) {
      if (intervalSaveID.current) {
        clearInterval(intervalSaveID.current);
      }

      intervalSaveID.current = setInterval(async () => {
        if (mindmapChanged(initialMindmapData, mindInstance)) {
          await onHandleSave({ redirect: false, autoSave: true });
        }
      }, 20000);

      return () => {
        clearInterval(intervalSaveID.current);
      };
    }
  }, [mindInstance, initialMindmapData]);

  const navigate = useNavigate();

  const onHandleChangeDirection = async (newDirection) => {
    setChangingDirection(true);

    try {
      const input = { direction: newDirection };

      if (mindmapChanged(initialMindmapData, mindInstance)) {
        input.nodeData = mindInstance.getData().nodeData;
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}/update-mindmap-data`, input, {
        headers: { Authorization: token }
      });

      const message = 'Mapa inteligente salvo!';

      await reloadData({ data, message, nodeData: input.nodeData, onGetResponse: () => {
        setTimeout(() => {
          setChangingDirection(false);
        }, 100);
      } });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar organização do mapa inteligente. Por favor, tente novamente.');
      }
    }
  };

  const onHandleSave = async ({ redirect, redirectToMindmap, openPdf, autoSave }) => {
    if (!mindmapChanged(initialMindmapData, mindInstance) && (data.mindmapDirection && mindInstance.direction)) {
      if (redirect == true) {
        navigate(`/app/folders${data.request.folder ? ('?folder=' + data.request.folder) : ''}`);
        return;
      } else if (redirectToMindmap) {
        setTimeout(() => { window.location.href = `/app/edit-smartmap/${redirectToMindmap}`; });
        return;
      }
    }

    const currentMs = new Date().getTime();
    
    if (window.lastSaveRequestMs && (currentMs - window.lastSaveRequestMs < 500)) {
      return;
    } else {
      window.lastSaveRequestMs = currentMs;
    }

    try {
      const nodeData = mindInstance.getData().nodeData;

      let message = 'Mapa inteligente salvo!';

      if (mindmapChanged(initialMindmapData, mindInstance)) {
        if (openPdf) {
          message = null;
          setMessageInfo('Salvando alterações. Tente novamente após finalizar.');
        }

        setSaving(true);

        const token = localStorage.getItem('token');
        const inputData = { nodeData, direction: mindInstance.direction };
        const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${data.request._id}/update-mindmap-data`, inputData, {
          headers: { Authorization: token }
        });

        if (response.data.message) {
          message = response.data.message
        }
      } else {
        if (openPdf) {
          message = '';

          await loadAndOpenPdfInNewTab(data.request._id);
        }
      }

      if (redirectToMindmap) {
        setTimeout(() => navigate(`/app/edit-smartmap/${redirectToMindmap}`), 10);
        return;
      } else {
        await reloadData({ data, nodeData, message, redirect, openPdf, autoSave, onGetResponse: () => {
          setTimeout(() => {
            setSaving(false);
          }, 100);
        } });
      }
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao salvar mapa inteligente. Por favor, tente novamente.');
      }

      setSaving(false);
    }
  };

  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [fetchingAllMindmapLean, setFetchingAllMindmapLean] = useState(false);
  const [allMindmapLean, setAllMindmapLean] = useState([]);
  const [fetchingAllSummaryLean, setFetchingAllSummaryLean] = useState(false);
  const [allSummaryLean, setAllSummaryLean] = useState([]);
  const [fetchingAllFlashcardLean, setFetchingAllFlashcardLean] = useState(false);
  const [allFlashcardLean, setAllFlashcardLean] = useState([]);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pastas.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pastas.');
    }

    setFetchingAllFolders(false);
  };

  const fetchAllMindmapLean = async () => {
    setFetchingAllMindmapLean(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all/mindmap-lean`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allRequests) {
        setAllMindmapLean(response.data.allRequests);  
      } else {
        setMessageError('Erro ao buscar mapas inteligentes.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar mapas inteligentes.');
    }

    setFetchingAllMindmapLean(false);
  };

  const fetchAllSummaryLean = async () => {
    setFetchingAllSummaryLean(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all/summary-lean`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allRequests) {
        setAllSummaryLean(response.data.allRequests);  
      } else {
        setMessageError('Erro ao buscar resumos.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar resumos.');
    }

    setFetchingAllSummaryLean(false);
  };

  const fetchAllFlashcardLean = async () => {
    setFetchingAllFlashcardLean(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all/flashcard-lean`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allRequests) {
        setAllFlashcardLean(response.data.allRequests);  
      } else {
        setMessageError('Erro ao buscar flashcards.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar flashcards.');
    }

    setFetchingAllFlashcardLean(false);
  };

  useEffect(() => {
    if (!currentFolderId) {
      setCurrentFolderId((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);


  const [classMode, setClassMode] = useState(false);

  const onHandleChangeClassMode = () => {
    setClassMode(!classMode);
  };

  const handleKeyDownClassMode = useCallback((e) => {
    if (e.key === 'Enter') {
      handleFocus(true);
    } else if (e.key === 'ArrowLeft') {
      if (e.altKey) {
        handleCancelFocus(true);
      }
    } else if (e.key === 'Escape') {
      if (classMode) {
          setClassMode(false);

          if (mindInstance) {
            for (let i = 0; i < 10; i++) {
              mindInstance.cancelFocus();
            }

            mindInstance.refresh();

            setTimeout(() => {
              mindInstance.selectNode((mindInstance.mindElixirBox || document).querySelector(`[data-nodeid=me${mindInstance.nodeData.id}]`));  
            }, 50);
          }
      }
    }
  }, [mindInstance, classMode]); 

  const handleKeyDownGlobal = useCallback((e) => {
    if (e.key === 'a') {
      if (e.metaKey || e.ctrlKey) {
        if (!classMode) {
          setClassMode(true);
        }
      }
    }
  }, [mindInstance]); 

  const handleDblclick = useCallback((e) => {
    handleFocus(true);
  }, [mindInstance]); 

  useEffect(() => {
    if (mindInstance) {
      mindInstance.editable = !classMode;

      if (classMode) {
        mindInstance.map.removeEventListener('keydown', handleKeyDownClassMode);
        mindInstance.map.addEventListener('keydown', handleKeyDownClassMode);
        mindInstance.map.removeEventListener('dblclick', handleDblclick);
        mindInstance.map.addEventListener('dblclick', handleDblclick);

        if (!mindInstance.currentNode) {
          mindInstance.selectNode((mindInstance.mindElixirBox || document).querySelector(`[data-nodeid=me${mindInstance.nodeData.id}]`));
        }
      }
    }

    return () => {
      if (mindInstance && mindInstance.map) {
        mindInstance.map.removeEventListener('keydown', handleKeyDownClassMode);
        mindInstance.map.removeEventListener('dblclick', handleDblclick);
      }
    };
  }, [classMode, mindInstance, handleKeyDownClassMode]);

  useEffect(() => {
    if (mindInstance) {
      mindInstance.map.removeEventListener('keydown', handleKeyDownGlobal);
      mindInstance.map.addEventListener('keydown', handleKeyDownGlobal);
    }

    return () => {
      if (mindInstance && mindInstance.map) {
        mindInstance.map.removeEventListener('keydown', handleKeyDownGlobal);
      }
    };
  }, [mindInstance, handleKeyDownGlobal]);

  const handleFocus = (dontCheck = false) => {
    let mind = mindInstance;
    if (!mind) return;

    if (!dontCheck) {
      if (!mind.currentNode) {
        setMessageError('Selecione um item');
        return;
      }
    }
    
    mind.focusNode(mind.currentNode);

    if (mind.nodeData && mind.nodeData.id) {
      handleMaximizeChild(dontCheck);
      mind.selectNode((mind.mindElixirBox || document).querySelector(`[data-nodeid=me${mind.nodeData.id}]`));
      mind.map.focus();
    }
  };

  const handleCancelFocus = (dontCheck = false) => {
    let mind = mindInstance;
    if (!mind) return;

    mind.cancelFocus();

    setTimeout(() => {
      if (mind.currentNode && mind.currentNode.nodeObj  && mind.currentNode.nodeObj.expanded) {
        if (mind.currentNode.nodeObj.parent && mind.currentNode.nodeObj.parent.children) {
          const currentId = mind.currentNode.nodeObj.id;

          let shouldMinimize = true;
          
          for (let child of mind.currentNode.nodeObj.parent.children) {
            if (child.id != currentId && child.expanded) {
              shouldMinimize = false;
              break;
            }
          }

          if (shouldMinimize) {
            mind.currentNode.nodeObj.expanded = false;
            mind.refresh();
            mind.selectNode((mind.mindElixirBox || document).querySelector(`[data-nodeid=me${currentId}]`));
          }
        }
      }
    }, 10);

    mind.map.focus();
  };

  const handleMaximizeChild = (dontCheck = false) => {
    let mind = mindInstance;
    if (!mind) return;

    if (!dontCheck) {
      if (!mind.currentNode) {
        setMessageError('Selecione um item');
        return;
      }
    }

    if (mind.currentNode.nodeObj && mind.currentNode.nodeObj.children && mind.currentNode.nodeObj.children.length > 0) {
      mind.currentNode.nodeObj.expanded = true;

      for (const child of mind.currentNode.nodeObj.children) {
        child.expanded = true;
      }

      mind.selectNode(mind.currentNode);
      mind.refresh();
      mind.map.focus();
    }
  };

  const handleMinimizeChild = (dontCheck = false) => {
    let mind = mindInstance;
    if (!mind) return;

    if (!dontCheck) {
      if (!mind.currentNode) {
        setMessageError('Selecione um item');
        return;
      }
    }

    if (mind.currentNode.nodeObj && mind.currentNode.nodeObj.children && mind.currentNode.nodeObj.children.length > 0) {
      mind.currentNode.nodeObj.expanded = true;

      for (const child of mind.currentNode.nodeObj.children) {
        child.expanded = false;
      }

      mind.selectNode(mind.currentNode);
      mind.refresh();
      mind.map.focus();
    }
  };

  return (
    <>
    {
      !mindInstance && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
            <CircularProgress style={{ width: '100px', height: '100px', color: '#888888' }} />
        </div>
      )
    }
    <div id="map"  className="no-zoom" style={{ display: 'flex', flex: 1, height: '100vh', overflow: 'hidden', fontFamily: '"Montserrat", -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif' }}>
      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
      {mindInstance && (
        <>
        <MindmapToolbar  
          mind={mindInstance} 
          initialMindmapData={initialMindmapData} 
          saving={saving} 
          onHandleChangeDirection={onHandleChangeDirection} 
          onHandleSave={onHandleSave} 
          classMode={classMode}
          onHandleChangeClassMode={onHandleChangeClassMode}
          navigate={navigate} 
          data={data} 
          isMobile={isMobile} 
          isPortrait={isPortrait} 
          selectedNode={selectedNode} 
          selectedNodeData={selectedNodeData} 
          setMessageError={setMessageError} 
          setMessageInfo={setMessageInfo} 
          onOpenEditLink={handleClickOpenEditLink} 
          onOpenEditTitle={handleClickOpenEditTitle} 
          onOpenDetail={handleClickOpenDetail} 
          onOpenShortcuts={handleClickOpenShortcuts} 
          onOpenEditSummary={handleClickOpenEditSummary} 
          onOpenEditFlashcards={handleClickOpenEditFlashcards} 
          onCreateNewMindmap={handleClickOpenCreateNewMindmap} 
          onOpenLinkMindmap={handleClickOpenLinkMindmap}
          onOpenLinkSummary={handleClickOpenLinkSummary}
          onOpenLinkFlashcard={handleClickOpenLinkFlashcard}
          onHandleFocus={handleFocus}
          onHandleCancelFocus={handleCancelFocus}
          onHandleMaximizeChild={handleMaximizeChild}
          onHandleMinimizeChild={handleMinimizeChild}
        />
        <Dialog 
          open={openEditTitle} 
          onClose={handleCloseEditTitle}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <CustomTextField
              autoFocus
              label="Título"
              type="text"
              fullWidth
              variant="standard"
              value={formDataEditTitle}
              onChange={handleChangeEditTitleData}
            />
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="outlined" onClick={handleCloseEditTitle}>Cancelar</Button>
            <Button size="medium" color="info" variant="contained" onClick={handleSaveEditTitle} disabled={editingTitle}>{ editingTitle ? 'Aguarde...' : 'Salvar' }</Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openCreateNewMindmap} 
          onClose={handleCloseCreateNewMindmap}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <CustomTextField
              autoFocus
              label="Título do novo mapa"
              type="text"
              fullWidth
              variant="outlined"
              value={formDataCreateNewMindmap}
              onChange={handleChangeCreateNewMindmapData}
            />
            <div style={{ marginTop: '16px' }}>
              <SelectFolder 
                  colorBgLabel="#f6f6f6"
                  isLoading={fetchingAllFolders}
                  folders={folders} 
                  currentFolderId={currentFolderId}
                  onSelect={setCurrentFolderId}
                />
            </div>
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="outlined" onClick={handleCloseCreateNewMindmap}>Cancelar</Button>
            <Button size="medium" color="info" variant="contained" onClick={handleSaveCreateNewMindmap} disabled={creatingNew}>{ creatingNew ? 'Aguarde...' : 'Criar novo mapa' }</Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openLinkMindmap} 
          onClose={handleCloseLinkMindmap}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <div>
              {
                fetchingAllMindmapLean ?
                (
                  <Skeleton variant="rectangular" width="100%" height={30} />
                ) :
                (
                  <VirtualizedAutocomplete
                    label="Vincular mapa inteligente"
                    placeholder="Selecione um mapa inteligente"
                    data={allMindmapLean}
                    initialValue={initialLinkMindmapId}
                    noOptionsText="Nenhum mapa inteligente encontrado"
                    renderOption={(props, option) => (
                      <div {...props} key={option._id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '0.8rem' }}>
                        <div style={{ color: '#000000' }}>{option.label}</div>
                        { option.folderDescription && 
                          <div style={{ 
                            color: '#999999', 
                            display: 'flex', 
                            alignItems: 'center',
                            overflow: 'hidden', 
                            whiteSpace: 'nowrap', 
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: '0.7rem'
                          }}>
                            <i className="bx bxs-folder" style={{ fontSize: '14px', color: '#bbbbbb', marginRight: '4px' }} />
                            {option.folderDescription}
                          </div> 
                        }
                      </div>
                    )}
                    onChange={(item) => { setLinkMindmapId(item ? item._id : null) }}
                  />
                )
              }
            </div>
          </DialogContent>
          {
            !fetchingAllMindmapLean &&
            <DialogActions>
              <Button size="medium" color="info" variant="outlined" onClick={handleCloseLinkMindmap}>Cancelar</Button>
              <Button size="medium" color="info" variant="contained" onClick={handleSaveLinkMindmap}>{(linkMindmapId || !initialLinkMindmapId) ? 'Vincular' : 'Desvincular'}</Button>
            </DialogActions>
          }
        </Dialog>

        <Dialog 
          open={openLinkSummary} 
          onClose={handleCloseLinkSummary}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <div>
              {
                fetchingAllSummaryLean ?
                (
                  <Skeleton variant="rectangular" width="100%" height={30} />
                ) :
                (
                  <VirtualizedAutocomplete
                    label="Vincular resumo"
                    noOptionsText="Nenhum resumo encontrado"
                    placeholder="Selecione um resumo"
                    data={allSummaryLean}
                    initialValue={initialLinkSummaryId}
                    renderOption={(props, option) => (
                      <div {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '0.8rem' }}>
                        <div style={{ color: '#000000' }}>{option.label}</div>
                        { option.folderDescription && 
                          <div style={{ 
                            color: '#999999', 
                            display: 'flex', 
                            alignItems: 'center',
                            overflow: 'hidden', 
                            whiteSpace: 'nowrap', 
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: '0.7rem'
                          }}>
                            <i className="bx bxs-folder" style={{ fontSize: '14px', color: '#bbbbbb', marginRight: '4px' }} />
                            {option.folderDescription}
                          </div> 
                        }
                      </div>
                    )}
                    onChange={(item) => { setLinkSummaryId(item ? item._id : null) }}
                  />
                )
              }
            </div>
          </DialogContent>
          {
            !fetchingAllSummaryLean &&
            <DialogActions>
              <Button size="medium" color="info" variant="outlined" onClick={handleCloseLinkSummary}>Cancelar</Button>
              <Button size="medium" color="info" variant="contained" onClick={handleSaveLinkSummary}>{(linkSummaryId || !initialLinkSummaryId) ? 'Vincular' : 'Desvincular'}</Button>
            </DialogActions>
          }
        </Dialog>

        <Dialog 
          open={openLinkFlashcard} 
          onClose={handleCloseLinkFlashcard}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <div>
              {
                fetchingAllFlashcardLean ?
                (
                  <Skeleton variant="rectangular" width="100%" height={30} />
                ) :
                (
                  <VirtualizedAutocomplete
                    label="Vincular flashcard"
                    noOptionsText="Nenhum flashcard encontrado"
                    placeholder="Selecione um flashcard"
                    data={allFlashcardLean}
                    initialValue={initialLinkFlashcardId}
                    renderOption={(props, option) => (
                      <div {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '0.8rem' }}>
                        <div style={{ color: '#000000' }}>{option.label}</div>
                        { option.folderDescription && 
                          <div style={{ 
                            color: '#999999', 
                            display: 'flex', 
                            alignItems: 'center',
                            overflow: 'hidden', 
                            whiteSpace: 'nowrap', 
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            fontSize: '0.7rem'
                          }}>
                            <i className="bx bxs-folder" style={{ fontSize: '14px', color: '#bbbbbb', marginRight: '4px' }} />
                            {option.folderDescription}
                          </div> 
                        }
                      </div>
                    )}
                    onChange={(item) => { setLinkFlashcardId(item ? item._id : null) }}
                  />
                )
              }
            </div>
          </DialogContent>
          {
            !fetchingAllFlashcardLean &&
            <DialogActions>
              <Button size="medium" color="info" variant="outlined" onClick={handleCloseLinkFlashcard}>Cancelar</Button>
              <Button size="medium" color="info" variant="contained" onClick={handleSaveLinkFlashcard}>{(linkFlashcardId || !initialLinkFlashcardId) ? 'Vincular' : 'Desvincular'}</Button>
            </DialogActions>
          }
        </Dialog>

        <Dialog 
          open={openEditFlashcards} 
          onClose={handleCloseEditFlashcards}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            {
              (fetchingRequest || savingFlashcards) ?
              (
                <>
                  <Skeleton variant="rectangular" width="100%" height={20} />
                  <Skeleton style={{ marginTop: '8px' }} variant="rectangular" width="100%" height={20} />
                  <Skeleton style={{ marginTop: '8px' }}  variant="rectangular" width="100%" height={20} />
                </>
              ) :
              (
                flashcardsEditMode ?
                (
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                      <div>
                        <Tooltip title="Adicionar">
                          <Button size="small" style={{ marginRight: '4px' }} color="primary" variant="contained" onClick={handleAddFlashcard}>
                            <i style={{ fontSize: '16px' }} className="bx bx-plus" />
                          </Button>
                        </Tooltip>

                        <Tooltip title="Excluir">
                          <Button size="small" style={{ marginRight: '4px' }} color="error" variant="contained" onClick={handleDeleteFlashcard} disabled={!flashcards || flashcards.length == 0}>
                            <i style={{ fontSize: '16px' }} className="bx bx-trash" />
                          </Button>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Anterior">
                          <Button size="small" style={{ marginRight: '4px' }} color="gray" variant="outlined" onClick={handlePreviousFlashcard} disabled={(!flashcards || flashcards.length == 0) || (currentFlashcardIndex == 0)}>
                            <i style={{ fontSize: '16px' }} className="bx bx-chevron-left" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Próxima">
                          <Button size="small" style={{ marginRight: '4px' }} color="gray" variant="outlined" onClick={handleNextFlashcard} disabled={(!flashcards || flashcards.length == 0) || (currentFlashcardIndex == (flashcards ? (flashcards.length - 1) : 0))}>
                            <i style={{ fontSize: '16px' }} className="bx bx-chevron-right" />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                    {
                      (!flashcards || flashcards.length == 0) ?
                      (
                        <div style={{ marginTop: '10px', color: '#62697f', fontSize: '0.9rem' }}>
                          Nenhuma pergunta e resposta cadastrada!
                        </div>
                      ) :
                      (
                        <>
                          <CustomTextField
                            autoFocus
                            multiline
                            label={`Pergunta ${currentFlashcardIndex+1}`}
                            placeholder="Informe aqui a pergunta"
                            rows={3} 
                            type="text"
                            fullWidth
                            value={(flashcards && (flashcards.length > currentFlashcardIndex)) ? flashcards[currentFlashcardIndex].front : ''}
                            onChange={handleChangeQuestion}
                          />
                          <CustomTextField
                            style={{ marginTop: '20px' }}
                            multiline
                            label={`Resposta ${currentFlashcardIndex+1}`}
                            placeholder="Informe aqui a resposta"
                            rows={3} 
                            type="text"
                            fullWidth
                            value={(flashcards && (flashcards.length > currentFlashcardIndex)) ? flashcards[currentFlashcardIndex].back : ''}
                            onChange={handleChangeAnswer}
                          />
                        </>
                      )
                    }
                  </div>
                ) :
                (
                  (!flashcards || flashcards.length == 0) ?
                  (
                    <div style={{ marginTop: '10px', color: '#666666' }}>
                      Nenhuma pergunta e resposta cadastrada!
                    </div>
                  ) :
                  (
                    <div>
                      { flashcards && (flashcards.length > currentFlashcardIndex) && 
                        (
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                              <Tooltip title="Anterior">
                                <Button size="small" style={{ marginRight: '4px' }} color="gray" variant="outlined" onClick={handlePreviousFlashcard} disabled={(!flashcards || flashcards.length == 0) || (currentFlashcardIndex == 0)}>
                                  <i style={{ fontSize: '16px' }} className="bx bx-chevron-left" />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Próxima">
                                <Button size="small" style={{ marginRight: '4px' }} color="gray" variant="outlined" onClick={handleNextFlashcard} disabled={(!flashcards || flashcards.length == 0) || (currentFlashcardIndex == (flashcards ? (flashcards.length - 1) : 0))}>
                                  <i style={{ fontSize: '16px' }} className="bx bx-chevron-right" />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Fechar">
                                <Button size="small" color="gray" onClick={handleCloseEditFlashcards}>
                                  <i style={{ fontSize: '16px' }} className="bx bx-x" />
                                </Button>
                              </Tooltip>
                            </div>

                           <div style={{ position: 'relative', marginTop: '5px', marginBottom: '15px', textAlign: 'center' }}>
                            <div style={{ position: 'absolute', zIndex: '2', top: '-13px', left: '10px' }}>
                              <Tooltip title={flashcardFrontVisible ? 'Ver resposta' : 'Ver pergunta'}>
                                <Button size="small" style={{ marginRight: '4px' }} color={flashcardFrontVisible ? 'gray' : 'blue'} variant="contained" onClick={() => setFlashcardFrontVisible(!flashcardFrontVisible)}>
                                  <i style={{ fontSize: '16px' }} className={`bx bx-rotate-${flashcardFrontVisible ? 'right' : 'left'}`} />
                                </Button>
                              </Tooltip>
                              <span style={{ color: flashcardFrontVisible ? '#777777' : '#322c96', fontWeight: '600', marginRight: '5px' }}>{`${flashcardFrontVisible ? 'Pergunta' : 'Resposta'} ${currentFlashcardIndex + 1}`}</span>
                            </div>
                            <div onClick={() => setFlashcardFrontVisible(!flashcardFrontVisible)} style={{ cursor: 'pointer', padding: '25px 20px 20px', fontSize: '0.9rem', textAlign: 'left', color: '#666666', borderRadius: '8px', backgroundColor: '#eaeaea', perspective: '1000px', overflow: 'auto', maxHeight: '300px', width: '100%' }}>
                              <div style={{ transition: 'transform 0.4s', transformStyle: 'preserve-3d', transform: flashcardFrontVisible ? 'rotateY(0deg)' : 'rotateY(180deg)' }}>
                                <div style={{ display: (!flashcardFrontVisible ? 'none' : null), backfaceVisibility: 'hidden', height: 'auto' }}>
                                  {flashcards[currentFlashcardIndex] ? flashcards[currentFlashcardIndex].front : null}
                                </div>
                                <div style={{ display: (flashcardFrontVisible ? 'none' : null), backfaceVisibility: 'hidden', color: '#322c96', transform: 'rotateY(180deg)', height: 'auto' }}>
                                  {flashcards[currentFlashcardIndex] ? flashcards[currentFlashcardIndex].back : null}
                                </div>
                              </div>
                            </div>
                          </div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                              <Tooltip title="Adicionar">
                                <Button size="small" style={{ marginRight: '4px' }} color="primary" variant="contained" onClick={handleAddFlashcard}>
                                  <i style={{ fontSize: '16px' }} className="bx bx-plus" />
                                </Button>
                              </Tooltip>

                              <Tooltip title="Editar">
                                <Button size="small" style={{ marginRight: '4px' }} color="info" variant="contained" onClick={handleEditFlashcard}>
                                  <i style={{ fontSize: '16px' }} className="bx bx-pencil" />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        ) 
                      }
                    </div>
                  )
                )
              )
            }
            
          </DialogContent>
          {
            flashcardsEditMode &&
            (
              <DialogActions>
                <Button size="medium" color="info" variant="outlined" onClick={handleCancelFlashcard}>Cancelar</Button>
                <Button size="medium" color="info" variant="contained" disabled={savingFlashcards} onClick={handleSaveFlashcards}>{savingFlashcards ? 'Salvando...' : 'Salvar'}</Button>
              </DialogActions>
            )
          }
        </Dialog>

        <Dialog 
          open={openEditLink} 
          onClose={handleCloseEditLink}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            {
              !linkEditMode &&
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i style={{ fontSize: '18px', color: '#556c8a', marginRight: '5px' }} className="bx bx-link" />
                  <Link href={formDataEditLink} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', flexGrow: '1', marginLeft: '5px', color: '#556c8a', fontWeight: '500', fontSize: '14px' }}>
                    {(!formDataEditLink || formDataEditLink.length < 25) ? formDataEditLink : `${formDataEditLink.substring(0, 22)}...`}
                  </Link>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                 <Tooltip title="Editar">
                    <Button size="small" style={{ marginRight: '4px' }} color="info" variant="contained" onClick={() => setLinkEditMode(true)}>
                      <i style={{ fontSize: '16px' }} className="bx bx-pencil" />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Excluir">
                    <Button size="small" style={{ marginRight: '4px' }} color="error" variant="contained" onClick={handleDeleteLink}>
                      <i style={{ fontSize: '16px' }} className="bx bx-trash" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Fechar">
                    <Button size="small" color="gray" onClick={handleCloseEditLink}>
                      <i style={{ fontSize: '16px' }} className="bx bx-x" />
                    </Button>
                  </Tooltip>   
                </div>
              </>
            }
            {
              linkEditMode &&
              <CustomTextField
                autoFocus
                label="Link"
                placeholder="Informe um link (https://...)"
                type="text"
                fullWidth
                variant="standard"
                value={formDataEditLink}
                onChange={handleChangeEditLinkData}
              />
            }
          </DialogContent>
          {
            linkEditMode &&
            <DialogActions>
              <Button size="medium" color="info" variant="outlined" onClick={handleCloseEditLink}>Cancelar</Button>
              <Button size="medium" color="info" variant="contained" onClick={handleSaveEditLink}>Salvar</Button>
            </DialogActions>
          }
        </Dialog>

        <Dialog 
          open={openEditDetail} 
          onClose={handleCloseDetail}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            {
              !detailEditMode &&
              <>
                <div>
                  <Typography style={{ display: 'flex', flexGrow: '1', marginLeft: '5px', color: '#556c8a', fontWeight: '500', fontSize: '14px' }}>
                    {detailText}
                  </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                  {
                    savingDetail ?
                    (
                      <CircularProgress style={{ width: '20px', height: '20px', color: '#888888', marginRight: '6px' }} />
                    ) :
                    (
                      <>
                        <Tooltip title="Editar">
                          <Button size="small" style={{ marginRight: '4px' }} color="info" variant="contained" onClick={() => setDetailEditMode(true)}>
                            <i style={{ fontSize: '16px' }} className="bx bx-pencil" />
                          </Button>
                        </Tooltip>

                        <Tooltip title="Excluir">
                          <Button size="small" style={{ marginRight: '4px' }} color="error" variant="contained" onClick={handleDeleteDetail}>
                            <i style={{ fontSize: '16px' }} className="bx bx-trash" />
                          </Button>
                        </Tooltip>
                      </>
                    )
                  }  

                  <Tooltip title="Fechar">
                    <Button size="small" color="gray" onClick={handleCloseDetail}>
                      <i style={{ fontSize: '16px' }} className="bx bx-x" />
                    </Button>
                  </Tooltip>   
                </div>
              </>
            }
            {
              detailEditMode &&
              <CustomTextField
                autoFocus
                multiline
                label="Complemento"
                placeholder="Informe aqui o complemento"
                rows={3} 
                type="text"
                fullWidth
                variant="standard"
                value={detailText}
                onChange={handleChangeDetailText}
              />
            }
          </DialogContent>
          {
            detailEditMode &&
            <DialogActions>
              <Button size="medium" color="info" variant="outlined" onClick={handleCloseDetail}>Cancelar</Button>
              <Button size="medium" color="info" variant="contained" onClick={handleSaveDetail} disabled={savingDetail}>{ savingDetail ? 'Aguarde...' : 'Salvar' }</Button>
            </DialogActions>
          }
        </Dialog>

        <Dialog 
          open={openShortcuts} 
          onClose={handleCloseShortcuts}
          PaperProps={{
            style: { width: '400px' },
          }}
        >
          <DialogContent>
            <Typography component="body1" style={{ color: '#556c8a', fontWeight: '600', fontSize: '18px' }}>
                Atalhos
            </Typography>
            {shortcuts && shortcuts.map((shortcut, index) => (
              <Grid container key={index} style={{ marginTop: (index==0) ? '20px' : '0', padding: '8px 16px', borderRadius: '4px', background: ((index%2==0) ? '#e4e4e4' : 'transparent') }}>
                <Grid item xs={8}>
                  <Typography variant="body2" style={{fontSize: '11px', color: '#888888' }}>
                    {shortcut.description}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <Typography variant="body2" style={{ fontSize: '11px', color: '#57678b' }}>
                    {shortcut.shortcut}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="contained" style={{ marginRight: '4px', marginBottom: '4px' }} onClick={handleCloseShortcuts}>Fechar</Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openEditSummary} 
          onClose={handleCloseEditSummary}
          PaperProps={{
            style: { maxWidth: '900px', width: '900px' },
          }}
        >
          <DialogContent>
            {
              fetchingRequest ?
              (
                <>
                  <Skeleton variant="rectangular" width="100%" height={20} />
                  <Skeleton style={{ marginTop: '8px' }} variant="rectangular" width="100%" height={20} />
                  <Skeleton style={{ marginTop: '8px' }}  variant="rectangular" width="100%" height={20} />
                </>
              ) :
              (
                <Editor
                  ref={editorRef}
                  initialValue={summary}
                  previewStyle="vertical"
                  height={ isMobile ? (isPortrait ? '480px' : '260px') : '600px' }
                  initialEditType="wysiwyg"
                  useCommandShortcut={true}
                  onChange={handleChangeEditSummary}
                  toolbarItems={toolbarItems}
                />
              )
            }
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="outlined" onClick={handleCloseEditSummary}>Cancelar</Button>
            <Button size="medium" color="info" variant="contained" onClick={handleSaveEditSummary} disabled={editingSummary}>{ editingSummary ? 'Aguarde...' : 'Salvar' }</Button>
          </DialogActions>
        </Dialog>
        </>
      )}
    </div>
    </>
  );
};

export default EditMindmap;
