import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Container, Typography, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import LogoComponent from './LogoComponent';
import useSnackbar from './useSnackbar';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const handleSubmit = async (e) => {
    setSending(true);

    e.preventDefault();
    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/auth/forgot-password', { email });
      setMessageInfo(response.data.message);

      if (response.data.userId) {
        setTimeout(function() {
          navigate(`/reset-password/${response.data.userId}`);
        }, 3400);  
      }
    } catch (error) {
      setMessageError(error.response.data.error);
    }

    setSending(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Container component="main" maxWidth="xs">
        <LogoComponent />

        <Box style={{ 
            padding: '30px', 
            backgroundColor: '#ffffff', 
            borderRadius: '8px', 
            marginTop: '15px',
            boxShadow: '0 0 0 0.1em #e7e7e7' 
        }}>
          <Typography variant="h6" style={{ fontSize: '14px', color: '#666666', fontWeight: '700' }}>
            Esqueceu sua senha?
          </Typography>

          <form onSubmit={handleSubmit}>
            <CustomTextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Informe o seu email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              style={{ marginTop: '15px'}}
              disabled={sending}
            >
              {sending ? 'Aguarde...' : 'Enviar'} 
            </Button>

          </form>
        </Box>

        <Button
          onClick={() => navigate('/login')}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '10px' }}
        >
          Voltar
        </Button>
        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
      </Container>
    </Grid>
  );
}

export default ForgotPassword;
