import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Button, FormControl, InputLabel, Dialog, DialogContent, DialogActions, Link, Tooltip, IconButton, Divider, Box, TextField, Container, Typography, Card, CardHeader, CardContent, CardActions, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import useSnackbar from './useSnackbar';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useUser } from './UserContext';
import { useLocation } from 'react-router-dom';
import SelectFolder from './SelectFolder';
import { MindMapIcon } from './MainIcons';
import LinkToMaterial from './LinkToMaterial';

function NewMarkdownMindMap() {
  const token = localStorage.getItem('token');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user, fetchUser } = useUser();
  const [openTooltip, setOpenTooltip] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folder = queryParams.get('folder');
  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(folder);
  const [markdown, setMarkdown] = useState('');

  const handleMarkdownChange = (e) => {
    setMarkdown(e.target.value);
  };
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pasta.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pasta.');
    }

    setFetchingAllFolders(false);
  };

  const handleCreateMindmap = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/mindmap-from-markdown', { text, markdown, folder: currentFolderId }, {
        headers: { Authorization: token }
      });

      const requestId = response.data.requestId;

      setTimeout(() => {
        navigate(`/app/edit-smartmap/${requestId}`);
      }, 800);

      setMessageInfo('Mapa inteligente criado!');

      setTimeout(async () => {
        try {
          await fetchUser();
        } catch(err) { }
      }, 200);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAllFolders();
  }, []);

  useEffect(() => {
    if (!currentFolderId) {
      setCurrentFolderId((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);

  return (
      <>
      <Container component="main" maxWidth="xs" style={{ marginBottom: '30px' }}>
        <Card sx={{ 
          marginTop: '15px',
          '@media (min-width:600px)': {
            marginTop: '40px' 
          } 
        }}>
          <CardHeader
            sx={{
              position: 'relative',
              paddingLeft: '20px',
              '&::before': {
                content: '""', 
                position: 'absolute',
                left: '-3px',
                top: '9px',
                bottom: '9px',
                width: '6px',
                backgroundColor: '#6937ea',
                borderRadius: '3px'
              }
            }}
            title={
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MindMapIcon color="#000000" style={{ marginRight: '6px' }} /> <span>Novo Mapa Inteligente</span>
                </div>
              </>
            }
            subheader="Insira um título e o markdown e crie um mapa inteligente"
          />
          <CardContent style={{ padding: '0' }}>
            <Box style={{ padding: '16px' }}>
              <CustomTextField
                variant="outlined"
                autoComplete="off"
                margin="normal"
                style={{ margin: '0' }}
                fullWidth
                id="text"
                label="Título"
                placeholder="Digite aqui o título"
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Box>

            <Box style={{ padding: '16px' }}>
              <CustomTextField
                variant="outlined"
                autoComplete="off"
                margin="normal"
                style={{ margin: '0' }}
                fullWidth
                id="markdown"
                label="Markdown"
                placeholder="Copie e cole aqui o seu markdown"
                name="markdown"
                value={markdown}
                multiline
                rows={8} 
                onChange={handleMarkdownChange}
              />
            </Box>

            <Box style={{ padding: '16px' }}>
              <SelectFolder 
                isLoading={fetchingAllFolders}
                folders={folders} 
                currentFolderId={currentFolderId}
                onSelect={setCurrentFolderId}
              />
            </Box>
          
          </CardContent>
          <CardActions>
            <Button
              onClick={handleCreateMindmap}
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            >
                {loading ? 'Aguarde...' : 'Criar Mapa Inteligente'}
            </Button>
          </CardActions>
        </Card>

        <Button
          onClick={() => navigate(`/app/folders${currentFolderId ? ('?folder=' + currentFolderId) : ''}`)}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '8px' }}
        >
          Voltar
        </Button>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />

      </Container>
      </>
  );
}

export default NewMarkdownMindMap;
