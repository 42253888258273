import React from 'react';
import { Grid, Typography } from '@mui/material';

function PriceComponent({ price }) {
  const formattedPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(price);

  return (
  	<div style={{ background: '#eeeeee', borderRadius: '5px', padding: '10px 20px' }}>
		<Grid container spacing={2}>
		  <Grid item xs={6}>
		    <Typography variant="body1" style={{ fontSize: '14px', fontWeight: '700', color: '#888888' }}>
		      Total
		    </Typography>
		  </Grid>
		  <Grid item xs={6}>
		    <Typography variant="body1" style={{ textAlign: 'right', fontSize: '13px', fontWeight: '600', color: '#3d657c' }}>
		      {formattedPrice}
		    </Typography>
		  </Grid>
		</Grid>
    </div>
  );
}

export default PriceComponent;
