import React from 'react';
import { Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { LayoutProvider } from './components/LayoutContext';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token');

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <LayoutProvider><Layout>{children}</Layout></LayoutProvider>;
};

export default ProtectedRoute;
