import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

function LayoutPublic({ children }) {
 
  const texts = [
    'mais eficiente.',
    'mais focado.',
    'mais rápido.',
    'muito melhor.'
  ];

  const images = [
    '/mapa01.png',
    '/mapa02.png',
    '/mapa03.png'
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentTextIndex, setCurrentIndex] = useState(0);
  const [imageVisible, setImageVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageVisible(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setImageVisible(true);
      }, 500); // Ajuste o tempo de transição conforme necessário
    }, 5000);

    const intervalText = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => {
      clearInterval(interval);
      clearInterval(intervalText);
    };
  }, []);

  useEffect(() => {
    

    return () => {
      // Código de limpeza aqui
    };
  }, [currentImageIndex]);

  const imageStyle = {
    maxWidth: 600,
    maxHeight: 550,
    opacity: imageVisible ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out' // Ajuste a duração conforme necessário
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: '100%',
        transition: 'transform 0.5s ease-in-out',
        backgroundColor: '#1e242b'
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          flex: 2,
          opacity: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: 'radial-gradient(circle at top left,transparent 25%,#12161a 25.5%, #12161a 36%, transparent 37%, transparent 100%),radial-gradient(circle at bottom right,transparent 34%,#12161a 34.5%, #12161a 45.5%, transparent 46%, transparent 100%)',
          backgroundColor: '#0b0e11',
          backgroundSize: '30px 30px'
        }}
      >
        <Box style={{ width: '100%', padding: '25px' }}>
          <img src="/app_logo.png" alt="Logomarca Estudo Inteligente" style={{ 
              height: '45px'
            }} />
        </Box>

        <Box class="header-title-box">
          <Box class="header-title-fixed">
            Nossa missão é que você 
          </Box>
          <Box class="header-title">
            estude <span class="words">{texts[currentTextIndex]}</span>
          </Box>
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff'
          }}
        >
            <img src={images[currentImageIndex]} style={imageStyle} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          backgroundColor: '#d9d9d9'
        }}
      >
        {children}    
      </Box>
    </Box>
  );
}

export default LayoutPublic;
