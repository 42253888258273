import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import axios from 'axios';
import { Switch, Dialog, DialogActions, DialogContent, Menu, MenuItem, Link, DialogTitle, Button, Skeleton, Grid, Container, Typography, Box, Divider, Tooltip, TextField, InputAdornment, IconButton, useTheme, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RequestTypeIcon from './RequestTypeIcon';
import RequestChip from './RequestChip';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import _ from 'lodash';
import CustomTextField from './CustomTextField';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import { useUser } from './UserContext';
import '@toast-ui/editor/dist/toastui-editor.css';
import HorizontalList from './HorizontalList';
import { MindMapIcon, FlashcardIcon, SummaryIcon, PdfIcon, 
  DeleteIcon, EditIcon, ArrowLeftIcon, ArrowRightIcon, SearchIcon,
  SortIcon, TickIcon, VerticalDotsIcon, MenuIcon, FilterIcon, 
  GeneratingIcon, SendIcon, RenameIcon, FolderIcon, EmptyFileIcon,
  ChevronRightIcon } from './MainIcons';
import { useLocation } from 'react-router-dom';
import SelectFolder from './SelectFolder';
import { handleDownloadAndEdit } from './handleDownloadAndEdit';

import { Editor } from '@toast-ui/react-editor';



function NewRequestButton({ user, isMobile, disabled, onBlankMindmapClick, onBlankSummaryClick, onMarkdownMindmapClick, onMindmapClick, onSummaryClick, onFlashcardClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mindmapSubitemsVisible, setMindmapSubitemsVisible] = useState(false);
  const [summarySubitemsVisible, setSummarySubitemsVisible] = useState(false);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button 
        variant="contained" 
        color="primary" 
        aria-controls="types-simple-menu" 
        aria-haspopup="true" 
        onClick={handleClick}
        disabled={disabled}
        style={{ marginLeft: '8px', }}
      >
        {isMobile ? <Add /> : 'Novo Material'}
      </Button>
      <Menu
        id="types-simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem sx={{ fontSize: '0.8rem', color: getColorFromType('mindmap'), '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={() => setMindmapSubitemsVisible(!mindmapSubitemsVisible)}>
          <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
            <MindMapIcon color={getColorFromType('mindmap')} />
          </span>
          Mapa inteligente
        </MenuItem>
        {
          mindmapSubitemsVisible &&
          (
            <>
              {
                user && !user.onlyFolderCourses &&
                (
                  <MenuItem onClick={e => { e.preventDefault(); onMindmapClick(); setMindmapSubitemsVisible(false); handleClose(); }} >
                    <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                      <ChevronRightIcon color="#888888" size={20} />
                    </span>
                    Gerado por IA
                  </MenuItem>
                )
              }
              <MenuItem onClick={e => { e.preventDefault(); onBlankMindmapClick(); setMindmapSubitemsVisible(false); handleClose(); }} >
                <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                  <ChevronRightIcon color="#888888" size={20} />
                </span>
                Em branco
              </MenuItem>
              <MenuItem onClick={e => { e.preventDefault(); onMarkdownMindmapClick(); setMindmapSubitemsVisible(false); handleClose(); }} >
                <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                  <ChevronRightIcon color="#888888" size={20} />
                </span>
                Importar markdown
              </MenuItem>
            </>
          )
        }

        {
          user && !user.onlyFolderCourses &&
          (
            <MenuItem sx={{ fontSize: '0.8rem', color: getColorFromType('flashcard'), '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={e => { e.preventDefault(); onFlashcardClick(); handleClose(); }}>
              <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                <FlashcardIcon color={getColorFromType('flashcard')} />
              </span>
              Flashcard
            </MenuItem>
          )
        }

         <MenuItem sx={{ fontSize: '0.8rem', color: getColorFromType('summary'), '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={() => setSummarySubitemsVisible(!summarySubitemsVisible)}>
          <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
            <MindMapIcon color={getColorFromType('summary')} />
          </span>
          Resumo
        </MenuItem>
        {
          summarySubitemsVisible &&
          (
            <>
              {
                user && !user.onlyFolderCourses &&
                (
                  <MenuItem onClick={e => { e.preventDefault(); onSummaryClick(); setSummarySubitemsVisible(false); handleClose(); }} >
                    <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                      <ChevronRightIcon color="#888888" size={20} />
                    </span>
                    Gerado por IA
                  </MenuItem>
                )
              }
              <MenuItem onClick={e => { e.preventDefault(); onBlankSummaryClick(); setSummarySubitemsVisible(false); handleClose(); }} >
                <span style={{ width: '30px', display: 'flex', alignItems: 'center' }}>
                  <ChevronRightIcon color="#888888" size={20} />
                </span>
                Em branco
              </MenuItem>
            </>
          )
        }
      </Menu>
    </div>
  );
}

function SortButton({ isMobile, sortItems, sortBy, onSortChange, disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e, sortProperty) => {
    e.preventDefault(); 
    if (sortProperty != sortBy) {
      onSortChange(sortProperty);   
    }
    handleClose();
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title="Ordenar materiais na pasta">
        <Button      
          color="gray" 
          aria-controls="sort-simple-menu" 
          aria-haspopup="true" 
          onClick={handleClick}
          disabled={disabled}
          style={{ paddingLeft: '5px', paddingRight: '5px' }} 
        >
          <SortIcon color="#888888" />
        </Button>
      </Tooltip>
      <Menu
        id="sort-simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          sortItems && sortItems.map((item, index) => (
            <MenuItem key={index} sx={{ 
                fontSize: '0.8rem', 
                color: (sortBy == item.property) ? '#204279' : '#666666', 
                ...((sortBy == item.property) ? {
                  position: 'relative',
                  '&::before': {
                    content: '""', 
                    position: 'absolute',
                    left: '-4px',
                    top: '5px',
                    bottom: '5px',
                    width: '8px',
                    backgroundColor: '#204279',
                    borderRadius: '4px'
                  },
                  '&:hover': { backgroundColor: '#e8e8e8' } 
                } : {})
              }} 
              onClick={e => handleChange(e, item.property)}
            >
              {item.label}
            </MenuItem>    
          ))
        }
      </Menu>
    </div>
  );
}

const ColorSquares = ({ colors }) => {
  if (!colors || colors.length === 0) {
    return <div style={{ marginRight: '10px', width: '6px', height: '6px', border: '1px solid gray', backgroundColor: 'transparent', borderRadius: '3px' }} />;
  }

  return (
    <div style={{ display: 'flex', marginRight: '10px' }}>
      {colors.map((color, index) => (
        <div
          key={index}
          style={{
            width: '6px',
            height: '6px',
            backgroundColor: color,
            borderRadius: '3px',
            marginRight: index !== colors.length - 1 ? '2px' : '0',
          }}
        />
      ))}
    </div>
  );
};


function FilterByTypeButton({ isMobile, filterBy = [], onFilterChange, disabled }) {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e, type) => {
    e.preventDefault(); 
    if (filterBy.includes(type)) {
      onFilterChange(filterBy.filter(i => i != type));   
    } else {
      onFilterChange([...filterBy, type]);
    }
    //handleClose();
  };

  const filterItems = [
    { type: 'mindmap', label: 'Mapa inteligente', svgIcon: color => <MindMapIcon color={color} /> },
    { type: 'summary', label: 'Resumo', svgIcon: color => <SummaryIcon color={color} /> },
    { type: 'flashcard', label: 'Flashcard', svgIcon: color => <FlashcardIcon color={color} /> },
  ];

  const containsType = type => (filterBy || []).includes(type);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title="Filtrar por tipo do material">
        <Button      
          color="gray" 
          aria-controls="filter-simple-menu" 
          aria-haspopup="true" 
          onClick={handleClick}
          disabled={disabled}
          style={{ paddingLeft: '5px', paddingRight: '5px' }} 
        >
          <FilterIcon size={20} color="#888888" />
        </Button>
      </Tooltip>
      <Menu
        id="filter-simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          filterItems && filterItems.map((item, index) => {
            const contains = containsType(item.type);
            const color = contains ? getColorFromType(item.type) : '#666666';

            return <MenuItem key={index} sx={{ 
                  fontSize: '0.8rem', 
                  color: color, 
                  ...(contains ? {
                    position: 'relative',
                    '&::before': {
                      content: '""', 
                      position: 'absolute',
                      left: '-4px',
                      top: '1px',
                      bottom: '1px',
                      width: '8px',
                      backgroundColor: getColorFromType(item.type)
                    },
                    '&:hover': { backgroundColor: '#e8e8e8' } 
                  } : {})
                }} 
                onClick={e => handleChange(e, item.type)}
              >
                <span style={{width: '28px'}}>
                  {item.svgIcon ? item.svgIcon(color) : null}
                </span>
                {item.label}
              </MenuItem>  ;
          })
        }
      </Menu>
    </div>
  );
}

function RequestItemHeader({ request, isPending, expanded, toogleExpanded, isMobile, setMessageError }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(request.checked == true);
  }, [request]);

  const handleChangeChecked = async e => {
    const newValue = !checked;

    e.stopPropagation();
    setChecked(newValue);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${request._id}/update-checked`, { checked: newValue }, {
        headers: { Authorization: token }
      })
    } catch (error) {
      setMessageError('Falha ao salvar material!');
      setChecked(!newValue);
    }
  };

  return (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        padding: '0px 15px', 
        justifyContent: isMobile ? 'flex-start' : 'space-between',
        cursor: request.status === "completed" ? 'pointer' : 'default'
      }}
      onClick={e => {
        if (request.status === "completed") {
          e.preventDefault(); 
          toogleExpanded();
        }
      }}
    >
      <RequestChip type={request.type} />
      <Switch
        color={request.type}
        onClick={(event) => event.stopPropagation()}
        checked={checked}
        onChange={handleChangeChecked}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography style={{ flex: 1, fontSize: '0.8em', fontWeight: '600', color: '#666666' }} variant="body1">{request.text}</Typography>
      {
        isPending(request) && <CircularProgress size={24} style={{ color: '#888888', marginLeft: '8px' }} />
      }
      {
        (request.status === "failed") && (
          <Tooltip title="Falha no processamento">
            <ThumbDownAltIcon size={24} style={{ color: '#df3a3a', marginLeft: '8px' }} />
          </Tooltip>
        )
      }
      {(request.status === "completed" && !request.generatingPdf) && (
        <div style={{ display: 'flex', alignItems: 'center', minHeight: '30px' }}>
          {expanded ? <ExpandLessIcon style={{ color: '#888888' }} /> : <ExpandMoreIcon style={{ color: '#888888' }} />}
        </div>
      )}
    </div>
  );
}

function GeneratingMessage({ isMobile }) {
  return (
    <div style={{ textAlign: 'right', paddingRight: '15px', paddingTop: '5px' }}> 
      <Typography component="span" sx={{ color: '#1671dc', fontWeight: '600', fontSize: (isMobile ? '0.65rem' : '0.7rem') }}>
        {isMobile ? '' : 'Aguarde! '} O material está sendo gerado e estará disponível em breve
      </Typography>
    </div>
  );
}

function MoveSection({ isMobile, colorBgLabel = '#ffffff', removeRequestFromList, request, changeFolder, handleCancel, setMessageError, setMessageInfo }) {
  const [moving, setMoving] = useState(false);
  const [folder, setFolder] = useState(null);
  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro no carregamento das pastas.');
      }
    } catch (error) {
      setMessageError('Erro no carregamento das pastas.');
    }

    setFetchingAllFolders(false);
  };

  const selectFolderRef = useRef();

  useEffect(() => {
    fetchAllFolders();

    if (selectFolderRef.current) {
      selectFolderRef.current.openSelectDialog();
    }
  }, []);

  useEffect(() => {
    if (!folder) {
      setFolder((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);

  const handleMove = async () => {
    try {
      setMoving(true);

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${request._id}/move`, { folder: folder || null }, {
        headers: { Authorization: token }
      });

      if (response.data) {
        if (response.data.message) {
          setMessageInfo(response.data.message);
        } else {
          setMessageInfo('Material movido!');
        }

        setTimeout(() => {
          changeFolder(folder);
          removeRequestFromList(request._id);
          handleCancel();
        }, 50);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao mover material. Por favor, tente novamente.');
      }
    }

    setMoving(false);
  };

  const moveConfirmationStyle = isMobile ? 
  {
    display: 'flex',
    flexDirection: 'column',
  } : 
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const moveConfirmationButtonStyle = isMobile ? 
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '8px',
  } : 
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  return (
    <Box style={{...moveConfirmationStyle, padding: '15px' }}>
      <div style={{ display: 'flex', flexGrow: 1, paddingRight: (isMobile ? '0' : '20px') }}>
        <SelectFolder 
          colorBgLabel={colorBgLabel}
          ref={selectFolderRef}
          title={`Selecione a pasta de destino${!isMobile ? ' do material' : ''}`}
          isLoading={fetchingAllFolders}
          folders={folders} 
          currentFolderId={folder}
          onSelect={setFolder}
        />
      </div>
      <div style={moveConfirmationButtonStyle}>
        <Button size="small" onClick={e => { e.preventDefault(); handleMove(); }} style={{ marginRight: '8px', marginBottom: isMobile ? '8px' : '0' }} disabled={moving} variant="contained" color="gray">
          { moving ? 'Aguarde...' : 'Mover' }
        </Button>
        <Button size="small" onClick={e => { e.preventDefault(); handleCancel(); }} color="gray">
          Cancelar
        </Button>
      </div>
    </Box>
  );
}

function DeleteSection({ isMobile, removeRequestFromList, request, reload, handleCancel, setMessageError, setMessageInfo }) {
  const [deleting, setDeleting] = useState(false);

  const handleConfirmDelete = async () => {
    try {
      setDeleting(true);

      const token = localStorage.getItem('token');
      const response = await axios.delete(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${request._id}`, {
        headers: { Authorization: token }
      });

      if (response.data) {

        if (response.data.message) {
          setMessageInfo(response.data.message);
        } else {
          setMessageInfo('Material excluído!');
        }

        setTimeout(() => {
          reload();
          removeRequestFromList(request._id);
          handleCancel();
        }, 50);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao excluir material. Por favor, tente novamente.');
      }
    }

    setDeleting(false);
  };

  const deleteConfirmationStyle = isMobile ? 
  {
    display: 'flex',
    flexDirection: 'column',
  } : 
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const deleteConfirmationButtonStyle = isMobile ? 
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '8px',
  } : 
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  return (
    <Box style={deleteConfirmationStyle}>
      <Typography style={{ display: 'flex' }} variant="body2" style={{ flexGrow: 1 }}>Deseja realmente excluir?</Typography>
      <div style={deleteConfirmationButtonStyle}>
        <Button size="small" onClick={e => { e.preventDefault(); handleConfirmDelete(); }} style={{ marginRight: '8px', marginBottom: isMobile ? '8px' : '0' }} disabled={deleting} variant="contained" color="gray">
          { deleting ? 'Aguarde...' : 'Sim' }
        </Button>
        <Button size="small" onClick={e => { e.preventDefault(); handleCancel(); }} color="gray">
          Não
        </Button>
      </div>
    </Box>
  );
}

function EditTitleSection({ isMobile, setUpdatedTitle, request, handleCancel, setMessageError, setMessageInfo }) {
  const [editingTitle, setEditingTitle] = useState(null);
  const [editedText, setEditedText] = useState(''); 
  const textInputRef = useRef(null);

  useEffect(() => {
    if (request) {
      setEditedText(request.text);
      textInputRef.current.focus();
    } else {
      setEditedText('');
    }
  }, [request]);

  const handleSaveEditTitle = async (requestId) => {
    setEditingTitle(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${requestId}/update-text`, { text: editedText }, {
        headers: { Authorization: token }
      });

      if (response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Material renomeado!');
      }

      setUpdatedTitle(requestId, editedText);
      handleCancel();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao renomear material. Por favor, tente novamente.');
      }
    }

    setEditingTitle(false);
  };

  return (
    <div style={{ padding: '15px' }}>
      <CustomTextField 
        InputProps={{
          inputRef: textInputRef
        }}
        fullWidth
        value={editedText}
        onChange={(e) => setEditedText(e.target.value)}
        variant="outlined"
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button size="small" onClick={e => { e.preventDefault(); handleSaveEditTitle(request._id); }} style={{ marginRight: '8px', marginBottom: isMobile ? '8px' : '0' }} disabled={editingTitle} variant="contained" color="gray">
          { editingTitle ? 'Aguarde...' : 'Salvar' }
        </Button>
        <Button size="small" onClick={e => { e.preventDefault(); handleCancel(); }} color="gray">
          Cancelar
        </Button>
      </div>
    </div>
  );
}

function EditSummarySection({ isMobile, navigate, location, fetchingRequest, reload, request, initialSummary, handleCancel, setMessageError, setMessageInfo }) {
  const [editingSummary, setEditingSummary] = useState(null);
  const [summary, setSummary] = useState(null);
  const editorRef = useRef();

  useEffect(() => {
    setSummary(initialSummary || '');
  }, [initialSummary])

  const toolbarItems = [
    ['heading', 'bold', 'italic', 'hr'],
    ['ul', 'ol'],
    ['table']
  ];

  const handleChangeEditSummary = () => {
    const editorInstance = editorRef.current.getInstance();
    setSummary(editorInstance.getMarkdown());
  };

  const handleSaveEditSummary = async () => {
    setEditingSummary(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${request._id}/update-summary-data`, { summary }, {
        headers: { Authorization: token }
      });
      
      if (response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Resumo alterado!');
      }

      setTimeout(() => {
        reload();
        setSummary('');
        handleCancel();

        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('currentSummary');

        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
      }, 50);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar resumo. Por favor, tente novamente.');
      }
    }

    setEditingSummary(false);
  }; 

  return (
    <div style={{ padding: isMobile ? '0' : '15px' }}>
      {
        summary &&
        (
          <Editor
            ref={editorRef}
            initialValue={summary}
            previewStyle="vertical"
            height="600px"
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            onChange={handleChangeEditSummary}
            toolbarItems={toolbarItems}
          />
        )
      }
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Button size="small" onClick={e => { e.preventDefault(); handleSaveEditSummary(); }} style={{ marginRight: '8px', marginBottom: isMobile ? '8px' : '0' }} disabled={editingSummary} variant="contained" color="gray">
          { editingSummary ? 'Aguarde...' : 'Salvar' }
        </Button>
        <Button size="small" onClick={e => { e.preventDefault(); handleCancel(); }} color="gray">
          Cancelar
        </Button>
      </div>
    </div>
  );
}

const getColorFromType = (type) => {
  switch (type) {
    case 'mindmap':
      return '#6937ea';
    case 'summary':
      return '#b1900e';
    case 'flashcard':
      return '#499c6a';
    default:
      return '#666666';
  }
};

function RequestItemToolbar({ request, isMobile, fetchingRequest, navigate, onDelete, onMove, onEditTitle, onEditSummary, setMessageInfo, setMessageError }) {
  const [anchorElMoreAction, setAnchorElMoreAction] = useState(null);

  const handleClickMoreActions = e => { 
    e.preventDefault();
    setAnchorElMoreAction(e.currentTarget);
  };

  const handleCloseMoreActions = () => {
    setAnchorElMoreAction(null);
  };

  const [fetchingPdf, setFetchingPdf] = useState(false);
  const [fetchingSecPdf, setFetchingSecPdf] = useState(false);

  const handleOpenPrimaryUrl = async (e) => {
    return _handleOpenUrl(e, false, setFetchingPdf);
  };

  const handleOpenSecondaryUrl = async (e) => {
    return _handleOpenUrl(e, true, setFetchingSecPdf);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const loadAndOpenPdfInNewTab = async (requestId, isSecondary) => {
    if (isSecondary) {
      setMessageInfo('Aguarde! Abrindo PDF...');  
    }

    const token = localStorage.getItem('token');
    const url = `https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/pdf?type=request&id=${requestId}&token=${token}${isSecondary ? '&secondary=1' : ''}`;
    const resp = await axios.get(url);

    if (resp.data.pdf) {
      const binaryString = window.atob(resp.data.pdf);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      openInNewTab(pdfUrl);
    } else if (resp.data.redirectUrl) {
      openInNewTab(resp.data.redirectUrl);
    }
  };

  const _handleOpenUrl = async (e, isSecondary, setBusy) => {
    setBusy(true);

    try {
      e.preventDefault();

      await loadAndOpenPdfInNewTab(request._id, isSecondary);
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        if (request.type == 'flashcard') {
          setMessageError('Não foi possível baixar o arquivo.');
        } else {
          setMessageError('Não foi possível abrir o PDF.');
        }
      }
    }

    setBusy(false);
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
      <Box style={{ display: 'flex' }}>
        <Typography component="span" sx={{ color: '#000000', fontSize: '0.65rem' }}>
          { `${(!isMobile ? 'Material gerado em ' : '')} ${format(new Date(request.createdAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}` }
        </Typography>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title={(request.type == 'flashcard') ? 'Baixar arquivo' : 'Visualizar arquivo PDF'}>
          <Button size="small" disabled={fetchingPdf} color="primary" style={{marginRight: (isMobile ? '0' : '5px')}} onClick={handleOpenPrimaryUrl}>
            {
              (fetchingPdf ? <CircularProgress size={20} style={{ color: '#30445a', marginRight: (isMobile ? '0' : '5px') }} /> : 
                (
                  (request.type == 'flashcard') ? 
                  <FlashcardIcon color="#30445a" style={{marginRight: (isMobile ? '0' : '5px')}} /> : 
                  <PdfIcon color="#30445a" style={{marginRight: (isMobile ? '0' : '5px')}} />
                )
              )
            } 
            { !isMobile && (fetchingPdf ? 'Carregando...' : ((request.type == 'flashcard') ? 'Baixar arquivo' : 'PDF'))}
          </Button>
        </Tooltip>

        {
          (request.type == 'flashcard') && (
            <Tooltip title="Visualizar arquivo PDF">
              <Button size="small" disabled={fetchingSecPdf} color="primary" style={{marginRight: (isMobile ? '0' : '5px')}} onClick={handleOpenSecondaryUrl}>
                {
                  (fetchingSecPdf ? <CircularProgress size={20} style={{ color: '#30445a', marginRight: (isMobile ? '0' : '5px') }} /> : 
                    <PdfIcon color="#30445a" style={{marginRight: (isMobile ? '0' : '5px')}} />
                  )
                } 
                { !isMobile && (fetchingSecPdf ? 'Carregando...' : 'PDF')}
              </Button>
            </Tooltip>
          )
        }

        {
          (request.type == 'summary') &&
          (
            <Tooltip title="Editar resumo">
              <Button size="small" color="primary" style={{marginRight: '0px'}} disabled={fetchingRequest} onClick={e => { e.preventDefault(); onEditSummary(); }}>
                { (fetchingRequest ? <CircularProgress size={20} style={{ color: '#30445a', marginRight: (isMobile ? '0' : '5px') }} /> : <SummaryIcon color="#30445a" style={{ marginRight: (isMobile ? '0' : '5px') }} />) }
                { !isMobile && (fetchingRequest ? 'Carregando...' : 'Editar') }
              </Button>
            </Tooltip>
          )
        }

        {
          (request.type == 'mindmap') &&
          (
            <Tooltip title="Editar mapa inteligente">
              <Button size="small" color="primary" style={{marginRight: '0px'}} onClick={e => { e.preventDefault(); navigate(`/app/edit-smartmap/${request._id}`); }}>
                <MindMapIcon color="#30445a" style={{ marginRight: (isMobile ? '0' : '5px') }} />
                { !isMobile && 'Editar' }
              </Button>
            </Tooltip>
          )
        }

        <div>
          <Tooltip title="Mais ações">
            <Button 
              size="small" 
              color="primary" 
              onClick={handleClickMoreActions}
              aria-controls="more-actions-simple-menu"
              aria-haspopup="true" 
            >
              <MenuIcon size={22} color="#30445a" style={{marginRight: (isMobile ? '0' : '5px')}} />  { !isMobile && 'Ações' }
            </Button>
          </Tooltip>

          <Menu
            id="more-actions-simple-menu"
            anchorEl={anchorElMoreAction}
            keepMounted
            open={Boolean(anchorElMoreAction)}
            onClose={handleCloseMoreActions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem sx={{ fontSize: '0.8rem', '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={e => { e.preventDefault(); onMove(); handleCloseMoreActions(); }}>
              <span style={{ width: '28px' }}>
                <SendIcon color="#30445a" />
              </span>
              Mover para
            </MenuItem>
            <MenuItem sx={{ fontSize: '0.8rem', '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={e => { e.preventDefault(); onEditTitle(); handleCloseMoreActions(); }}>
              <span style={{ width: '28px' }}>
                <RenameIcon color="#30445a" />
              </span>
              Renomear
            </MenuItem>
            <MenuItem sx={{ fontSize: '0.8rem', '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={e => { e.preventDefault(); onDelete(); handleCloseMoreActions(); }}>
              <span style={{ width: '28px' }}>
                <DeleteIcon color="#a57474" />
              </span>
              Excluir
            </MenuItem>
          </Menu>
        </div>
        
      </div>
    </Box>
  );
}

function RequestItem({ index, opened, request, navigate, isMobile, onClick, changeFolder, updateItemInList, setUpdatedTitle, removeRequestFromList, reload, setMessageError, setMessageInfo }) {
  const [expanded, setExpanded] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [moveOpened, setMoveOpened] = useState(false);
  const [editTitleOpened, setEditTitleOpened] = useState(false);
  const [editSummaryOpened, setEditSummaryOpened] = useState(false);
  const [fetchingRequest, setFetchingRequest] = useState(false);
  const [summary, setSummary] = useState('');
  const timeoutID = useRef(null);

  const handleDeleteClick = () => {
    setDeleteOpened(true);
  };
    
  const handleCancelDelete = () => {
    setDeleteOpened(false);
  };

  const handleMoveClick = () => {
    setMoveOpened(true);
  };
    
  const handleCancelMove = () => {
    setMoveOpened(false);
  };
  
  const handleCancelEditTitle = () => {
    setEditTitleOpened(false);
  };

  const handleEditTitleClick = () => {
    setEditTitleOpened(true);
  };

  const handleCancelEditSummary = () => {
    setEditSummaryOpened(false);
  };

  const handleEditSummaryClick = async () => {
    if (opened) {
      setExpanded(true);
    }

    setFetchingRequest(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${request._id}?contentJson=1`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.contentJson) {
        setSummary(response.data.contentJson.summary || '');

        setTimeout(() => {
          setEditSummaryOpened(true);
        }, 50);
      } else {
        setMessageError('Erro ao carregar resumo!');
      }
    } catch(err) {
      console.log(err);
      setMessageError('Erro ao carregar resumo!');
    }

    setFetchingRequest(false);
  };

  const isPending = (request) => (request.status === "pending" || request.generatingPdf);

  const checkPending = async (index = 0) => {
    if (index > 120) {
      return;
    }

    if (request) {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }

      try {
        const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${request._id}`, {
          headers: { Authorization: token }
        });

        if (response && response.data && !isPending(response.data)) {
          setExpanded(true);
          setMessageInfo('Seu material está pronto!');

          if (updateItemInList) {
            updateItemInList(request._id, response.data);
          }
        } else {
          timeoutID.current = setTimeout(async () => {
            await checkPending(index + 1);
          }, 5000);
        }
      } catch(err) {

      }
    }
  };

  useEffect(() => {
    if (request && isPending(request)) {
      timeoutID.current = setTimeout(async () => {
        await checkPending(1);
      }, 5000);
    }

    return () => {
      if (timeoutID.current) {
        clearTimeout(timeoutID.current);
      }
    };

  }, [request]);

  useEffect(() => {
    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    }
  }, []);

  useEffect(() => {
    if (opened) {
      handleEditSummaryClick();
    }
  }, [opened]);

  const bgColor = (index % 2 == 0) ? '#ffffff' : '#f4f4f4';

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column', // Organiza em coluna para mobile e desktop
        padding: '10px 0',
        backgroundColor: bgColor,
        borderRadius: '8px',
        marginBottom: '8px',
        boxShadow: expanded ? '0 0 0 0.06em #bbbbbb' : 'none',
        '&::before': {
          content: '""', 
          position: 'absolute',
          left: '-3px',
          top: '9px',
          bottom: '9px',
          width: '6px',
          backgroundColor: getColorFromType(request.type),
          borderRadius: '3px'
        },
        '&:hover': {
          boxShadow: '0 0 0 0.06em #c7c7c7'
        }
      }}
    >
      <RequestItemHeader
        request={request}
        isPending={isPending}
        expanded={expanded}
        isMobile={isMobile}
        setMessageError={setMessageError}
        toogleExpanded={() => setExpanded(!expanded)} 
      />

      { isPending(request) && <GeneratingMessage isMobile={isMobile} /> }

      {
        expanded && !isPending(request) && 
        (
          <div style={{ padding: '0 20px', paddingTop: '10px', borderTop: '1px solid #eaeaea', marginTop: '5px' }}>
          {
            deleteOpened && 
            <DeleteSection 
              isMobile={isMobile} 
              handleCancel={handleCancelDelete} 
              removeRequestFromList={removeRequestFromList}
              request={request}
              reload={reload}
              setMessageError={setMessageError}
              setMessageInfo={setMessageInfo}
            />
          }

          {
            moveOpened && 
            <MoveSection 
              isMobile={isMobile} 
              colorBgLabel={bgColor}
              handleCancel={handleCancelMove} 
              removeRequestFromList={removeRequestFromList}
              request={request}
              changeFolder={changeFolder}
              setMessageError={setMessageError}
              setMessageInfo={setMessageInfo}
            />
          }

          {
            editTitleOpened && 
            <EditTitleSection
              isMobile={isMobile} 
              handleCancel={handleCancelEditTitle} 
              setUpdatedTitle={setUpdatedTitle}
              request={request}
              setMessageError={setMessageError}
              setMessageInfo={setMessageInfo}
            />
          }

          {
            editSummaryOpened && 
            <EditSummarySection
              isMobile={isMobile} 
              fetchingRequest={fetchingRequest}
              handleCancel={handleCancelEditSummary} 
              navigate={navigate}
              location={location}
              reload={reload}
              request={request}
              initialSummary={summary}
              setMessageError={setMessageError}
              setMessageInfo={setMessageInfo}
            />
          }

          {
            (!deleteOpened && !editTitleOpened && !editSummaryOpened && !moveOpened) && 
            <RequestItemToolbar
              request={request}
              isMobile={isMobile}
              fetchingRequest={fetchingRequest}
              navigate={navigate}
              onDelete={handleDeleteClick}
              onMove={handleMoveClick}
              onEditTitle={handleEditTitleClick}
              onEditSummary={handleEditSummaryClick}
              setMessageError={setMessageError}
              setMessageInfo={setMessageInfo}
            />
          }
          </div>  
        )
      }
    </Box>
  );
}

const FolderMoreActionsButton = ({ folder, onOpenRename, onOpenDelete }) => {
  const [anchorElFolderMoreAction, setAnchorElFolderMoreAction] = useState(null);

  const handleClickFolderMoreActions = e => { 
    e.stopPropagation();
    setAnchorElFolderMoreAction(e.currentTarget);
  };

  const handleCloseFolderMoreActions = e => {
    setAnchorElFolderMoreAction(null);

    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
  };

  const handleRenameFolderClick = e => { 
    if (onOpenRename) {
      onOpenRename(folder);  
    }
    
    handleCloseFolderMoreActions(e); 
    e.preventDefault();
    e.stopPropagation(); 
  };

  const handleDeleteFolderClick = e => {
    if (onOpenDelete) {
      onOpenDelete(folder);  
    }
    
    handleCloseFolderMoreActions(e); 
    e.preventDefault();
    e.stopPropagation(); 
  };

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title="Ações">
        <Button 
          size="small" 
          color="primary" 
          onClick={handleClickFolderMoreActions}
          aria-controls="folder-more-actions-simple-menu"
          aria-haspopup="true" 
        >
          <MenuIcon size={22} color="#30445a" /> 
        </Button>
      </Tooltip>
      <Menu
        id="folder-more-actions-simple-menu"
        anchorEl={anchorElFolderMoreAction}
        keepMounted
        open={Boolean(anchorElFolderMoreAction)}
        onClose={handleCloseFolderMoreActions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem sx={{ fontSize: '0.8rem', '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={handleRenameFolderClick}>
          <span style={{ width: '28px' }}>
            <RenameIcon color="#30445a" />
          </span>
          Renomear
        </MenuItem>
        <MenuItem sx={{ fontSize: '0.8rem', '&:hover': { backgroundColor: '#e8e8e8' } }} onClick={handleDeleteFolderClick}>
          <span style={{ width: '28px' }}>
            <DeleteIcon color="#a57474" />
          </span>
          Excluir
        </MenuItem>
      </Menu>
    </div>
  );
};
function ListFolders() {
  const [requests, setRequests] = useState([]);
  const [fetchingRequests, setFetchingRequests] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0);
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const folder = queryParams.get('folder');
  const currentSummary = queryParams.get('currentSummary');
  
  const isInitialMount = useRef(true);
  const textInputRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [fetchingFolders, setFetchingFolders] = useState(false);
  const [folders, setFolders] = useState([{ newFolder: true }]); // primeiro item é o "criar nova pasta"
  const [folderChain, setFolderChain] = useState([{ _id: null, name: 'Início' }]);
  
  const { user } = useUser();

  const [filter, setFilter] = useState({
    searchText: '',
    currentFolder: folder || null,
    currentPage: 1,
    sortProperty: (user && !user.onlyFolderCourses) ? 'date_desc' : 'name_asc',
    filterProperty: ['mindmap', 'summary', 'flashcard']
  });

  const updateFilter = (input) => {
    let newFilter = {...filter};

    if (input.hasOwnProperty('newCurrentFolder')) {
      newFilter = {...newFilter, currentFolder: input.newCurrentFolder};
    }

    if (input.hasOwnProperty('newCurrentPage')) {
      newFilter = {...newFilter, currentPage: input.newCurrentPage};
    }

    if (input.hasOwnProperty('newSearchText')) {
      newFilter = {...newFilter, searchText: input.newSearchText || ''};
    }

    if (input.hasOwnProperty('newSortProperty')) {
      newFilter = {...newFilter, sortProperty: input.newSortProperty || ''};
    }

    if (input.hasOwnProperty('newFilterProperty')) {
      newFilter = {...newFilter, filterProperty: input.newFilterProperty || ''};
    }

    setFilter(currentFilter => {
      const change = !_.isEqual(newFilter, currentFilter);

      return change ? newFilter : currentFilter;
    });
  }
  
  useLayoutEffect(() => {
    if (folder != filter.currentFolder) {
      updateFilter({ newCurrentFolder: folder || null, newCurrentPage: 1 });  
    }
  }, [folder]);

  
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const searchInputRef = useRef(null);
  
  const handleSearchChange = (event) => {
    updateFilter({ newSearchText: event.target.value, newCurrentPage: 1 });
  };

  const debouncedSearch = useCallback(
    _.debounce((filter) => {
      fetchRequests(filter);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(filter);
    return () => debouncedSearch.cancel();
  }, [filter, debouncedSearch]);

  const handleOpenSearch = () => {
    setSearchVisible(true);
  };

  const handleCloseSearch = () => {
    setSearchVisible(false);
    updateFilter({ newSearchText: '', newCurrentPage: 1 });
  };

  useLayoutEffect(() => {
    if (searchVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchVisible]);

  const fetchFolders = useCallback(async (parentFolderId) => {
    setFetchingFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders?folder=${parentFolderId}`, {
        headers: { Authorization: token }
      });

      if (response.data.folders && user && user.onlyFolderCourses) {
        if ((!folder || folder == '') && response.data.folders.length == 1) {
          navigate(`/app/folders?folder=${response.data.folders[0]._id}`);
          return;
        }
      }

      setFolders([].concat(response.data.folders || []).concat([{ newFolder: true }]));
      setFolderChain([{ _id: null, name: 'Início' }].concat(response.data.currentFolderChain || []));
    } catch (error) {
      setMessageError('Erro no carregamento das pastas.');
    }

    setFetchingFolders(false);
  }, [filter.currentFolder]);

  const fetchRequests = useCallback(async ({ currentFolder, currentPage, sortProperty, filterProperty, searchText }) => {
    setFetchingRequests(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests?${currentFolder ? ('folder='+ currentFolder + '&') : ''}page=${currentPage}&sort_by=${sortProperty}&filter_by=${(filterProperty||[]).join('_')}&search=${encodeURIComponent(searchText)}`, {
        headers: { Authorization: token }
      });

      setRequests(response.data.requests);
      setTotalPages(response.data.totalPages);
      setTotalRequests(response.data.totalRequests);

      if (isInitialMount.current) {
        isInitialMount.current = false;
      }
    } catch (error) {
      setMessageError('Erro no carregamento dos materiais. Por favor, tente novamente.');
    }

    setFetchingRequests(false);
  }, [filter.currentPage, filter.currentFolder, filter.sortProperty, filter.filterProperty]);

  useLayoutEffect(() => {
    fetchFolders(filter.currentFolder);

    const summaryProp = currentSummary ? ('currentSummary=' + currentSummary + '&') : '';

    if (filter.currentFolder) {
      if (location.pathname != ('?' + `${summaryProp}folder=${filter.currentFolder}`)) {
        navigate('?' + `${summaryProp}folder=${filter.currentFolder}`);
      }
    } else {
      if (location.pathname != ('?' + `${summaryProp}folder=`)) {
        navigate('?' + `${summaryProp}folder=`);
      }
    }
  }, [filter.currentFolder, fetchFolders]);

  const goToPreviousPage = () => {
    updateFilter({ newCurrentPage: filter.currentPage - 1 });
  };

  const goToNextPage = () => {
    updateFilter({ newCurrentPage: filter.currentPage + 1 });
  };

  const handleNewFolderClick = function () {
    setOpenNewFolder(true);
  };

  const handleFolderClick = function(folder) {
    changeFolder(folder ? folder._id : null);
  };

  const changeFolder = function(folderId) {
    setSearchVisible(false);
    updateFilter({ newSearchText: '', newCurrentPage: 1, newCurrentFolder: folderId });
  };

  const handleFolderChainClick = function(idx) {
    const _folder = folderChain[idx];
    const newFolderChain = [].concat(folderChain.slice(0, idx+1));
    setFolderChain(newFolderChain);

    handleFolderClick(_folder);
  };

  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [formDataNewFolder, setFormDataNewFolder] = useState('');
  const [creatingNewFolder, setCreatingNewFolder] = useState(false);
  
  const handleCloseNewFolder = () => {
    setFormDataNewFolder('');
    setOpenNewFolder(false);
  };

  const handleChangeNewFolderData = (event) => {
    setFormDataNewFolder(event.target.value);
  };

  const handleSaveNewFolder = async () => {
    setCreatingNewFolder(true);

    try {
      const input = { name: formDataNewFolder, parent: filter.currentFolder };

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders`, input, {
        headers: { Authorization: token }
      });

      if (response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Pasta criada!');
      }

      setTimeout(() => {
        fetchFolders(filter.currentFolder);
      }, 50);

      setFormDataNewFolder('');
      setOpenNewFolder(false);
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar título. Por favor, tente novamente.');
      }
    }

    setCreatingNewFolder(false);
  };

  const [openRenameFolderId, setOpenRenameFolderId] = useState(null);
  const [formDataRenameFolder, setFormDataRenameFolder] = useState('');
  const [renamingFolder, setRenamingFolder] = useState(false);
  
  const handleCloseRenameFolder = () => {
    setFormDataRenameFolder('');
    setOpenRenameFolderId(null);
  };

  const handleChangeRenameFolderData = (event) => {
    setFormDataRenameFolder(event.target.value);
  };

  const handleSaveRenameFolder = async () => {
    setRenamingFolder(true);

    try {
      const input = { name: formDataRenameFolder };

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/${openRenameFolderId}/rename`, input, {
        headers: { Authorization: token }
      });

      if (response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Pasta renomeada!');
      }

      setFolders((folders || []).map(folder => 
        (folder && (folder._id === openRenameFolderId)) ? { ...folder, name: formDataRenameFolder } : folder
      ));

      handleCloseRenameFolder();
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao renomear pasta. Por favor, tente novamente.');
      }
    }

    setRenamingFolder(false);
  };

  const [openDeleteFolderId, setOpenDeleteFolderId] = useState(null);
  const [deletingFolder, setDeletingFolder] = useState(false);
  
  const handleCloseDeleteFolder = () => {
    setOpenDeleteFolderId(null);
  };

  const handleSaveDeleteFolder = async () => {
    setDeletingFolder(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/${openDeleteFolderId}`, {
        headers: { Authorization: token }
      });

      if (response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Pasta excluída!');
      }
      
      setFolders((folders || []).filter(folder => !folder || (folder._id !== openDeleteFolderId)));
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao excluir pasta. Por favor, tente novamente.');
      }
    }

    handleCloseDeleteFolder();
    setDeletingFolder(false);
  };

  const handleBack = () => {
    const newFolderChain = [].concat(folderChain);
    newFolderChain.pop();
    setFolderChain(newFolderChain);
    setSearchVisible(false);
    updateFilter({ newSearchText: '', newCurrentPage: 1, newCurrentFolder: newFolderChain[newFolderChain.length-1]._id });
  };

  const sortItems = [
    { label: 'Nome (A-Z)', property: 'name_asc' },
    { label: 'Nome (Z-A)', property: 'name_desc' },
    { label: 'Data (mais recentes)', labelMobile: 'Mais recentes', property: 'date_desc' },
    { label: 'Data (mais antigos)', labelMobile: 'Mais antigos', property: 'date_asc' }
  ];

  const handleChangeSortProperty = (sortProperty) => {
    updateFilter({ newSortProperty: sortProperty, newCurrentPage: 1 });
  };

  const handleChangeFilterProperty = (filterProperty) => {
    updateFilter({ newFilterProperty: filterProperty, newCurrentPage: 1 });
  };

  /*
  return (
      <Container component="main" maxWidth="md" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        paddingTop: '50px',
        textAlign: 'center',
        height: '100vh'
      }}>
        <GeneratingIcon />
        <Typography component="h2" variant="h2" style={{ fontWeight: '500', marginTop: '40px' }}>
          Você ainda não gerou materiais!
        </Typography>

        <Typography component="h3" variant="h3" color="secondary" style={{ fontWeight: '700', marginTop: '15px' }}>
          Comece agora sua experiência
        </Typography>

        <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate(`/app/home`)}
            style={{marginTop: '50px'}}
            size="large"
        >
            Gerar meu primeiro material
        </Button>
      </Container>
  );
  */

  const handleOpenRenameFolder = (folder) => {
    const { _id: folderId, name: folderName } = folder || {};
    
    setFormDataRenameFolder(folderName);
    setOpenRenameFolderId(folderId);
  };
  const handleOpenDeleteFolder = (folder) => {
    const { _id: folderId } = folder || {};
    setOpenDeleteFolderId(folderId);
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <div style={{ display: 'flex', color: '#556c8a', margin: '30px 0 5px 0', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <FolderIcon size="30" color="#556c8a" />
            <Link 
              component="button" 
              variant="body2" 
              sx={{ 
                color: '#556c8a', 
                fontWeight: '600', 
                fontSize: '18px', 
                padding: '10px', 
                paddingLeft: '10px',
                textDecoration: 'none',
                '&:hover': {
                    color: '#334966',
                    textDecoration: 'underline'
                  }
              }}
              onClick={() => handleFolderChainClick(0)}
            > 
              Meus Materiais
            </Link>
          </div>

          {
            folderChain && folderChain.length > 1 &&
            (
              <Button 
                  variant="outlined" 
                  color="primary" 
                  size="small"
                  onClick={handleBack}
              >
                <ArrowLeftIcon color="#30445a" style={{ marginRight: '5px' }} /> Voltar
              </Button>
            )
          } 
        </div>
        
        <HorizontalList items={folderChain.map(i => i.name)} onClick={handleFolderChainClick} />

        {
          fetchingFolders &&
          (
            <Grid container spacing={1}>
              {Array.from(new Array(2)).map((_, index) => (
                <Grid item xs={6} key={index}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={50}
                    style={{ borderRadius: '8px', marginBottom: '8px' }}
                  />
                </Grid>
              ))}
            </Grid>
          )
        }

        { !fetchingFolders && folders && 
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px',  marginTop: '20px' }}>
            {folders.map((folder, index) => (
              <Button 
                key={(folder.newFolder) ? 'new_folder' : folder._id} 
                onClick={() => (folder.newFolder) ? handleNewFolderClick() : handleFolderClick(folder)}
                sx={{
                  textAlign: 'left',
                  flex: isMobile ? '1 0 100%' : '1 0 48%', // Alteração aqui
                  padding: '15px',
                  backgroundColor: (folder.newFolder) ? 'transparent' : (isMobile ? ((index % 2 === 0)? '#ffffff' : '#f4f4f4') : ((index % 4 === 0 || index % 4 === 3) ? '#ffffff' : '#f4f4f4')),
                  borderRadius: '8px',
                  justifyContent: 'space-between',
                  boxShadow: ((folder.newFolder) ? '0 0 0 0.06em #596e8f' : null),
                  '&:hover': {
                    backgroundColor: (folder.newFolder) ? '#c9ced4' : (isMobile ? ((index % 2 === 0)? '#ffffff' : '#f4f4f4') : ((index % 4 === 0 || index % 4 === 3) ? '#ffffff' : '#f4f4f4')),
                    boxShadow: ((folder.newFolder) ? '0 0 0 0.06em #9ba2ae' : '0 0 0 0.06em #9aa2ad')
                  }
                }}
              >
                <div style={{ display: 'flex'}}>
                  <i className={`bx bx-${(folder.newFolder) ? 'folder-plus' : 'folder'}`} style={{ fontSize: '20px', color: ((folder.newFolder) ? '#204279' : '#444444') }} />
                  <Typography style={{ marginLeft: '6px', fontSize: '1em', fontWeight: '500', color: ((folder.newFolder) ? '#204279' : '#444444') }}>
                    { (folder.newFolder) ? 'Criar nova pasta' : folder.name }
                  </Typography>
                </div>
                {
                  (!folder.newFolder) && 
                    <FolderMoreActionsButton 
                      folder={folder} 
                      onOpenRename={handleOpenRenameFolder} 
                      onOpenDelete={handleOpenDeleteFolder} 
                    />
                }
              </Button>
            ))}
            {!isMobile && folders && folders.length % 2 !== 0 && (
              <div style={{ flex: '1 0 48%', padding: '15px', opacity: 0 }}>
                {/* Elemento fantasma invisível */}
              </div>
            )}
          </Box>
        }

        { 
          ((!fetchingFolders && folders) || (!fetchingRequests && requests)) && 
          (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '30px 0 20px 0' }}>
              {
                ((!fetchingFolders && folders) && (!fetchingRequests && requests)) ?
                (
                  <span style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                    <i className="bx bx-file" style={{ color: '#999999', fontSize: '14px', marginRight: '3px' }} />
                    <Typography component="body1" style={{ color: '#666666', fontWeight: '500', fontSize: '12px', marginRight: '10px' }}>
                        {((totalRequests == 1 && !isMobile) ? '1 material' : `${totalRequests}${isMobile ? '' : ' materiais na pasta'}`)}
                    </Typography>

                    {
                      user && !user.onlyFolderCourses &&
                      (
                        <>
                          <SortIcon color="#204279" size={14} style={{ marginRight: '3px' }} />
                          <Typography component="body1" style={{ color: '#204279', fontWeight: '500', fontSize: '12px', marginRight: '10px' }}>
                              { 
                                (() => {
                                  const item = (sortItems.find(i => i.property == filter.sortProperty) || { label: '...', labelMobile: '...' });
                                  return isMobile ? (item.labelMobile || item.label) : item.label;
                                })()
                              }
                          </Typography>

                          <ColorSquares colors={(filter.filterProperty||[]).sort().map(type => getColorFromType(type))} />
                        </>
                      )
                    }
                  </span>
                ) : (<span></span>)
              }
              
              <div style={{ display: 'flex'}}>
                {
                  !searchVisible &&
                  (
                    <Tooltip title="Buscar materiais na pasta">
                      <Button 
                        color="gray" 
                        onClick={handleOpenSearch}
                        style={{ paddingLeft: '5px', paddingRight: '5px' }} 
                      >
                        <SearchIcon color="#888888" />
                      </Button>
                    </Tooltip>
                  )
                }

                {
                  user && !user.onlyFolderCourses &&
                  (
                    <>
                      <SortButton
                        isMobile={isMobile}
                        sortItems={sortItems}
                        sortBy={filter.sortProperty}
                        onSortChange={handleChangeSortProperty}
                        disabled={fetchingFolders && fetchingRequests}
                      />

                      <FilterByTypeButton
                        isMobile={isMobile}
                        filterBy={filter.filterProperty}
                        onFilterChange={handleChangeFilterProperty}
                        disabled={fetchingFolders && fetchingRequests}
                      />
                    </>
                  )
                }     

                <NewRequestButton
                  user={user}
                  isMobile={isMobile}
                  onBlankMindmapClick={() => navigate(`/app/new-blank-smartmap${filter.currentFolder ? ('?folder=' + filter.currentFolder) : ''}`)}
                  onBlankSummaryClick={() => navigate(`/app/new-blank-summary${filter.currentFolder ? ('?folder=' + filter.currentFolder) : ''}`)}
                  onMarkdownMindmapClick={() => navigate(`/app/new-markdown-smartmap${filter.currentFolder ? ('?folder=' + filter.currentFolder) : ''}`)}
                  onMindmapClick={() => navigate(`/app/new-smartmap${filter.currentFolder ? ('?folder=' + filter.currentFolder) : ''}`)}
                  onFlashcardClick={() => navigate(`/app/new-flashcard${filter.currentFolder ? ('?folder=' + filter.currentFolder) : ''}`)}
                  onSummaryClick={() => navigate(`/app/new-summary${filter.currentFolder ? ('?folder=' + filter.currentFolder) : ''}`)}
                  disabled={fetchingFolders && fetchingRequests}
                />
              </div>
            </div>
          )
        }

        {
          !fetchingFolders && searchVisible &&
          (
            <div style={{ display: 'flex', boxShadow: '0 0 0 0.06em #a2a2a2', alignItems: 'center', marginBottom: '15px', backgroundColor: '#f1f1f1', borderRadius: '8px', padding: '15px' }}>
              <CustomTextField
                fullWidth
                placeholder="Buscar materiais"
                variant="standard"
                value={filter.searchText}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="#bbbbbb" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCloseSearch} style={{ color: '#bbbbbb' }}>
                        <Close color="#bbbbbb" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputRef: searchInputRef
                }}
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 'none',
                  },
                  '& .MuiInput-underline:focus': {
                    borderBottom: 'none',
                  }
                }}
              />
            </div>
          )
        }

        {
          ((!fetchingFolders && !fetchingRequests) && (!requests || requests.length == 0)) &&
          (
            <div style={{
              padding: '13px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
            }}>
              <Typography variant="h6" component="span" marginLeft="0.5rem" style={{ marginTop: '15px', color: '#30445a', fontWeight: '500' }}>
                Nenhum material
              </Typography>
            </div>
          )
        }

        {
          (fetchingRequests || fetchingFolders) ? (
            <div style={{ marginTop: '20px' }}>
              {
                Array.from(new Array(2)).map((_, index) => (
                  <Skeleton key={index} variant="rectangular" width="100%" height={50} style={{ borderRadius: '8px', marginBottom: '8px' }} />
                ))  
              }
            </div>
          ) : (
            requests && requests.map((request, index) => (
              <RequestItem
                key={(request && request._id) || index}
                opened={request && request._id && (request.type == 'summary') && (request._id == currentSummary)}
                index={index}
                request={request}
                navigate={navigate}
                isMobile={isMobile}
                changeFolder={changeFolder}
                updateItemInList={(requestId, newRequest) => {
                  setRequests((requests||[]).map(request => 
                    request._id === requestId ? { ...newRequest } : request
                  ));
                }}
                setUpdatedTitle={(requestId, editedText) => {
                  setRequests((requests||[]).map(request => 
                    request._id === requestId ? { ...request, text: editedText } : request
                  ));
                }}
                removeRequestFromList={(requestId) => {
                  setRequests((requests||[]).filter(request => request._id !== requestId));
                }}
                reload={() => {
                  fetchRequests(filter);
                }}
                setMessageError={setMessageError}
                setMessageInfo={setMessageInfo}
              />
            ))
          )
        }
        
        {
          ((!fetchingFolders && !fetchingRequests) && requests && requests.length > 0) &&
          (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px', marginBottom: '30px' }}>
              <Button 
                  color="primary" 
                  disabled={filter.currentPage === 1} 
                  onClick={goToPreviousPage}
              >
                  <ArrowLeftIcon color={(filter.currentPage === 1) ? '#a1a1a1' : '#30445a'} /> {!isMobile && <span style={{ marginLeft: '6px' }}>Anterior</span>}
              </Button>

              <Typography variant="body2" style={{ fontSize: '0.75em', color: '#666666', fontWeight: '600' }}>
                  {!isMobile && 'Página '} {filter.currentPage} de {totalPages}
              </Typography>
              
              <Button 
                  color="primary" 
                  disabled={filter.currentPage === totalPages} 
                  onClick={goToNextPage}
              >
                  {!isMobile && <span style={{ marginRight: '6px' }}>Próxima</span>} <ArrowRightIcon color={(filter.currentPage === totalPages) ? '#a1a1a1' : '#30445a'} />
              </Button>
            </div>
          )
        }

        <Dialog 
          open={openNewFolder} 
          onClose={handleCloseNewFolder}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <CustomTextField
              autoFocus
              label="Pasta"
              placeholder="Informe o nome da nova pasta"
              type="text"
              fullWidth
              variant="standard"
              value={formDataNewFolder}
              onChange={handleChangeNewFolderData}
            />
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="outlined" onClick={handleCloseNewFolder}>Cancelar</Button>
            <Button size="medium" color="info" variant="contained" onClick={handleSaveNewFolder} disabled={creatingNewFolder}>{ creatingNewFolder ? 'Aguarde...' : 'Salvar' }</Button>
          </DialogActions>
        </Dialog>


        <Dialog 
          open={openRenameFolderId} 
          onClose={handleCloseRenameFolder}
          PaperProps={{
            style: { width: '500px' },
          }}
        >
          <DialogContent>
            <CustomTextField
              autoFocus
              label="Pasta"
              placeholder="Informe o nome da pasta"
              type="text"
              fullWidth
              variant="standard"
              value={formDataRenameFolder}
              onChange={handleChangeRenameFolderData}
            />
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="outlined" onClick={handleCloseRenameFolder}>Cancelar</Button>
            <Button size="medium" color="info" variant="contained" onClick={handleSaveRenameFolder} disabled={renamingFolder}>{ renamingFolder ? 'Aguarde...' : 'Renomear' }</Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openDeleteFolderId} 
          onClose={handleCloseDeleteFolder}
          PaperProps={{
            style: { width: '400px' },
          }}
        >
          <DialogContent>
            <Typography style={{ display: 'flex' }} variant="body2" style={{ flexGrow: 1 }}>Deseja realmente excluir?</Typography>
          </DialogContent>
          <DialogActions>
            <Button size="medium" color="info" variant="outlined" onClick={handleCloseDeleteFolder}>Não</Button>
            <Button size="medium" color="info" variant="contained" onClick={handleSaveDeleteFolder} disabled={deletingFolder}>{ deletingFolder ? 'Aguarde...' : 'Sim' }</Button>
          </DialogActions>
        </Dialog>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
      </Container>
    </>
  );
}

export default ListFolders;
