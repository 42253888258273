import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext(null);

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/user/me', {
          headers: { Authorization: token }
        });
        if (response.data && response.data.me) {
          setUser(response.data.me);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário', error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isLoading, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};