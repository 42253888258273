import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& input': {
	    fontSize: 16, // Define o tamanho da fonte como 16px para evitar zoom no mobile
	  }
  },
}));

function CustomTextField(props) {
  return (
    <StyledTextField
      {...props}
      autoComplete="off"
      variant={props.variant || 'outlined'}
      InputLabelProps={{ shrink: true }}
    />
  );
}

export default CustomTextField;
