import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Card, Typography, Button, TextField, FormControl, Dialog, 
  DialogActions, DialogContent, DialogTitle, List, ListItem, 
  ListItemText, IconButton, ListItemSecondaryAction, Box, Tooltip,
  Skeleton, Link, InputLabel
} from '@mui/material';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';

function SudoUpdateMindmap() {
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [prompt1, setPrompt1] = useState('');

  const getLevel = (level) => {
    let lvl = '';

    for (let i = 0; i < level; i++) {
      lvl += '#';
    }

    return lvl;
  };
  
  const getMindmapString = (str, nodeData, level = 1) => {
    str = `${getLevel(level)} ${nodeData.topic} `;

    if (nodeData.children) {
      for (let item of nodeData.children) {
        str += getMindmapString(str, item, level + 1);
      }
    }

    return str;
  };

  const fetchMindmapData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    if (!requestId) {
      setMessageError('Informe o id do mapa inteligente!');
    }

    setLoading(true);
    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${requestId}/full-mindmap`, {
        headers: { Authorization: token }
      });

      setPrompt1(JSON.stringify({ mindmap: getMindmapString('', response.data.mindmapNode).trim(), flashcards: response.data.flashcards, summary: response.data.mindmapSummary }, null, 2));
    } catch (error) {
      console.error('Erro ao carregar dados', error);
    }
    setLoading(false);
  };
  
  const handleSubmit = async () => {
    setSaving(true);
    
    try {
      const token = localStorage.getItem('token');

      const input = { prompt1, requestId };
      
      await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/mindmap-from-prompts`, input, {
        headers: { Authorization: token }
      });

      setMessageInfo('Mapa atualizado!');

      setPrompt1('');
      setRequestId('');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar mapa');
      }
      console.error('Erro ao atualizar mapa', error);
    }
    
    setSaving(false);
  };

  return (
    <Container component="main" maxWidth="md" style={{ marginBottom: '30px' }}>
      <Card sx={{ 
        marginTop: '15px', display: 'flex', flexDirection: 'column', padding: '50px 40px'
      }}>
        
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <TextField
            fullWidth
            label="RequestID (id do mapa inteligente)"
            value={requestId}
            onChange={(e) => setRequestId(e.target.value)}
          />

          <Button onClick={fetchMindmapData} disabled={loading} size="small" color="primary" variant="contained" style={{ marginLeft: '8px' }}>
            { loading ? 'Aguarde...' : 'Carregar' }
          </Button>
        </div>

        <TextField
          fullWidth
          label="Resultado do Prompt 1 (mapa inteligente)"
          value={prompt1}
          multiline
          rows={4} 
          onChange={(e) => setPrompt1(e.target.value)}
          style={{ marginBottom: '12px', marginTop: '15px' }}
        />

        <Button onClick={handleSubmit} disabled={saving} size="medium" color="info" variant="contained">
          { saving ? 'Salvando ...' : 'Salvar' }
        </Button>
      </Card>
      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
    </Container>
  );
}

export default SudoUpdateMindmap;
