import React, { useEffect } from 'react';
import { Box, Button, Container, Grid, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { MindmapIcon, SummaryIcon, FlashcardsIcon } from './NewRequestIcons';
import { CoinIcon } from './MainIcons';
import { useUser } from './UserContext';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const BackButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function Home() {
  const navigate = useNavigate();
  const { user, isLoading } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folder = queryParams.get('folder');

  useEffect(() => {
    if (user && user.onlyFolderCourses == true) {
      navigate('/app/folders');
    }
  }, [user]);

  const IconButtonWithText = ({ children, color, mainText, secondaryText, credits, onClick }) => {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          cursor: 'pointer', 
          padding: '20px 30px 30px 30px',
          borderRadius: '10px', 
          backgroundColor: '#ffffff',
          border: '1px solid #ffffff',
          minHeight: '100%',
          justifyContent: 'space-between',
          '&:hover': {
            border: '1px solid #cccccc',
          },
          '@media (min-width:600px)': {
            padding: '20px 20px 30px 20px'
          } 
        }} 
        onClick={onClick}
      >
        <Tooltip title={`Custa ${credits} crédito${(credits > 1) ? 's' : ''}`}>
          <Typography component="span" sx={{ display: 'flex', alignItems: 'center', width: '35px', color: color, fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>
            { credits }
            <CoinIcon size={14} color={color} style={{ marginLeft: '3px' }} />
          </Typography>  
        </Tooltip>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          {children}
          <Typography variant="body1" sx={{ marginTop: '15px', color: '#666666', fontWeight: '700', fontSize: '18px' }}>
            {mainText}
          </Typography>
          <Typography sx={{ textAlign: 'center', '@media (min-width:600px)': { textAlign: 'left' }, marginTop: '15px', color: '#666666', fontWeight: '500', fontSize: '13px' }}>
            {secondaryText}
          </Typography>
        </Box>
        <Box sx={{ alignSelf: 'center', marginTop: '15px' }}>
          <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '12px', borderRadius: '6px', background: color, padding: '6px 15px' }}>
            {`Gerar um ${mainText}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
    {
      (isLoading || (user && user.onlyFolderCourses == true)) ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
            <CircularProgress style={{ width: '100px', height: '100px', color: '#888888' }} />
        </div>
      ) : 
      <Container component="main" maxWidth="md">
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexDirection: 'column',
            margin: '15px 0 30px 0',
            '@media (min-width:600px)': {
              margin: '40px',
            } 
          }}>
          <Typography component="h3" variant="h3" style={{ fontWeight: '700', color: '#444444', marginTop: '5px' }}>
            O que deseja gerar agora?
          </Typography>
        </Box>

        <Grid container spacing={2} justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={4}>
            <IconButtonWithText
              mainText="Mapa Inteligente"
              color="#6938ea"
              secondaryText="Insira um assunto ou um texto de sua escolha e deixe a mágica por nossa conta."
              onClick={() => navigate(`/app/new-smartmap${folder ? ('?folder=' + folder) : ''}`)}
              credits={1}
            >
              <MindmapIcon />
            </IconButtonWithText>
          </Grid>

          <Grid item xs={12} md={4}>
            <IconButtonWithText
              mainText="Flashcard"
              color="#499c6a"
              secondaryText="Não perca mais tempo criando baralhos para inserir no Anki. Fazemos isso em segundos para você."
              onClick={() => navigate(`/app/new-flashcard${folder ? ('?folder=' + folder) : ''}`)}
              credits={1}
            >
              <FlashcardsIcon />
            </IconButtonWithText>
          </Grid>

          <Grid item xs={12} md={4}>
            <IconButtonWithText
              mainText="Resumo"
              color="#b1900e"
              secondaryText="Estude qualquer assunto por um material enxuto e ao mesmo tempo poderosíssimo."
              onClick={() => navigate(`/app/new-summary${folder ? ('?folder=' + folder) : ''}`)}
              credits={1}
            >
              <SummaryIcon />
            </IconButtonWithText>
          </Grid>
        </Grid>

        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
          <BackButton
            onClick={() => navigate('/app/folders')}
            color="info"
            size="medium"
            style={{ fontWeight: '400' }}
          >
            Ir para o meu <span style={{ fontWeight: '700', marginLeft: '5px' }}>repositório de materiais</span>
          </BackButton>
        </Box>
      </Container>
    }
    </>
  );
}

export default Home;
