import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Button, TextField, FormControl, Dialog, 
  DialogActions, DialogContent, DialogTitle, List, ListItem, 
  ListItemText, IconButton, ListItemSecondaryAction, Box, Tooltip,
  Skeleton, Link, InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; //
import { createTheme, ThemeProvider } from '@mui/material/styles';

function ManageEditais() {
  const [editais, setEditais] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentEdital, setCurrentEdital] = useState(null);
  const [saving, setSaving] = useState(false);
  const [formEditData, setFormEditData] = useState({
    title: '',
    imageUrl: null,
    institution: null,
    testDate: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormEditData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const navigate = useNavigate();

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  useEffect(() => {
    fetchEditais();
  }, []);

  useEffect(() => {
    console.log(formEditData);
  }, [formEditData]);

  const fetchEditais = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais', {
        headers: { Authorization: token }
      });
      setEditais(response.data || []);
    } catch (error) {
      console.error('Erro ao carregar editais', error);
    }
    setLoading(false);
  };

  const handleEdit = (edital) => {
    setCurrentEdital(edital);
    setEditDialogOpen(true);
  };

  const handleDelete = (edital) => {
    setCurrentEdital(edital);
    setDeleteDialogOpen(true);
  };

  const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) {
    return;
  }

  const formData = new FormData();
  formData.append('file', file);

  const token = localStorage.getItem('token');

  try {
    const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais/${currentEdital._id}/upload-image`, formData, {
      headers: { Authorization: token }
    });

    setFormEditData({ ...formEditData, imageUrl: response.data.fileUrl });
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      setMessageError(error.response.data.error);
    } else {
      setMessageError('Erro no upload!');
    }
    console.error('Erro no upload:', error);
    // Trate o erro conforme necessário
  }
};

  const handleEditSubmit = async () => {
    setSaving(true);
    const token = localStorage.getItem('token');

    let currentId;

    if (currentEdital && currentEdital._id) {
      currentId = currentEdital._id;
    }
    
    try {
      if (currentId) {
        await axios.put(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais/${currentId}`, formEditData, {
          headers: { Authorization: token }
        });
      } else {
        await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais`, formEditData, {
          headers: { Authorization: token }
        });
      }
      
      fetchEditais();

      setMessageInfo('Edital alterado!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar edital');
      }
      console.error('Erro ao atualizar edital', error);
    }
    setEditDialogOpen(false);
    setSaving(false);
  };

  const handleDeleteSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.delete(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais/${currentEdital._id}`, {
        headers: { Authorization: token }
      });
      fetchEditais();

      setMessageInfo('Edital excluído!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao excluir edital');
      }
      console.error('Erro ao deletar edital', error);
    }
    setDeleteDialogOpen(false);
  };

  return (
    <Container component="main" maxWidth="md">
      <div style={{ margin: '30px 0px', display: 'flex', flexDirection: 'row' }}>
        <Link style={{ color: '#445770', fontWeight: '600', fontSize: '18px' }} component="button" variant="body2" onClick={() => navigate('/sudo')}>
          Administração
        </Link>
        <i style={{ color: '#556c8a', fontSize: '24px' }} className="bx bx-chevron-right" />
        <Typography style={{ color: '#556c8a', fontWeight: '600', fontSize: '18px' }}>
          Gerenciar Editais
        </Typography>
      </ div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', boxShadow: '0 0 0 0.125em #cccccc', marginBottom: '15px', backgroundColor: '#ffffff', borderRadius: '8px', padding: '15px' }}>
        <Button variant="contained" color="primary" onClick={() => handleEdit({})} startIcon={<AddIcon />} style={{ display: 'flex' }}>
          Novo Edital
        </Button>
      </div>
      {loading ? (
        <Container component="main" style={{ maxWidth: '800px', marginTop: '40px' }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton style={{ marginTop: '10px' }} variant="rectangular" width="100%" height={40} />
        </Container>
      ) : (

        <div>
          {editais.map((edital, index) => (
            <div key={edital._id} style={{
                    display: 'flex',
                    flexDirection: 'column', // Organiza em coluna para mobile e desktop
                    padding: '10px 20px',
                    backgroundColor: (index % 2 == 0) ? '#ffffff' : '#f4f4f4',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    marginBottom: '8px'
                  }}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography style={{ marginLeft: '10px', flex: 1, fontSize: '0.8em', fontWeight: '600', color: '#666666', padding: '8px', paddingLeft: '0px' }} variant="body1">
                    {edital.title}
                  </Typography>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title="Alterar edital">
                    <IconButton color="gray" edge="end" aria-label="Alterar edital" onClick={() => handleEdit(edital)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Excluir edital">
                    <IconButton color="gray" edge="end" aria-label="Excluir edital" onClick={() => handleDelete(edital)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
            </div>
          ))}
        </div>
      )}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Edital</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <FormControl fullWidth margin="normal">
            <TextField 
              fullWidth 
              label="Título" 
              value={formEditData.title} 
              onChange={handleChange}
              name="title"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField 
              fullWidth 
              label="Órgão" 
              value={formEditData.institution} 
              onChange={handleChange}
              name="institution"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
              <DatePicker
                label="Data da Prova"
                value={formEditData.testDate}
                onChange={(newValue) => {
                  handleChange({ target: { name: 'testDate', value: newValue } });
                }}
                renderInput={(params) => (<TextField {...params} />)}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="image-upload">Upload de Imagem</InputLabel>
            <input
              id="image-upload"
              name="image-upload"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageUpload}
              style={{ marginTop: '10px' }}
            />
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleEditSubmit} size="medium" color="info" variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <p>Você tem certeza que deseja excluir o edital "{currentEdital?.title}"?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleDeleteSubmit} color="primary" size="medium" color="info" variant="contained">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
    </Container>
  );
}

export default ManageEditais;
