import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Grid, Container, Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import LogoComponent from './LogoComponent';
import LayoutPublic from './LayoutPublic';
import useSnackbar from './useSnackbar';

function Register() {
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryStringParams = query => {
    return new URLSearchParams(query);
  }

  const queryParams = getQueryStringParams(location.search);
  const c = queryParams.get('c'); 

  useEffect(() => {
    if (c) {
      localStorage.setItem('c', c);
    }
  }, [c]);

  const {
    setMessageInfo,
    setMessageError,
    clearMessageInfo,
    clearMessageError,
    messageInfo,
    messageError
  } = useSnackbar();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const onlyNums = value.replace(/[^\d]/g, '');
      if (onlyNums.length <= 10) {
        setFormData(prevState => ({
          ...prevState,
          phone: onlyNums.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3').trim()
        }));
      } else if (onlyNums.length === 11) {
        setFormData(prevState => ({
          ...prevState,
          phone: onlyNums.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3').trim()
        }));
      }
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password.length < 6) {
      setMessageError('A senha deve ter pelo menos 6 caracteres.');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setMessageError('As senhas não coincidem.');
      return;
    }

    try {
      const localStorageC = localStorage.getItem('c') || '';
      const dataToSend = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        ...(localStorageC && { c: localStorageC })
      };

      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/user/register', dataToSend);

      if (response.data && response.data.user) {
        setMessageInfo('Conta criada! Acesse seu email para confirmação.');

        setTimeout(() => {
          navigate(`/email-verification/${response.data.user._id}`);
        }, 2400);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao criar conta. Por favor, tente novamente.');
      }
    }
  };

  return (
    <LayoutPublic>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Container component="main" maxWidth="xs">
          <LogoComponent notCentered="true" />
          <Typography variant="h3" component="h3" style={{ fontSize: '17px', fontWeight: '500', padding: '0 10px 15px 10px', color: '#5f6e7e' }}>
            Crie sua conta e <span style={{ fontWeight: '700' }}>ganhe 5 créditos</span> para desfrutar do Estudo Inteligente
          </Typography>

          <Box style={{ 
              padding: '30px', 
              backgroundColor: '#ffffff', 
              borderRadius: '8px', 
              marginTop: '15px',
              boxShadow: '0 0 0 0.1em #e7e7e7' 
          }}>
            <form onSubmit={handleSubmit}>
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Nome"
                name="name"
                autoFocus
                value={formData.name}
                onChange={handleChange}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="phone"
                label="Telefone (com DDD)"
                type="text"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirmar Senha"
                type="password"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: '20px' }}
              >
                Criar Conta
              </Button>
            </form>
          </Box>

          <Button
            onClick={() => navigate('/login')}
            fullWidth
            color="info"
            size="medium"
            style={{ marginTop: '10px' }}
          >
            Entrar
          </Button>

          <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
          <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
        </Container>
      </Grid>
    </LayoutPublic>
  );
}

export default Register;
