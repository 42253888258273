export const FlashcardsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={48}
    height={48}
    style={{
      enableBackground: "new 0 0 48 48",
    }}
    viewBox="0 0 682.667 682.667"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path fill="#499c6a" d="M0 512h512V0H0Z" data-original="#000000" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
      <path
        fill="none"
        stroke="#499c6a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={20}
        d="m0 0-53.81-14.418c-24.819-6.65-50.33 8.078-56.98 32.897l-87.557 326.768c-6.65 24.818 8.078 50.329 32.897 56.98l205.246 54.995c24.819 6.65 50.33-8.078 56.98-32.897l1.071-3.996"
        data-original="#000000"
        style={{
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(209.944 34.598)"
      />
      <path
        fill="none"
        stroke="#499c6a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={20}
        d="M0 0a6.532 6.532 0 0 1-6.524-6.524v-338.294A6.531 6.531 0 0 1 0-351.342h212.486a6.531 6.531 0 0 1 6.524 6.524V-6.524A6.532 6.532 0 0 1 212.486 0z"
        data-original="#000000"
        style={{
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(242.99 413.644)"
      />
      <path
        fill="none"
        stroke="#499c6a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={20}
        d="M0 0h-212.486c-25.695 0-46.524 20.83-46.524 46.524v338.294c0 25.695 20.829 46.524 46.524 46.524H0c25.694 0 46.524-20.829 46.524-46.524V46.524C46.524 20.83 25.694 0 0 0Z"
        data-original="#000000"
        style={{
          strokeWidth: 20,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(455.476 22.301)"
      />
      <path
        fill="#499c6a"
        d="m0 0-30.324-22.133c-11.73-8.562-27.551 2.932-23.033 16.734l11.679 35.68-30.56 22.101c-11.754 8.501-5.741 27.077 8.765 27.077h37.715l11.526 35.658c4.464 13.809 24 13.809 28.464 0l11.526-35.658h37.715c14.506 0 20.519-18.576 8.765-27.077l-30.56-22.101 11.679-35.68c4.518-13.802-11.303-25.296-23.033-16.734Z"
        data-original="#000000"
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(349.233 187.774)"
      />
    </g>
  </svg>
);

export const SummaryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={48}
    height={48}
    style={{
      enableBackground: "new 0 0 48 48",
    }}
    viewBox="0 0 367.6 367.6"
    {...props}
  >
    <g fill="#b1900e">
      <path
        d="M328.6 81.6c-.4 0-.4-.4-.8-.8s-.4-.8-.8-1.2L258.2 2.4c-.4-.4-1.2-.8-1.6-1.2-.4 0-.4-.4-.8-.4-.8-.4-2-.8-3.2-.8H83.8C59 0 38.6 20.4 38.6 45.2v277.2c0 24.8 20.4 45.2 45.2 45.2h200c24.8 0 45.2-20.4 45.2-45.2v-238c0-.8-.4-2-.4-2.8zm-68.4-54.4 44.4 50h-44.4v-50zM313.8 322c0 16.8-13.2 30.4-30 30.4h-200c-16.8 0-30-13.6-30-30V44.8c0-16.8 13.6-30 30-30H245v69.6c0 4 3.2 7.6 7.6 7.6h61.2v230z"
        data-original="#000000"
      />
      <path
        d="M92.6 92h66.8c4 0 7.6-3.2 7.6-7.6s-3.2-7.6-7.6-7.6H92.6c-4 0-7.6 3.2-7.6 7.6s3.6 7.6 7.6 7.6zm66.8 183.6H92.6c-4 0-7.6 3.2-7.6 7.6 0 4 3.2 7.6 7.6 7.6h66.8c4 0 7.6-3.2 7.6-7.6 0-4-3.6-7.6-7.6-7.6zM85 134.8c0 4 3.2 7.6 7.6 7.6H271c4 0 7.6-3.2 7.6-7.6 0-4-3.2-7.6-7.6-7.6H92.6c-4 0-7.6 3.2-7.6 7.6zm186 30H92.6c-4 0-7.6 3.2-7.6 7.6 0 4 3.2 7.6 7.6 7.6H271c4 0 7.6-3.2 7.6-7.6 0-4.4-3.2-7.6-7.6-7.6zm0 37.2H92.6c-4 0-7.6 3.2-7.6 7.6 0 4 3.2 7.6 7.6 7.6H271c4 0 7.6-3.2 7.6-7.6 0-4.4-3.2-7.6-7.6-7.6zm0 37.2H92.6c-4 0-7.6 3.2-7.6 7.6 0 4 3.2 7.6 7.6 7.6H271c4 0 7.6-3.2 7.6-7.6 0-4-3.2-7.6-7.6-7.6z"
        data-original="#000000"
      />
    </g>
  </svg>
)

export const MindmapIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={48}
    height={48}
    style={{
      enableBackground: "new 0 0 48 48",
    }}
    viewBox="0 0 414.4 414.4"
    {...props}
  >
    <path
      fill="#6938ea"
      d="M397.6 249.2h-32.8v-42.4c0-4.8-3.2-8-8-8H215.2v-34.4H248c9.6 0 16.8-7.2 16.8-16.8V66.8c0-9.6-7.2-16.8-16.8-16.8h-81.6c-9.6 0-16.8 7.2-16.8 16.8v81.6c0 9.6 7.2 16.8 16.8 16.8h32.8v33.6H57.6c-4.8 0-8 3.2-8 8v42.4H16.8C7.2 249.2 0 256.4 0 266v81.6c0 9.6 7.2 16.8 16.8 16.8h81.6c9.6 0 16.8-7.2 16.8-16.8V266c0-9.6-7.2-16.8-16.8-16.8H65.6v-34.4h133.6v34.4h-32.8c-9.6 0-16.8 7.2-16.8 16.8v81.6c0 9.6 7.2 16.8 16.8 16.8H248c9.6 0 16.8-7.2 16.8-16.8V266c0-9.6-7.2-16.8-16.8-16.8h-32.8v-34.4h133.6v34.4H316c-9.6 0-16.8 7.2-16.8 16.8v81.6c0 9.6 7.2 16.8 16.8 16.8h81.6c9.6 0 16.8-7.2 16.8-16.8V266c0-9.6-8-16.8-16.8-16.8zM99.2 266c-.8-.8 0-.8 0 0v81.6c0 .8 0 .8-.8.8H16.8c-.8 0-.8 0-.8-.8V266c0-.8 0-.8.8-.8l82.4.8zm149.6 0c-.8-.8 0-.8 0 0v81.6c0 .8 0 .8-.8.8h-81.6c-.8 0-.8 0-.8-.8V266c0-.8 0-.8.8-.8l82.4.8zm-83.2-118.4c.8.8 0 .8 0 0V66.8c0-.8 0-.8.8-.8H248c.8 0 .8 0 .8.8v81.6c0 .8 0 .8-.8.8l-82.4-1.6zm232.8 199.2c0 .8-.8.8 0 0l-82.4.8c-.8 0-.8 0-.8-.8V266c0-.8 0-.8.8-.8h81.6c.8 0 .8 0 .8.8v80.8z"
      data-original="#000000"
    />
  </svg>
);