import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, Link, Tooltip, IconButton, Box, TextField, Container, Typography } from '@mui/material';
import FolderNavigator, { findFolderById } from './FolderNavigator';
import CircularProgress from '@mui/material/CircularProgress';
import { SearchFolderIcon } from './MainIcons';

const SelectFolder = forwardRef(({ colorBgLabel, label = 'Pasta', title = 'Selecione uma pasta', folders, currentFolderId, onSelect, isLoading = false }, ref) => {
  const [selectFolderOpened, setSelectFolderOpened] = useState(false);
  const [currentSelectedFolder, setCurrentSelectedFolder] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);

  useEffect(() => {
  	if (folders) {
  		setCurrentFolder(findFolderById(currentFolderId, folders) || null);	
  	}
  }, [currentFolderId, folders]);

  const handleSelect = () => {
    if (onSelect) {
      onSelect(currentSelectedFolder ? currentSelectedFolder._id : null);
    }

    setSelectFolderOpened(false);
  };

  const handleOpenSelectDialog = () => {
    setSelectFolderOpened(true);
  };

  useImperativeHandle(ref, () => ({
    openSelectDialog: handleOpenSelectDialog
  }));

  const handleCancel = () => {
    setSelectFolderOpened(false);
  };

  const handleChangeFolder = (folder) => {
    setCurrentSelectedFolder(folder);
  };

  return (
    <>
      <FormControl variant="outlined" fullWidth sx={{ position: 'relative' }}>
        <InputLabel shrink sx={{ transform: 'translate(9px, -8px) scale(0.75)', background: colorBgLabel || '#ffffff', padding: '0 5px' }}>
          {label}
        </InputLabel>
        <div style={{
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '8px',
          padding: (isLoading ? '16.5px 14px' : '11.5px 14px') ,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          {
          	isLoading &&
          	(
          		<Typography style={{ display: 'flex', alignItems: 'center', color: '#888888', fontSize: '0.8rem' }}
	            >
	              <CircularProgress size={12} style={{ color: '#888888', marginRight: '8px' }} />
	              Carregando ...
	            </Typography>
          	)
          }
          {
          	!isLoading &&
          	<>
          	  <Link href="#" onClick={handleOpenSelectDialog} underline="hover" sx={{ fontSize: '0.8rem', color: '#182d6d', marginRight: 'auto' }}>
	            { (currentFolder ? currentFolder.name : '...') }
	          </Link>
	          <Tooltip title="Selecionar pasta">
	            <IconButton size="small" onClick={handleOpenSelectDialog}>
	              <SearchFolderIcon color="#182d6d" />
	            </IconButton>
	          </Tooltip>
      		</>
          }
        </div>
      </FormControl>
      <Dialog 
        open={selectFolderOpened} 
        onClose={handleCancel}
        PaperProps={{
          style: { width: '500px' },
        }}
      >
        <DialogTitle style={{ fontWeight: '500', border: '1px solid #cdcdcd', fontSize: '0.9rem', display: 'flex', alignItems: 'center' }}>
        	<SearchFolderIcon color="#444444" size={18} style={{ marginRight: '8px' }} /> { title }
        </DialogTitle>
        <DialogContent style={{ paddingTop: '20px' }}>
          {
            isLoading &&
            (
              <Typography style={{ display: 'flex', alignItems: 'center', color: '#888888', fontSize: '0.8rem' }}
              >
                <CircularProgress size={12} style={{ color: '#888888', marginRight: '8px' }} />
                Carregando ...
              </Typography>
            )
          }
          {
            !isLoading &&
            <FolderNavigator 
              folders={folders} 
              initialFolderId={currentFolder ? currentFolder._id : null}
              onChange={handleChangeFolder}
            />
          }
        </DialogContent>
        <DialogActions style={{ padding: '15px', paddingTop: '0' }}>
          <Button size="medium" color="info" variant="outlined" onClick={() => setSelectFolderOpened(false)}>Cancelar</Button>
          <Button size="medium" color="info" variant="contained" onClick={handleSelect}>Selecionar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default SelectFolder;
