import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box, Typography, Paper } from '@mui/material';
import { FixedSizeList as List } from 'react-window'; // Importando de react-window
import CustomTextField from './CustomTextField';

function renderRow(props) {
  const { index, style, data } = props;
  return React.cloneElement(data[index], { style });
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 50; // Ajuste a altura de cada item aqui

  return (
    <div ref={ref}>
      <List
        height={250}
        width="100%"
        itemSize={() => itemSize}
        itemCount={itemCount}
        itemData={itemData}
        {...other}
      >
        {renderRow}
      </List>
    </div>
  );
});

function customFilter(options, { inputValue }) {
  const modifiedInput = inputValue.replace(/\*/g, '.*').replace(/^\$/, '^');
  const regex = new RegExp(modifiedInput, 'i');
  return options.filter(option => regex.test(option.label));
}

export default function VirtualizedAutocomplete({ data, placeholder, label, noOptionsText, initialValue, renderOption, getOptionLabel, onChange }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (initialValue !== undefined) {
      console.log(initialValue);

      const initialItem = data.find(item => item._id == initialValue);
      console.log(initialItem);
      setValue(initialItem || null);
    }
  }, [initialValue, data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      style={{ width: '100%' }}
      popupIcon={<i className="bx bx-chevron-down" style={{ fontSize: '21px', color: '#888888' }} />} 
      clearIcon={<i className="bx bx-x" style={{ fontSize: '18px', color: '#bbbbbb' }} />} 
      sx={{
        '& .MuiAutocomplete-option:hover': {
            backgroundColor: '#f1f1f1',
          },
      }}
      noOptionsText={noOptionsText || 'Nenhum material encontrado'}
      getOptionLabel={getOptionLabel ? getOptionLabel : ((option) => option.label)}
      options={data}
      value={value}
      onChange={handleChange}
      renderInput={(params) => 
          <CustomTextField 
            variant="outlined"
            placeholder={placeholder}
            label={label || "Selecione uma opção"}
            {...params}
          />
        }
      filterOptions={customFilter}
      renderOption={renderOption}
    />
  );
}
