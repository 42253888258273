import axios from 'axios';

const setupAxiosInterceptors = (navigate) => {
  const interceptor = axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && (error.response.status === 401) || (error.response.status === 403)) {
        localStorage.removeItem('token');
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );

  return () => {
    axios.interceptors.response.eject(interceptor);
  };
};


export default setupAxiosInterceptors;
