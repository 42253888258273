import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

function ReadOnlyTextFieldWithCopy({ value, onCopied }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);

    if (onCopied) {
      onCopied();
    }
  };

  return (
    <TextField
      value={value}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={copyToClipboard} style={{ color: '#999999' }}>
              <FileCopyIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default ReadOnlyTextFieldWithCopy;
