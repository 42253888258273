import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Credits from './components/Credits';
import BuyCredits from './components/BuyCredits';
import Home from './components/Home';
import NewMindMap from './components/NewMindMap';
import NewMarkdownMindMap from './components/NewMarkdownMindMap';
import NewBlankMindMap from './components/NewBlankMindMap';
import NewBlankSummary from './components/NewBlankSummary';
import NewMindMapFromContent from './components/NewMindMapFromContent';
import NewSummary from './components/NewSummary';
import NewFlashcard from './components/NewFlashcard';
import NewFlashcardFromContent from './components/NewFlashcardFromContent';
import ListFolders from './components/ListFolders';
import Transaction from './components/Transaction';
import EmailVerification from './components/EmailVerification';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import FirstLogin from './components/FirstLogin';
import SendMail from './components/SendMail';
import QuestionsAndAnswers from './components/QuestionsAndAnswers';
import EditMindmap from './components/EditMindmap';
import ManageSubjects from './components/ManageSubjects';
import ManageTopics from './components/ManageTopics';
import ManageEditais from './components/ManageEditais';
import ManageFolderCourse from './components/ManageFolderCourse';
import SudoUpdateMindmap from './components/SudoUpdateMindmap';
import ViewRequest from './components/ViewRequest';
import Sudo from './components/Sudo';
import ContentRepository from './components/ContentRepository';
import { CssBaseline } from '@mui/material'; 
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AuthProvider } from './AuthProvider';
import { UserProvider } from './components/UserContext';
import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';
import 'boxicons/css/boxicons.min.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/app/home" />} />
              <Route path="/register"  element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/email-verification/:userId" element={<EmailVerification />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:userId" element={<ResetPassword />} />
              <Route path="/first-login" element={<FirstLogin />} />

              <Route path="/app/*" element={
                <ProtectedRoute>
                  <Routes>
                    <Route path="credits" element={<Credits />} />
                    <Route path="buy-credits" element={<BuyCredits />} />
                    <Route path="home" element={<Home />} />

                    <Route path="new-blank-smartmap" element={<NewBlankMindMap />} />
                    <Route path="new-blank-summary" element={<NewBlankSummary />} />
                    <Route path="new-markdown-smartmap" element={<NewMarkdownMindMap />} />
                    <Route path="new-smartmap" element={<NewMindMap />} />
                    <Route path="new-smartmap-from-content" element={<NewMindMapFromContent />} />
                    <Route path="new-summary" element={<NewSummary />} />
                    <Route path="new-flashcard" element={<NewFlashcard />} />
                    <Route path="new-flashcard-from-content" element={<NewFlashcardFromContent />} />
                    <Route path="folders" element={<ListFolders />} />
                    <Route path="transaction/:id" element={<Transaction />} />
                    <Route path="send-message" element={<SendMail />} />
                    <Route path="questions-answers" element={<QuestionsAndAnswers />} />
                    <Route path="edit-smartmap/:id" element={<EditMindmap />} />
                    <Route path="repository" element={<ContentRepository />} />
                    <Route path="view-smartmap/:id" element={<ViewRequest requestType="mindmap" />} />
                    <Route path="view-summary/:id" element={<ViewRequest requestType="summary" />} />
                    <Route path="view-flashcard/:id" element={<ViewRequest requestType="flashcard" />} />
                  </Routes>
                </ProtectedRoute>
              } /> 

              <Route path="/sudo/*" element={
                  <AdminRoute>
                    <Routes>
                      <Route path="/" element={<Sudo />} />
                      <Route path="manage-subjects" element={<ManageSubjects />} />
                      <Route path="manage-topics/:subjectId" element={<ManageTopics />} />
                      <Route path="manage-editais" element={<ManageEditais />} />
                      <Route path="manage-folder-courses" element={<ManageFolderCourse />} />
                      <Route path="update-mindmap" element={<SudoUpdateMindmap />} />
                    </Routes>
                  </AdminRoute>
                } />         
            </Routes>
          </UserProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
