import { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import setupAxiosInterceptors from './setupAxiosInterceptors';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  setupAxiosInterceptors(navigate);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
};
