import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Button, Tooltip, IconButton, Divider, Box, TextField, Container, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import useSnackbar from './useSnackbar';

function SendMail() {
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const handleSendMail = async () => {
    setSending(true);

    try {
      await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/support/send-message', { message }, {
        headers: { Authorization: token }
      });

      setTimeout(() => {
        navigate('/app/home');
      }, 3200);

      setMessageInfo('Enviado! Você será respondido em breve.');
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }

    setSending(false);
  };

  return (
      <Container component="main" maxWidth="sm">
        <Card sx={{ 
          marginTop: '15px',
          '@media (min-width:600px)': {
            marginTop: '40px' 
          } 
        }}>
          <CardHeader
            title="Suporte"
            subheader="Problemas? Sugestões? Fale conosco"
          />
          <CardContent>
            <CustomTextField
              variant="outlined"
              autoComplete="off"
              margin="normal"
              style={{ margin: '0' }}
              fullWidth
              id="message"
              label="Mensagem"
              name="message"
              value={message}
              multiline
              rows={8} 
              onChange={(e) => setMessage(e.target.value)}
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={handleSendMail}
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              disabled={sending}
            >
                {sending ? 'Aguarde...' : 'Enviar Mesagem'}
            </Button>
          </CardActions>
        </Card>

        <Button
          onClick={() => navigate('/app/home')}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '8px' }}
        >
          Ir para o Início
        </Button>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />

      </Container>
  );
}

export default SendMail;
