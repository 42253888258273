import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, FormControl, InputLabel, Dialog, DialogContent, DialogActions, Link, Tooltip, IconButton, Divider, Box, TextField, Container, Typography, Card, CardHeader, CardContent, CardActions, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CircularProgress from '@mui/material/CircularProgress';
import useSnackbar from './useSnackbar';
import { useUser } from './UserContext';
import { useParams } from 'react-router-dom';
import { MindMapIcon } from './MainIcons';

function ViewRequest({ requestType }) {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useUser();
  
  const { id: requestId } = useParams(); 
  const [request, setRequest] = useState(null);
  const [fetchingRequest, setFetchingRequest] = useState(false);
  
  const fetchRequest = async () => {
    setFetchingRequest(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${requestId}`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data._id) {
        setRequest(response.data);  

        if (requestType == 'mindmap') { 
          navigate(`/app/edit-smartmap/${response.data._id}`);  
        } else if (requestType == 'summary') { 
          if (response.data.s3Url) {
            window.location.href = response.data.s3Url;
          } else {
            setMessageError('PDF não gerado!');

            setTimeout(() => {
              navigate(`/app/home`);  
            }, 1200);
          }
        } else if (requestType == 'flashcard') {
          if (response.data.s3UrlSecondary) {
            window.location.href = response.data.s3UrlSecondary;
          } else {
            setMessageError('PDF não gerado!');

            setTimeout(() => {
              navigate(`/app/home`);  
            }, 1200);
          }
        }
      } else {
        setMessageError('Erro ao carregar material.');
      }
    } catch (error) {
      setMessageError('Erro ao carregar material.');
    }

    setFetchingRequest(false);
  };

  useEffect(() => {
    fetchRequest();
  }, [requestId]);

  return (
      <>
      <Container component="main" maxWidth="xs" style={{ marginBottom: '30px' }}>

        <div style={{ marginTop: '80px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress style={{ width: '60px', height: '60px', color: '#888888' }} />
        </div>
        
        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />

      </Container>
      </>
  );
}

export default ViewRequest;
