import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PriceComponent from './PriceComponent';
import RowComponent from './RowComponent';
import TransactionStatus from './TransactionStatus';
import { Skeleton, Box, Button, Container, Grid, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import ReadOnlyTextFieldWithCopy from './ReadOnlyTextFieldWithCopy';
import useSnackbar from './useSnackbar';

function Transaction() {
  const prices = {
    10: 9.0,
    25: 20.0,
    50: 35.0,
    100: 60.0
  };

  const { id } = useParams(); 
  const [transaction, setTransaction] = useState(null);
  const [price, setPrice] = useState();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();
  const navigate = useNavigate();

  let timeoutID;

  useEffect(() => {
    if (transaction) {
      setPrice(prices[transaction.amount] || 0);  

      if (transaction.status == 'pending') {
        timeoutID = setTimeout(() => {
           fetchTransaction(); 
        }, 5000);
      }
    } else {
      setPrice(null);
    }

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [transaction]);

  const token = localStorage.getItem('token');

  useEffect(() => {
    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, []);

  const fetchTransaction = async () => {
    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/payment/transaction/${id}`, {
        headers: { Authorization: token }
      });

      setTransaction(response.data.transaction);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Erro ao carregar dados da transação. Por favor, tente novamente.');
      }
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [token]);

  return (
      <Container component="main" maxWidth="xs">
        {
          !transaction ?
          (
            <>
              <Skeleton variant="text" sx={{ fontSize: '3rem', marginBottom: '5px' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }}animation="wave" />
              <Skeleton variant="text" sx={{ fontSize: '1.2rem' }}animation={false} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }}animation="wave" />
            </>
          ) :
          (
            <>
              <Card sx={{ 
                marginTop: '15px',
                '@media (min-width:600px)': {
                  marginTop: '40px' 
                } 
              }}>
                <CardHeader
                  title="Transação de Compra"
                  subheader={`#${transaction.id}`}
                />
                <CardContent>
                  <RowComponent title={`${transaction.amount} crédito${(transaction.amount>0)?'s':''}`} value={format(new Date(transaction.date), 'dd/MM/yyyy HH:mm', { locale: ptBR })} />
                    <div style={{ margin: '5px 0' }}>
                      <PriceComponent price={price}  />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                      <TransactionStatus status={transaction.status} />
                      {
                        (transaction.status == 'failed' && transaction.paymentError) &&
                        <Box style={{ marginTop: '10px', textAlign: 'center' }}>
                          <Typography variant="h6" component="span" style={{ color: '#f47878', fontWeight: '700' }}>
                            {transaction.paymentError}
                          </Typography>
                        </Box>
                      }
                    </div>
                    
                    {
                      (transaction.status == 'pending') &&
                      <>
                        { (transaction.pixQrCodeBase64 && (
                            <>
                              <Typography variant="h6" style={{ fontSize: '12px', textTransform: 'uppercase', marginBottom: '10px', color: '#666666', fontWeight: '700' }}>
                                PIX - Qr Code
                              </Typography>

                              <div style={{ textAlign: 'center' }}>
                                <img style={{width: '160px'}} src={`data:image/jpeg;base64,${transaction.pixQrCodeBase64}`} />
                              </div>
                            </>
                          )
                        ) }

                        { (transaction.pixQrCode && (
                            <div>
                              <Typography variant="h6" style={{ fontSize: '12px', textTransform: 'uppercase', marginTop: '20px', marginBottom: '10px', color: '#666666', fontWeight: '700' }}>
                                PIX - Código de Pagamento
                              </Typography>

                              <ReadOnlyTextFieldWithCopy value={transaction.pixQrCode} onCopied={() => setMessageInfo('Código PIX copiado!')} />
                            </div>
                          )
                        ) }

                      </>
                    }
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => window.open(transaction.paymentUrl, '_blank').focus()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    Ir para o MercadoPago
                  </Button>
                </CardActions>
              </Card>
              
              {
                (transaction.status == 'successful') &&
                <Button
                  onClick={() => navigate('/app/credits')}
                  fullWidth
                  color="info"
                  size="large"
                  style={{ marginTop: '10px' }}
                >
                  Meus créditos
                </Button>
              }
            </>
          )
        }

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />

      </Container>
  );
}

export default Transaction;
