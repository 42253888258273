import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Container, Typography, Grid, Box } from  '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import LogoComponent from './LogoComponent';
import LayoutPublic from './LayoutPublic';
import useSnackbar from './useSnackbar';
import { useUser } from './UserContext';

function Login() {
  const navigate = useNavigate();

  const { fetchUser } = useUser();

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/auth/login', formData);

      if (response.data) {
        if (response.data.emailNotVerified) {
          setMessageError('Você ainda não confirmou o seu email!');

          if (response.data.user) {
            setTimeout(function() {
              navigate(`/email-verification/${response.data.user._id}`);
            }, 1800);
          }
        } else if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          setTimeout(async () => {
            await fetchUser();
          });
          navigate('/');    
        }
      }
      
    } catch (error) {
      setMessageError('Email e/ou senha inválidos.');
    }

    setLoading(false);
  };

  return (
    <LayoutPublic>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Container component="main" maxWidth="xs">
          <LogoComponent notCentered="true" />
          <Typography variant="h3" component="h3" style={{ fontSize: '17px', fontWeight: '700', padding: '0 10px 15px 10px', color: '#5f6e7e' }}>
            A sua plataforma de estudos inteligente
          </Typography>
          <Box style={{ 
              padding: '30px', 
              backgroundColor: '#ffffff', 
              borderRadius: '8px', 
              marginTop: '15px',
              boxShadow: '0 0 0 0.1em #e7e7e7' 
          }}>
            <form onSubmit={handleSubmit}>
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: '20px' }}
                disabled={loading}
              >
                { loading ? 'Entrando...' : 'Entrar' }
              </Button>
              <Button
                onClick={() => navigate('/forgot-password')}
                fullWidth
                color="info"
                size="medium"
                style={{ marginTop: '10px' }}
              >
                Esqueceu a senha?
              </Button>
            </form>
          </Box>

          <Button
            onClick={() => navigate('/register')}
            variant="contained"
            fullWidth
            color="info"
            size="medium"
            style={{ marginTop: '30px' }}
          >
            Criar conta
          </Button>

          <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
          <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
        </Container>

      </Grid>
    </LayoutPublic>
  );
}

export default Login;
