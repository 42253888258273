import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Container, Typography, Grid, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import VerificationCodeInput from './VerificationCodeInput';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import LogoComponent from './LogoComponent';
import useSnackbar from './useSnackbar';

function ResetPassword() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    
    if (password.length < 6) {
      setMessageError('A senha deve ter pelo menos 6 caracteres.');
      return;
    }

    if (password !== confirmPassword) {
      setMessageError('As senhas não coincidem.');
      return;
    }

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/auth/reset-password', {
        userId,
        code,
        newPassword: password,
      });
      setMessageInfo(response.data.message);
      setTimeout(() => navigate('/login'), 2500);
    } catch (error) {
      setMessageError(error.response.data.error);
    }

    setLoading(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Container component="main" maxWidth="xs">
        <LogoComponent />

        <Box style={{ 
            padding: '30px', 
            backgroundColor: '#ffffff', 
            borderRadius: '8px', 
            marginTop: '15px',
            boxShadow: '0 0 0 0.1em #e7e7e7' 
        }}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', marginTop: '30px', marginBottom: '15px', color: 'rgb(164 185 201)', fontWeight: '700' }}>
              Informe o código recebido por email
            </Typography>

            <VerificationCodeInput onChange={setCode} />
            <CustomTextField
              style={{ marginTop: '40px' }}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Nova senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <CustomTextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="confirmPassword"
              label="Confirmar nova senha"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              style={{ marginTop: '30px' }}
              disabled={loading}
            >
              { loading ? 'Aguarde...' : 'Redefinir Senha' }
            </Button>
          </form>
        </Box>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
      </Container>
    </Grid>
  );
}

export default ResetPassword;
