import React from 'react';
import { Link } from 'react-router-dom';

const LogoComponent = ({ notCentered }) => {
  return (
    <Link to="/login" style={{ display: 'block', textAlign: notCentered ? 'left' : 'center', margin: '0 auto', marginBottom: '25px' }}>
      <img src="/app_logo_light.png" alt="Logomarca Estudo Inteligente" style={{ 
      	height: '70px',
      	'@media (min-width:600px)': {
          height: '45px'
      	} 
      }} />
    </Link>
  );
};

export default LogoComponent;
