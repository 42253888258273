import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './components/UserContext';
import Layout from './components/Layout';
import { LayoutProvider } from './components/LayoutContext';

const AdminRoute = ({ children }) => {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <div>Carregando...</div>; // Componente de carregamento
  }

  if (!user || !user.isAdmin) {
    return <Navigate to="/app/home" />;
  }

  return <LayoutProvider><Layout>{children}</Layout></LayoutProvider>;
};

export default AdminRoute;
