import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Grid, Container, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import LogoComponent from './LogoComponent';
import VerificationCodeInput from './VerificationCodeInput';

function EmailVerification() {
  const navigate = useNavigate();
  const {
    setMessageInfo,
    setMessageError,
    clearMessageInfo,
    clearMessageError,
    messageInfo,
    messageError
  } = useSnackbar();
  const [resendingMail, setResendingMail] = useState(false);
  const [validating, setValidating] = useState(false);
  const { userId } = useParams();
  
  const [formData, setFormData] = useState({
    code: ''
  });

  const [code, setCode] = useState('');

  const handleChangeCode = (newCode) => {
    setCode(newCode);
    setFormData((prevState) => ({
      ...prevState,
      code: newCode,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleResendCode = async () => {
    setResendingMail(true);

    try {
      await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/auth/resend-verification', { userId });
      setMessageInfo('Código de verificação reenviado.');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao reenviar código de verificação.');
      }
    }

    setResendingMail(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setValidating(true);

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/auth/verify-email', { userId, ...formData });

      setMessageInfo('Email confirmado. Aproveite a experiência!');

      if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          setTimeout(function() {
            navigate('/');    
          }, 1000);
          
      } else {
        setTimeout(function() {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Código de verificação inválido.');  
      }
    }

    setValidating(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Container component="main" maxWidth="xs">
        <LogoComponent />

        <Box style={{ 
            padding: '30px', 
            backgroundColor: '#ffffff', 
            borderRadius: '8px', 
            marginTop: '15px',
            boxShadow: '0 0 0 0.1em #e7e7e7' 
        }}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" style={{ fontSize: '13px', textAlign: 'center', marginBottom: '15px', color: 'rgb(164 185 201)', fontWeight: '700' }}>
              Informe o código recebido por email
            </Typography>

            <VerificationCodeInput onChange={handleChangeCode} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              style={{ marginTop: '30px' }}
              disabled={validating}
            >
              {validating ? 'Aguarde...' : 'Validar Código'}
            </Button>

             <Typography variant="h6" style={{ fontSize: '12px', textAlign: 'center', marginTop: '15px', marginBottom: '15px', color: '#666666', fontWeight: '700' }}>
              Não recebeu o código?
            </Typography>

            <Button
              onClick={handleResendCode}
              fullWidth
              color="info"
              variant="contained"
              size="medium"
              disabled={resendingMail}
            >
              {resendingMail ? 'Aguarde...' : 'Reenviar Email'}
            </Button>
          </form> 
        </Box>
        
        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
      </Container>
    </Grid>
  );
}

export default EmailVerification;