import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';

function TransactionStatus({ status }) {
  const StatusDescription = {
  	'pending': 'Pagamento pendente',
  	'successful': 'Pagamento realizado',
  	'failed': 'Falha no pagamento'
  };

  const StatusBackgroundColor = {
  	'pending': '#faeb56',
  	'successful': '#6ba164',
  	'failed': '#b04444'
  };

  const StatusTextColor = {
  	'pending': '#746f0a',
  	'successful': '#ffffff',
  	'failed': '#ffffff'
  };

  const StatusIcon = {
  	'pending': <CircularProgress size={26} style={{ color: '#746f0a' }} />,
  	'successful': <CheckCircleOutlineIcon style={{ color: 'white', fontSize: 20 }} />,
  	'failed': <ErrorIcon style={{ color: 'white', fontSize: 26 }} />
  };

  const description = StatusDescription[status] || '...';
  const background = StatusBackgroundColor[status] || '#cccccc';
  const color = StatusTextColor[status] || '#444444';
  const icon = StatusIcon[status];
  
  return (
  	<div style={{ background, color, borderRadius: '5px', padding: '10px 20px' }}>
      <Grid container justifyContent="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          {icon}
          <Typography variant="body1" style={{ fontSize: '13px', fontWeight: '700', marginLeft: '10px' }}>
            {description}
          </Typography>
        </Box>
      </Grid>
    </div>
  );
}

export default TransactionStatus;
