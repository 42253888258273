import React from 'react';
import { Typography, Box } from '@mui/material';

const CreditsDisplay = ({ credits, totalCredits }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ padding: '0' }}>
      <Box display="flex" alignItems="baseline" justifyContent="center">
      {
        (totalCredits > 0) ?
        (
          <>
            <Typography component="span" style={{ fontWeight: '600', fontSize: '50px', color: '#71D0DD' }}>
              {credits}
            </Typography>
            <Typography variant="h6" component="span" marginLeft="0.5rem" style={{ fontSize: '15px', color: '#999999', position: 'relative', top: '-4px', fontWeight: '600' }}>
              {`crédito${credits > 1 ? 's' : ''} de IA`}
            </Typography>
          </>
        ) :
        (
          <Typography variant="h6" component="span" style={{ color: '#666666', fontWeight: '700' }}>
            Você não tem créditos de IA!
          </Typography>
        )
      }
      </Box>
    </Box>
  );
};

export default CreditsDisplay;
