import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Button, TextField, FormControl, Dialog, 
  DialogActions, DialogContent, DialogTitle, List, ListItem, 
  ListItemText, IconButton, ListItemSecondaryAction, Box, Tooltip,
  Skeleton, Link, InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CustomSnackbar from './CustomSnackbar';
import SelectFolder from './SelectFolder';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';

function ManageFolderCourse() {
  const [folderCourses, setFolderCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formNewData, setFormNewData] = useState({
    title: '',
    hormartCourseId: ''
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormNewData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const navigate = useNavigate();

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  useEffect(() => {
    fetchFolderCourses();
  }, []);

  const fetchFolderCourses = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais/folder-courses', {
        headers: { Authorization: token }
      });
      setFolderCourses(response.data || []);
    } catch (error) {
      console.error('Erro ao carregar cursos por pasta', error);
    }
    setLoading(false);
  };

  const handleDelete = (course) => {
    setFolderCourses([...folderCourses].filter(c => !(c.folderId == course.folderId && c.hormartCourseId == course.hormartCourseId && c.title == course.title) ));
  };

  const handleAdd = () => {
    if (!formNewData.title) {
      setMessageError('Informe o título!');
      return;
    }
    if (!formNewData.hormartCourseId) {
      setMessageError('Informe o id do curso no Hotmart!');
      return;
    }
    if (!currentFolderId) {
      setMessageError('Informe a pasta!');
      return;
    }

    setFolderCourses([...folderCourses].concat([{...formNewData, folderId: currentFolderId }]));

    setFormNewData({
      title: '',
      hormartCourseId: ''
    });

    setAddSectionVisible(false);

    setCurrentFolderId(null);
  };

  const handleSubmit = async () => {
    setSaving(true);
    const token = localStorage.getItem('token');

    try {
      await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/editais/folder-courses', folderCourses, {
        headers: { Authorization: token }
      });
      
      fetchFolderCourses();

      setMessageInfo('Cursos por pasta alterados!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar cursos por pasta');
      }
      console.error('Erro ao atualizar cursos por pasta', error);
    }

    setSaving(false);
  };

  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [addSectionVisible, setAddSectionVisible] = useState(false);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pasta.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pasta.');
    }

    setFetchingAllFolders(false);
  };

  useEffect(() => {
    fetchAllFolders();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <div style={{ margin: '30px 0px', display: 'flex', flexDirection: 'row' }}>
        <Link style={{ color: '#445770', fontWeight: '600', fontSize: '18px' }} component="button" variant="body2" onClick={() => navigate('/sudo')}>
          Administração
        </Link>
        <i style={{ color: '#556c8a', fontSize: '24px' }} className="bx bx-chevron-right" />
        <Typography style={{ color: '#556c8a', fontWeight: '600', fontSize: '18px' }}>
          Gerenciar Cursos por Pasta
        </Typography>
      </ div>

      {
        !addSectionVisible &&
        (
          <div>
            <Button variant="contained" color="primary" onClick={() => setAddSectionVisible(true)} startIcon={<AddIcon />} style={{ display: 'flex', marginTop: '8px' }}>
              Novo Curso
            </Button>
          </div>
        )
      }
      {
        addSectionVisible &&
        (
          <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 0 0 0.125em #cccccc', marginBottom: '15px', backgroundColor: '#ffffff', borderRadius: '8px', padding: '15px' }}>
           <FormControl fullWidth margin="normal">
            <TextField 
              fullWidth 
              label="Título" 
              value={formNewData.title} 
              onChange={handleChange}
              name="title"
            />
            </FormControl>

            <FormControl fullWidth margin="normal">
            <TextField 
              fullWidth 
              label="ID Hotmart" 
              value={formNewData.hormartCourseId} 
              onChange={handleChange}
              name="hormartCourseId"
            />
            </FormControl>

            <div style={{ marginTop: '20px' }}>
              <SelectFolder 
                isLoading={fetchingAllFolders}
                folders={folders} 
                currentFolderId={currentFolderId}
                onSelect={setCurrentFolderId}
              />
            </div>

            <Button variant="contained" color="primary" onClick={handleAdd} style={{ display: 'flex', marginTop: '8px' }}>
              Adicionar
            </Button>
          </div>   
        )
      }
      {loading ? (
        <Container component="main" style={{ maxWidth: '800px', marginTop: '40px' }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton style={{ marginTop: '10px' }} variant="rectangular" width="100%" height={40} />
        </Container>
      ) : (
         <>
          <div>
            {!folderCourses || (folderCourses.length == 0) && 
              <div style={{ borderRadius: '8px', marginTop: '20px', background: '#ffffff', padding: '20px', marginBottom: '15px' }}>
                Nenhum curso cadastrado!
              </div>
            }
            {folderCourses.map((course, index) => (
              <div key={course._id} style={{
                      display: 'flex',
                      flexDirection: 'column', // Organiza em coluna para mobile e desktop
                      padding: '10px 20px',
                      backgroundColor: (index % 2 == 0) ? '#ffffff' : '#f4f4f4',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      marginTop: '12px',
                      marginBottom: '8px'
                    }}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box style={{ display: 'flex' }}>
                    <Typography style={{ marginLeft: '10px', flex: 1, fontSize: '0.8em', fontWeight: '600', color: '#666666', padding: '8px', paddingLeft: '0px' }} variant="body1">
                      {course.title}
                    </Typography>
                  </Box>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title="Excluir curso">
                      <IconButton color="gray" edge="end" aria-label="Excluir curso" onClick={() => handleDelete(course)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}> 
            <Button variant="contained" color="info" onClick={handleSubmit} style={{ display: 'flex' }} disabled={saving}>
              { saving ? 'Salvando...' : 'Salvar' }
            </Button>
          </div>
        </>
      )}

      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
    </Container>
  );
}

export default ManageFolderCourse;
