import React from 'react';
import { 
  Typography, Box, Link
} from '@mui/material';
import './HorizontalList.css';

function HorizontalList({ items, onClick }) {
  return (
    <Box className="horizontal-list">
      {items.reverse().map((item, index) => (
        <React.Fragment key={index}>
          {(index > 0) ? (
            <>
              <i className="bx bx-chevron-right" />
              <Link
                component="button"
                variant="body2"
                onClick={() => onClick(items.length - index - 1, item)}
                className="list-item"
              >
                {item}
              </Link>
            </>
          ) : (
            <>
              <i className="bx bxs-square-rounded" style={{ color: '#136880', fontSize: '6px', marginRight: '5px' }} />
              <Typography variant="body2" className="last-item" style={{ fontSize: '1rem', fontWeight: '600', color: '#136880' }}>
                {item}
              </Typography>  
            </>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default HorizontalList;
