import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Container, Typography, Button, TextField, Dialog,
  DialogActions, DialogContent, DialogTitle, List, ListItem,
  ListItemText, IconButton, ListItemSecondaryAction, Tooltip, Box,
  Skeleton, Link, Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';
import '@toast-ui/editor/dist/toastui-editor.css';

import { Editor } from '@toast-ui/react-editor';

function ContentButton({ type, description, getIcon, exists, subject, topic, onClick, onGenerating, setMessageInfo, setMessageError }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      setLoading(true);

      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content`, { type, subject: subject._id, topic: topic._id }, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.studyContent) {
        if (response.data.studyContent.generating) {
          setMessageInfo(`Aguarde! Gerando ${description}...`);
          if (onGenerating) {
            onGenerating();
          }
        } else {
          const { studyContent, requestId, requestContentJson } = response.data;
          onClick({ studyContent, requestId, requestContentJson });
        }
      }
    } catch(error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError(`Erro! ${description}`);
      }
    }

    setLoading(false);
  };

  return (
    loading ?
      <CircularProgress size={22} style={{ color: '#888888', marginLeft: '8px' }} />
      :
      <Tooltip title={description}>
        {
          exists ?
          (
            <IconButton
              onClick={handleClick}
              style={{
                backgroundColor: '#c6e1e5',
                color: '#2e93a1',
                '&:hover': {
                  backgroundColor: '#bcd8dc',
                },
              }}
            >
              {getIcon()}
            </IconButton>
          ) :
          (
            <IconButton color="gray" aria-label={description} onClick={handleClick}>
              {getIcon()}
            </IconButton>
          )
        }
      </Tooltip>
  );
}

function ManageTopics() {
  const { subjectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [topicsById, setTopicsById] = useState({});
  const [subject, setSubject] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteContentDialogOpen, setDeleteContentDialogOpen] = useState(false);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState('');
  const [addChildDialogOpen, setAddChildDialogOpen] = useState(false);
  const [titleAddChild, setTitleAddChild] = useState('');
  const [changingPublished, setChangingPublished] = useState(false);
  const [mindmapStudyContentSelected, setMindmapStudyContentSelected] = useState(null);
  const [mindmapRequestIdSelected, setMindmapRequestIdSelected] = useState(null);
  const [mindmapDialogOpen, setMindmapDialogOpen] = useState(false);
  const [summaryStudyContentSelected, setSummaryStudyContentSelected] = useState(null);
  const [summaryRequestIdSelected, setSummaryRequestIdSelected] = useState(null);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [promptsDialogOpen, setPromptsDialogOpen] = useState(false);
  const [promptsSummaryDialogOpen, setPromptsSummaryDialogOpen] = useState(false);
  const [prompt1, setPrompt1] = useState('');
  const [prompt3, setPrompt3] = useState('');
  const [savingPrompts, setSavingPrompts] = useState(false);

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  useEffect(() => {
    fetchSubjectAndTopics(subjectId);
  }, [subjectId]);

  const navigate = useNavigate();

  function fillTopicsDic(topics, dic) {
    if (topics && topics.length > 0) {
      for (let topic of topics) {
        dic[topic._id] = topic;
        fillTopicsDic(topic.loadedSubTopics, dic);
      }
    }
  }

  function getTopicsDic(topics) {
    let dic = {};
    fillTopicsDic(topics, dic);
    return dic;
  }

  const fetchSubjectAndTopics = async (subjectId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setLoading(true);
    
    try {
      const topicsResponse = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/study-content/subjects/${subjectId}/topics`, {
        headers: { Authorization: token }
      });

      if (topicsResponse.data) {
        if (topicsResponse.data.subject) {
          setSubject(topicsResponse.data.subject);
        }

        if (topicsResponse.data.topics) {
          setTopics(topicsResponse.data.topics);  
          setTopicsById(getTopicsDic(topicsResponse.data.topics));
        }
      }
      
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao carregar dados');
      }

      console.error('Erro ao carregar dados', error);
    }

    setLoading(false); 
  };

  function findParentTopic(topicId) {
    if (topicsById && topicsById[topicId]) {
      return topicsById[topicId];
    }

    return null;  
  }

  function getCompletedDescription(subject, topic) {
    let description = '';

    if (subject && topic) {
      let hierarchyStr = '';

      let hierarchy = [];
      let currentTopic = topic;

      while (currentTopic && currentTopic.parent) {
        const parentTopic = findParentTopic(currentTopic.parent);

        if (parentTopic) {
          hierarchy.push(`"${parentTopic.title}"`);
          currentTopic = parentTopic;
        } else {
          currentTopic = null;
        }
      }

      if (hierarchy.length > 0) {
        hierarchyStr = hierarchy.reverse().join(' > ');
      }

      let descriptionHierachy = '';

      if (hierarchyStr != '') {
        descriptionHierachy = `, relacionado à cadeia de assuntos: ${hierarchyStr}`;
      } 

      description = `assunto principal "${topic.title}" da disciplina "${subject.title}" ${descriptionHierachy}`;
    }

    return description;
  }

  const getPrompt1 = (subject, topic) => {
    const content = `Crie um mapa inteligente (seguindo a especificação indicada, espero apenas o JSON no formato correto) sobre o ${getCompletedDescription(subject, topic)}.`;

    return content;
  };


const getPrompt3 = (subject, topic) => {
    const content = `Em português (brasileiro), crie um resumo com no máximo 4000 palavras sobre o ${getCompletedDescription(subject, topic)}. O resumo deve estar no formato markdown (com o máximo de informações e formatações possíveis, tabelas, negrito, títulos, listas), ao final do resumo adicione uma seção contendo 10 perguntas e respostas. Substituir toda formatação de lista numerada (número + '.', ex: '1. Etapa A', '2. Etapa B') por (número + ')'), ex: '1) Etapa A', '2) Etapa B'. Caso precise escrever alguma fórmula matemática, escreva-a no formato AsciiMath com o conteúdo entre "!!" (exemplo: !!texto exemplo!!). Na resposta deste prompt eu espero receber apenas (única e exclusivamente) um json "{ summary }", onde "summary" é o resumo em markdown, sem nenhuma palavra a mais.`;
    
    return content;
  };

  const handleSaveFromPromptsResult = async () => {
    setSavingPrompts(true);

    try {
      const input = { prompt1, subject: subject._id, topic: currentTopic._id };

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/mindmap-from-prompts`, input, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Mapa inteligente criado!');
      }

      fetchSubjectAndTopics(subject._id);

      setPrompt1('');
      setPromptsDialogOpen(false);
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao criar mapa inteligente. Por favor, tente novamente.');
      }
    }


    setSavingPrompts(false);
  };


  const handleSaveFromPromptsSummaryResult = async () => {
    setSavingPrompts(true);

    try {
      const input = { prompt3, subject: subject._id, topic: currentTopic._id };

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/summary-from-prompts`, input, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Resumo criado!');
      }

      fetchSubjectAndTopics(subject._id);

      setPrompt3('');
      setPromptsSummaryDialogOpen(false);
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao criar mapa inteligente. Por favor, tente novamente.');
      }
    }


    setSavingPrompts(false);
  };

  const handleAddTopic = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/topics`, { subject: subjectId, title: newTitle }, {
        headers: { Authorization: token }
      });
      setNewTitle('');
      fetchSubjectAndTopics(subjectId);

      setMessageInfo('Assunto adicionado!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao adicionar assunto');
      }

      console.error('Erro ao adicionar tópico', error);
    }
  };

  const handleEdit = (topic) => {
    setCurrentTopic(topic);
    setEditingTitle(topic.title);
    setEditDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.put(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/topics/${currentTopic._id}`, { title: editingTitle }, {
        headers: { Authorization: token }
      });
      fetchSubjectAndTopics(subjectId);
      setEditDialogOpen(false);

      setMessageInfo('Assunto editado!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar assunto');
      }

      console.error('Erro ao editar tópico', error);
    }
  };

  const handleAddChild = (topic) => {
    setCurrentTopic(topic);
    setTitleAddChild('');
    setAddChildDialogOpen(true);
  };

  const handleAddChildSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/topics`, { title: titleAddChild, subject: subjectId, parentTopic: currentTopic  }, {
        headers: { Authorization: token }
      });
      fetchSubjectAndTopics(subjectId);
      setAddChildDialogOpen(false);

      setMessageInfo('Subassunto adicionado!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao adicionar subassunto');
      }

      console.error('Erro ao editar tópico', error);
    }
  };

  const handleDelete = (topic) => {
    setCurrentTopic(topic);
    setDeleteDialogOpen(true);
  };

  const handleDeleteSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.delete(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/topics/${currentTopic._id}`, {
        headers: { Authorization: token }
      });
      fetchSubjectAndTopics(subjectId);
      setDeleteDialogOpen(false);

      setMessageInfo('Assunto excluído!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao excluir assunto');
      }

      console.error('Erro ao deletar tópico', error);
    }
  };

  const handleDeleteContent = (topic) => {
    setCurrentTopic(topic);
    setDeleteContentDialogOpen(true);
  };

  const handleDeleteContentSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.delete(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/topics/${currentTopic._id}/related-content`, {
        headers: { Authorization: token }
      });
      fetchSubjectAndTopics(subjectId);
      setDeleteContentDialogOpen(false);

      setMessageInfo('Materiais excluídos!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao excluir materiais');
      }

      console.error('Erro ao deletar materiais', error);
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleEditMindmap = (requestId) => {
    openInNewTab(`/app/edit-smartmap/${requestId}`);
  };

  const handleEditSummary = (studyContent, requestId) => {
    setMessageInfo(`Abrir edição do studyContent: ${studyContent._id}`);
  };

  const handleEditFlashcard = (studyContent, requestId) => {
    setMessageInfo(`Abrir edição do studyContent: ${studyContent._id}`);
  };

  const handleMindmapPublish = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setChangingPublished(true);

    try {
      const newPublishedValue = !(mindmapStudyContentSelected && mindmapStudyContentSelected.published);

      if (mindmapStudyContentSelected) {
        const resp = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/${mindmapStudyContentSelected._id}/change-published`, { newValue: newPublishedValue }, {
          headers: { Authorization: token }
        });

        if (resp && resp.data && resp.data.studyContent) {
          setMindmapStudyContentSelected(resp.data.studyContent);
        }

        fetchSubjectAndTopics(subjectId);
      }
    } catch(error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar publicado');
      }

      console.error('Erro ao alterar publicado ', error);
    }

    setChangingPublished(false);
  };

  const handleSummaryPublish = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setChangingPublished(true);

    try {
      const newPublishedValue = !(summaryStudyContentSelected && summaryStudyContentSelected.published);

      if (summaryStudyContentSelected) {
        const resp = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/${summaryStudyContentSelected._id}/change-published`, { newValue: newPublishedValue }, {
          headers: { Authorization: token }
        });

        if (resp && resp.data && resp.data.studyContent) {
          setSummaryStudyContentSelected(resp.data.studyContent);
        }

        fetchSubjectAndTopics(subjectId);
      }
    } catch(error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar publicado');
      }

      console.error('Erro ao alterar publicado ', error);
    }

    setChangingPublished(false);
  };
  
  const openMindmapDialog = ({ studyContent, requestId }) => {
    setMindmapStudyContentSelected(studyContent);
    setMindmapRequestIdSelected(requestId);
    setMindmapDialogOpen(true);
  };

  const openSummaryDialog = ({ studyContent, requestId, requestContentJson }) => {
    try {
      const obj = JSON.parse(requestContentJson);

      if (obj && obj.summary) {
        setSummaryEditingRequestId(requestId);
        setSummary(obj.summary);
      }
    } catch(err) { }

    setSummaryStudyContentSelected(studyContent);
    setSummaryRequestIdSelected(requestId);
    setSummaryDialogOpen(true);
  };

  const openPromptsDialog = (topic) => {
    setPromptsDialogOpen(true);
    setCurrentTopic(topic);
    setPrompt1(getPrompt1(subject, topic));
  };

  const openPromptsSummaryDialog = (topic) => {
    setPromptsSummaryDialogOpen(true);
    setCurrentTopic(topic);
    setPrompt3(getPrompt3(subject, topic));
  };

  useEffect(() => {
    if (!promptsDialogOpen && currentTopic) {
      setCurrentTopic(null);
    } 
  }, [promptsDialogOpen]);

  useEffect(() => {
    if (!promptsSummaryDialogOpen && currentTopic) {
      setCurrentTopic(null);
    } 
  }, [promptsSummaryDialogOpen]);

  const editorRef = useRef();
  const [summary, setSummary] = useState('');
  const [summaryEditingRequestId, setSummaryEditingRequestId] = useState(null);
  const [editingSummary, setEditingSummary] = useState(false);
  
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().changeMode('wysiwyg', true);  
    }
  }, [openSummaryDialog]);

  const handleChangeEditSummary = () => {
    const editorInstance = editorRef.current.getInstance();
    setSummary(editorInstance.getMarkdown());
  };

  const toolbarItems = [
    ['heading', 'bold', 'italic', 'hr'],
    ['ul', 'ol'],
    ['table']
  ];

  const handleSaveEditSummary = async () => {
    setEditingSummary(true);

    try {
      const input = { summary };

      const token = localStorage.getItem('token');
      const response = await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests/${summaryEditingRequestId}/update-summary-data`, input, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.message) {
        setMessageInfo(response.data.message);
      } else {
        setMessageInfo('Resumo alterado!');
      }
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar resumo. Por favor, tente novamente.');
      }
    }

    setEditingSummary(false);
  };

  const renderTopicsWithSubTopics = (topics, level = 0) => {
  return topics.map((topic, index) => (
    <React.Fragment key={topic._id}>
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 20px',
          marginLeft: `${30 * level}px`, // Adiciona margem à esquerda com base no nível
          marginRight: `${-30 * level}px`, // Compensa a margem à esquerda para manter a largura
          backgroundColor: (index % 2 === 0) ? '#ffffff' : '#f4f4f4',
          borderRadius: '8px',
          marginBottom: '8px',
          justifyContent: 'space-between',
          width: `calc(100% - ${30 * level}px)` // Ajusta a largura para não extrapolar o pai
        }}>
        
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex' }}>
            <Typography style={{ marginLeft: '10px', flex: 1, fontSize: '0.8em', fontWeight: '600', color: ((topic.count > 0) ? '#2428da' : '#666666') }} variant="body1">{topic.title}{(topic.count > 0) ? ` (${topic.count})` : ""}</Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title="Prompts Mapa Inteligente">
              <IconButton color="gray" aria-label="Prompts Mapa Inteligente" onClick={e => openPromptsDialog(topic)}>
                <i style={{ fontSize: '22px' }} className="bx bx-terminal" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Prompts Resumo">
              <IconButton color="blue" aria-label="Prompts Resumo" onClick={e => openPromptsSummaryDialog(topic)}>
                <i style={{ fontSize: '22px' }} className="bx bxs-terminal" />
              </IconButton>
            </Tooltip>

            <ContentButton
              type="mindmap"
              onClick={openMindmapDialog}
              onGenerating={() => { fetchSubjectAndTopics(subjectId); }}
              topic={topic}
              subject={subject}
              description="Mapa inteligente"
              getIcon={() => <i  style={{ fontSize: '22px' }} className="bx bx-sitemap" />}
              exists={!!topic.mindmapStudyContent}
              setMessageInfo={setMessageInfo}
              setMessageError={setMessageError}
              />

            <ContentButton
              type="summary"
              onClick={openSummaryDialog}
              onGenerating={() => { fetchSubjectAndTopics(subjectId); }}
              topic={topic}
              subject={subject}
              description="Resumo"
              getIcon={() => <i  style={{ fontSize: '22px' }} className="bx bx-file" />}
              exists={!!topic.summaryStudyContent}
              setMessageInfo={setMessageInfo}
              setMessageError={setMessageError}
              />

              {
                /*
                <ContentButton
                  type="flashcard"
                  onClick={handleEditFlashcard}
                  topic={topic}
                  subject={subject}
                  description="Flashcard"
                  getIcon={() => <i  style={{ fontSize: '22px' }} className="bx bx-credit-card-front" />}
                  exists={!!topic.flashcardStudyContent}
                  setMessageInfo={setMessageInfo}
                  setMessageError={setMessageError}
                  />
                */
              }
            <Tooltip title="Adicionar filho">
              <IconButton color="gray" aria-label="Adicionar filho" onClick={() => handleAddChild(topic)}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar assunto">
              <IconButton color="gray" aria-label="Editar assunto" onClick={() => handleEdit(topic)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir assunto">
              <IconButton color="gray" aria-label="Excluir assunto" onClick={() => handleDelete(topic)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Excluir materiais do assunto">
              <IconButton color="gray" aria-label="Excluir materiais do assunto" onClick={() => handleDeleteContent(topic)}>
                <i className="bx bx-reset" style={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      </div>
      {topic.loadedSubTopics && topic.loadedSubTopics.length > 0 &&
        renderTopicsWithSubTopics(topic.loadedSubTopics, level + 1) // Chamada recursiva para renderizar sub-tópicos
      }
    </React.Fragment>
  ));
};

  return (
    <Container component="main" maxWidth="md">
      <div style={{ margin: '30px 0px 5px', display: 'flex', flexDirection: 'row' }}>
        <Link style={{ color: '#445770', fontWeight: '600', fontSize: '18px' }} component="button" variant="body2" onClick={() => navigate('/sudo')}>
          Administração
        </Link>
        <i style={{ color: '#556c8a', fontSize: '24px' }} className="bx bx-chevron-right" />
        <Link style={{ color: '#445770', fontWeight: '600', fontSize: '18px' }} component="button" variant="body2" onClick={() => navigate('/sudo/manage-subjects')}>
          Gerenciar Matérias
        </Link>
        <i style={{ color: '#556c8a', fontSize: '24px' }} className="bx bx-chevron-right" />
        <Typography style={{ color: '#556c8a', fontWeight: '600', fontSize: '18px' }}>
          Gerenciar Assuntos
        </Typography>
      </ div>
      <Typography style={{ marginBottom: '20px', color: '#666666', fontWeight: '600', fontSize: '14px' }}>
        {subject?.title}
      </Typography>

      <div style={{ display: 'flex', boxShadow: '0 0 0 0.125em #cccccc', marginBottom: '15px', backgroundColor: '#ffffff', borderRadius: '8px', padding: '15px' }}>
        <TextField 
          label="Novo Assunto" 
          fullWidth
          value={newTitle} 
          onChange={(e) => setNewTitle(e.target.value)} 
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleAddTopic} startIcon={<AddIcon />} style={{ display: 'flex' }}>
          Adicionar
        </Button>
      </div>

      {loading ? (
        <Container component="main" style={{ maxWidth: '800px', marginTop: '40px' }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton style={{ marginTop: '10px' }} variant="rectangular" width="100%" height={40} />
        </Container>
      ) : (
        <div>
          {renderTopicsWithSubTopics(topics)}
        </div>
      )
      }

      <Dialog open={promptsDialogOpen} onClose={() => setPromptsDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Mapa Inteligente | Prompts</DialogTitle>
      
        <DialogContent style={{ paddingTop: '10px' }}>
          <Link href="https://chat.openai.com/g/g-KcJq6VI6P-gerador-de-mapa-inteligente-com-compartilhamento" target="_blank" rel="noopener noreferrer">
            <Typography component="span" style={{ fontSize: '0.9rem' }} variant="body1">
              Link para a GPT
            </Typography>
          </Link>

          <TextField
            fullWidth
            label="Resultado do Prompt 1 (mapa inteligente)"
            value={prompt1}
            multiline
            rows={4} 
            onChange={(e) => setPrompt1(e.target.value)}
            style={{ marginBottom: '12px', marginTop: '15px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPromptsDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSaveFromPromptsResult} disabled={savingPrompts} size="medium" color="info" variant="contained">
            { savingPrompts ? 'Salvando ...' : 'Salvar' }
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={promptsSummaryDialogOpen} onClose={() => setPromptsSummaryDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Resumo | Prompts</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            fullWidth
            label="Resultado do Prompt 3 (resumo)"
            value={prompt3}
            multiline
            rows={6} 
            onChange={(e) => setPrompt3(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSummaryPromptsDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSaveFromPromptsSummaryResult} disabled={savingPrompts} size="medium" color="info" variant="contained">
            { savingPrompts ? 'Salvando ...' : 'Salvar' }
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addChildDialogOpen} onClose={() => setAddChildDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Adicionar Assunto</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          {
            currentTopic && (
              <div style={{ marginBottom: '10px' }}>
                <strong>Assunto pai: </strong> {currentTopic.title}
              </div>
            )
          }
          <TextField
            fullWidth
            label="Título do Assunto"
            value={titleAddChild}
            onChange={(e) => setTitleAddChild(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddChildDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleAddChildSubmit} size="medium" color="info" variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={summaryDialogOpen} onClose={() => setSummaryDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogContent style={{ padding: '30px' }}>
          <div style={{ marginBottom: '20px' }}>
            <strong>Resumo: </strong> {summaryStudyContentSelected && summaryStudyContentSelected.title}
          </div>
          <div style={{ marginBottom: '20px' }}>
            {
              changingPublished ?
              (
                `Salvando...`
              ) :
              (
                <>
                  <Switch
                    checked={summaryStudyContentSelected && summaryStudyContentSelected.published}
                    onChange={handleSummaryPublish}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {(summaryStudyContentSelected && summaryStudyContentSelected.published) ? 'Publicado' : 'NÃO publicado'}
                </>
              )
            }
          </div>
          <div>
            <Editor
              ref={editorRef}
              initialValue={summary}
              previewStyle="vertical"
              height="400px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              onChange={handleChangeEditSummary}
              toolbarItems={toolbarItems}
            />
            <Button style={{ marginTop: '10px' }} size="medium" color="info" variant="contained" onClick={handleSaveEditSummary} disabled={editingSummary}>{ editingSummary ? 'Aguarde...' : 'Salvar resumo' }</Button>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={() => setSummaryDialogOpen(false)} size="medium" color="info" variant="outlined">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={mindmapDialogOpen} onClose={() => setMindmapDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogContent style={{ padding: '30px' }}>
          <div style={{ marginBottom: '20px' }}>
            <strong>Mapa inteligente: </strong> {mindmapStudyContentSelected && mindmapStudyContentSelected.title}
          </div>
          <div style={{ marginBottom: '20px' }}>
            {
              changingPublished ?
              (
                `Salvando...`
              ) :
              (
                <>
                  <Switch
                    checked={mindmapStudyContentSelected && mindmapStudyContentSelected.published}
                    onChange={handleMindmapPublish}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {(mindmapStudyContentSelected && mindmapStudyContentSelected.published) ? 'Publicado' : 'NÃO publicado'}
                </>
              )
            }
          </div>
          <Button onClick={() => handleEditMindmap(mindmapRequestIdSelected)} size="medium" color="info" variant="contained">
            Editar mapa inteligente
          </Button>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={() => setMindmapDialogOpen(false)} size="medium" color="info" variant="outlined">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Editar Assunto</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            fullWidth
            label="Título do Assunto"
            value={editingTitle}
            onChange={(e) => setEditingTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleEditSubmit} size="medium" color="info" variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir o assunto "{currentTopic?.title}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleDeleteSubmit} color="primary" size="medium" color="info" variant="contained">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteContentDialogOpen} onClose={() => setDeleteContentDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Confirmar Exclusão dos materiais</DialogTitle>
        <DialogContent>
          Tem certeza que deseja excluir os materiais do assunto "{currentTopic?.title}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteContentDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleDeleteContentSubmit} color="primary" size="medium" color="info" variant="contained">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
    </Container>
  );
}

export default ManageTopics;
