import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Button, TextField, Dialog, 
  DialogActions, DialogContent, DialogTitle, List, ListItem, 
  ListItemText, IconButton, ListItemSecondaryAction, Box, Tooltip,
  Skeleton, Link
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';

function ManageSubjects() {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentSubject, setCurrentSubject] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState('');

  const navigate = useNavigate();

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleManageTopics = (subjectId) => {
    navigate(`/sudo/manage-topics/${subjectId}`);
  };

  const fetchSubjects = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/study-content/subjects', {
        headers: { Authorization: token }
      });
      setSubjects(response.data);
    } catch (error) {
      console.error('Erro ao carregar matérias', error);
    }
    setLoading(false);
  };

  const handleEdit = (subject) => {
    setCurrentSubject(subject);
    setEditingTitle(subject.title);
    setEditDialogOpen(true);
  };

  const handleDelete = (subject) => {
    setCurrentSubject(subject);
    setDeleteDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.put(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/subjects/${currentSubject._id}`, { title: editingTitle }, {
        headers: { Authorization: token }
      });
      fetchSubjects();

      setMessageInfo('Matéria editada!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao alterar matéria');
      }
      console.error('Erro ao atualizar matéria', error);
    }
    setEditDialogOpen(false);
  };

  const handleDeleteSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.delete(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/subjects/${currentSubject._id}`, {
        headers: { Authorization: token }
      });
      fetchSubjects();

      setMessageInfo('Matéria excluída!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao excluir matéria');
      }
      console.error('Erro ao deletar matéria', error);
    }
    setDeleteDialogOpen(false);
  };

  const handleAddSubject = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/admin/study-content/subjects', { title: newTitle }, {
        headers: { Authorization: token }
      });
      setNewTitle('');
      fetchSubjects();

      setMessageInfo('Matéria adicionada!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Erro ao adicionar matéria');
      }
      console.error('Erro ao adicionar matéria', error);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <div style={{ margin: '30px 0px', display: 'flex', flexDirection: 'row' }}>
        <Link style={{ color: '#445770', fontWeight: '600', fontSize: '18px' }} component="button" variant="body2" onClick={() => navigate('/sudo')}>
          Administração
        </Link>
        <i style={{ color: '#556c8a', fontSize: '24px' }} className="bx bx-chevron-right" />
        <Typography style={{ color: '#556c8a', fontWeight: '600', fontSize: '18px' }}>
          Gerenciar Matérias
        </Typography>
      </ div>

      <div style={{ display: 'flex', boxShadow: '0 0 0 0.125em #cccccc', marginBottom: '15px', backgroundColor: '#ffffff', borderRadius: '8px', padding: '15px' }}>
        <TextField 
          label="Nova Matéria" 
          fullWidth
          value={newTitle} 
          onChange={(e) => setNewTitle(e.target.value)} 
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleAddSubject} startIcon={<AddIcon />} style={{ display: 'flex' }}>
          Adicionar
        </Button>
      </div>
      {loading ? (
        <Container component="main" style={{ maxWidth: '800px', marginTop: '40px' }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton style={{ marginTop: '10px' }} variant="rectangular" width="100%" height={40} />
        </Container>
      ) : (

        <div>
          {subjects.map((subject, index) => (
            <div key={subject._id} style={{
                    display: 'flex',
                    flexDirection: 'column', // Organiza em coluna para mobile e desktop
                    padding: '10px 20px',
                    backgroundColor: (index % 2 == 0) ? '#ffffff' : '#f4f4f4',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    marginBottom: '8px'
                  }}>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex' }}>
                  <Link component="button" variant="body2" onClick={() => handleManageTopics(subject._id)} style={{ marginLeft: '10px', flex: 1, fontSize: '0.8em', fontWeight: '600', color: ((subject.count > 0) ? '#2428da' : '#666666'), padding: '8px', paddingLeft: '0px' }} variant="body1">{subject.title} {(subject.count > 0) ? ` (${subject.count})` : ""}</Link>
                </Box>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title="Editar matéria">
                    <IconButton color="gray" edge="end" aria-label="Editar matéria" onClick={() => handleEdit(subject)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Gerenciar Assuntos">
                    <IconButton
                      color="gray"
                      edge="end"
                      onClick={() => handleManageTopics(subject._id)}
                      aria-label="Gerenciar Assuntos"
                    >
                      <ListAltIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Excluir matéria">
                    <IconButton color="gray" edge="end" aria-label="Excluir matéria" onClick={() => handleDelete(subject)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
            </div>
          ))}
        </div>
      )}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Editar Matéria</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField 
            fullWidth 
            label="Título da Matéria" 
            value={editingTitle} 
            onChange={(e) => setEditingTitle(e.target.value)} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleEditSubmit} size="medium" color="info" variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
            style: { width: '600px' },
          }}>
        <DialogTitle style={{ fontWeight: '600' }}>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <p>Você tem certeza que deseja excluir a matéria "{currentSubject?.title}"?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} size="medium" color="info" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleDeleteSubmit} color="primary" size="medium" color="info" variant="contained">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
    </Container>
  );
}

export default ManageSubjects;
