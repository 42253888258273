import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Button, TextField, Dialog, 
  DialogActions, DialogContent, DialogTitle, List, ListItem, 
  ListItemText, IconButton, ListItemSecondaryAction, Box, Tooltip,
  Skeleton, Link, Badge, useTheme, useMediaQuery
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CustomSnackbar from './CustomSnackbar';
import RequestChip from './RequestChip';
import HorizontalList from './HorizontalList';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, SendIcon, ArchiveIcon } from './MainIcons';
import SelectFolder from './SelectFolder';
import CircularProgress from '@mui/material/CircularProgress';

function ContentRepository() {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([{ subjectId: null, topicId: null, title: 'Início' }]);
  const [input, setInput] = useState({ subjectId: null, topicId: null });
  const [data, setData] = useState(null);
  const [contentSelected, setContentSelected] = useState(null);
  const [contentDialogOpen, setContentDialogOpen] = useState(false);
  const [importing, setImporting] = useState(false);
  
  const navigate = useNavigate();

  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [selectFolderVisible, setSelectFolderVisible] = useState(false);
  const [folder, setFolder] = useState(null);
  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    if (!folder) {
      setFolder((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pasta.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pasta.');
    }

    setFetchingAllFolders(false);
  };

  useEffect(() => {
    fetchStudyContent();
  }, [input]);

  const fetchStudyContent = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/study-content/search', input, {
        headers: { Authorization: token }
      });
      
      setData(response.data);
    } catch (error) {
      setMessageError('Falha no carregamento dos dados');
      console.error('Falha no carregamento dos dados', error);
    }
    
    setLoading(false);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const loadAndOpenPdfInNewTab = async (itemId) => {
    const token = localStorage.getItem('token');
    const url = `https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/pdf?type=study-content&id=${itemId}&token=${token}`;
    const resp = await axios.get(url);

    if (resp.data.pdf) {  
      const binaryString = window.atob(resp.data.pdf);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(blob);
      openInNewTab(pdfUrl);
    } else if (resp.data.redirectUrl) {
      openInNewTab(resp.data.redirectUrl);
    }
  };

  const [openingPdf, setOpeningPdf] = useState(false);

  const handleOpenPdf = async (item) => {
    setOpeningPdf(true);

    try {
      await loadAndOpenPdfInNewTab(item.id);
    } catch (error) {
      console.log(error);

      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Não foi possível abrir o PDF.');
      }
    }

    setOpeningPdf(false);
  };

  const handleItemClick = (item) => {
    if (item && item.isFile) {
      setTimeout(() => fetchAllFolders(), 30);
      setContentSelected(item);
      setContentDialogOpen(true);
      return;
    }

    let _input = null;

    if (item.type == 'subject') {
      _input = { subjectId: item.id, topicId: null, title: item.title };
    } else if (item.type == 'topic') {
      _input = { subjectId: null, topicId: item.id, title: item.title };
    }

    if (_input) {
      setInput(_input);

      const newHistory = [].concat(history);
      newHistory.push(_input);
      setHistory(newHistory);
    }
  };

  const getIconClass = (type) => {
    switch (type) {
      case 'mindmap': return 'sitemap';
      case 'flashcard': return 'credit-card-front';
      case 'summary': return 'file';
      default: return 'file-blank';
    }
  }

  const getTypeDescription = (type) => {
    switch (type) {
      case 'mindmap': return 'Mapa inteligente';
      case 'flashcard': return 'Flashcard';
      case 'summary': return 'Resumo';
      default: return '...';
    }
  }

  const handleBack = () => {
    const newHistory = [].concat(history);
    newHistory.pop();
    setHistory(newHistory);
    setInput(newHistory[newHistory.length-1]);
  };

  const handleClickHistoryItem = (index) => {
    const newHistory = [].concat(history.slice(0, index+1));
    setHistory(newHistory);
    setInput(newHistory[newHistory.length-1]);
  };

  const handleImportContent = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    setImporting(true);

    try {
      await axios.post(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/study-content/${contentSelected.id}/import`, { folder }, {
        headers: { Authorization: token }
      });

      setMessageInfo('Material importado!');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
        
        if (error.response.data.limitReached) {
          console.log('>>> limite atingido! sugerir assinatura de plano!');
        }
      } else {
        setMessageError('Erro ao importar material');
      }

      console.error('Erro ao importar material', error);
    }

    setContentDialogOpen(false);
    setSelectFolderVisible(false);
    setImporting(false); 
  };

  const getColorFromType = (type) => {
    switch (type) {
      case 'mindmap':
        return '#6937ea';
      case 'summary':
        return '#b1900e';
      case 'flashcard':
        return '#499c6a';
      default:
        return '#666666';
    }
  };

  const importConfirmationStyle = isMobile ? 
  {
    display: 'flex',
    flexDirection: 'column',
  } : 
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const importConfirmationButtonStyle = isMobile ? 
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '10px',
  } : 
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: isMobile ? 'space-between' : 'flex-end'
  };

  const buttonStyle = {
    width: isMobile ? '100%' : 'auto',
    marginTop: isMobile ? '8px' : '0'
  };

  const handleCloseContentDialog = () => {
    setContentDialogOpen(false);
    setSelectFolderVisible(false);
  };

  return (
    <Container component="main" maxWidth="md">
      <div style={{ display: 'flex', color: '#556c8a', justifyContent: 'space-between', alignItems: 'center', margin: '30px 0 5px 0' }}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <ArchiveIcon size="30" color="#556c8a" />
          <Link 
            component="button" 
            variant="body2" 
            sx={{ 
              color: '#556c8a', 
              fontWeight: '600', 
              fontSize: '18px', 
              padding: '10px', 
              paddingLeft: '12px',
              textDecoration: 'none',
              '&:hover': {
                  color: '#334966',
                  textDecoration: 'underline'
                }
            }}
            onClick={() => handleClickHistoryItem(0)}
          > 
            Acervo Completo
          </Link>
        </div>

        {
          history && history.length > 1 &&
          (
            <Button 
                variant="outlined" 
                color="primary" 
                size="small"
                onClick={handleBack}
            >
              <ArrowLeftIcon color="#30445a" style={{ marginRight: '5px' }} /> Voltar
            </Button>
          )
        }
      </div>

      {loading ? (
        <Container component="main" maxWidth="md" style={{ marginTop: '40px' }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton style={{ marginTop: '10px' }} variant="rectangular" width="100%" height={40} />
        </Container>
      ) : (
        <div>

        <HorizontalList items={history.map(i => i.title)} onClick={handleClickHistoryItem} />

        <div style={{ marginTop: '20px' }}>
          {data && data.items && data.items.map((item, index) => (
            <Button 
              key={item.id} 
              onClick={() => handleItemClick(item)}
              fullWidth
              sx={{
                position: 'relative',
                flex: '1 0 48%',
                padding: '15px',
                marginBottom: '8px',
                backgroundColor: (index % 2 === 0) ? '#ffffff' : '#f4f4f4',
                borderRadius: '8px',
                justifyContent: 'flex-start',
                textAlign: 'left',
                '&:hover': {
                  backgroundColor: (index % 2 === 0) ? '#ffffff' : '#f4f4f4',
                  boxShadow: '0 0 0 0.06em #c7c7c7'
                },
                '&::before': {
                  content: '""', 
                  position: 'absolute',
                  left: '-3px',
                  top: '9px',
                  bottom: '9px',
                  width: '6px',
                  backgroundColor: getColorFromType(item.type),
                  borderRadius: '3px'
                }
              }}
            >
              <RequestChip type={item.type} />
              <Typography style={{ marginLeft: '10px', flex: 1, fontSize: '0.8em', fontWeight: '600', color: '#666666' }} variant="body1">{item.title}</Typography>
            </Button>
          ))}
        </div>

        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px',  marginTop: '20px' }}>
        {data && data.groups && data.groups.map((item, index) => (
          <Button 
            key={item.id} 
            onClick={() => handleItemClick(item)}
            sx={{
              textAlign: 'left',
              flex: isMobile ? '1 0 100%' : '1 0 48%', // Alteração aqui
              padding: '15px',
              backgroundColor: (isMobile ? ((index % 2 === 0)? '#ffffff' : '#f4f4f4') : ((index % 4 === 0 || index % 4 === 3) ? '#ffffff' : '#f4f4f4')),
              borderRadius: '8px',
              justifyContent: 'flex-start',
              '&:hover': {
                backgroundColor: (isMobile ? ((index % 2 === 0)? '#ffffff' : '#f4f4f4') : ((index % 4 === 0 || index % 4 === 3) ? '#ffffff' : '#f4f4f4')),
                boxShadow: '0 0 0 0.06em #9aa2ad'
              }
            }}
          >
            <Badge badgeContent={item.count} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'rgb(149, 164, 184, 0.4)',
                  fontSize: '0.7em',         
                  height: '16px',
                  minWidth: '16px',
                  fontWeight: 600
                },
              }}
            >
              <i className="bx bx-folder" style={{ fontSize: '20px' }} />
            </Badge>
            <Typography style={{ marginLeft: '16px', fontSize: '1em', fontWeight: '500', color: '#444444' }}>
              {item.title}
            </Typography>
          </Button>
        ))}
        {!isMobile && data && data.groups && data.groups.length % 2 !== 0 && (
          <div style={{ flex: '1 0 48%', padding: '15px', opacity: 0 }}>
            {/* Elemento fantasma invisível */}
          </div>
        )}
      </Box>

        </div>
      )}

      <Dialog open={contentDialogOpen} onClose={handleCloseContentDialog}
        PaperProps={{
            style: { width: '550px' },
          }}>
        <DialogContent style={{ padding: '30px', paddingTop: '0' }}>
          <div style={{ display: 'flex', padding: '10px 0', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ color: '#556c8a', fontWeight: '600', fontSize: '15px' }}>
              {`Importar${(contentSelected && contentSelected.type) ? (' ' + getTypeDescription(contentSelected.type).toLowerCase()) : ''}`} 
            </Typography>
            <IconButton onClick={handleCloseContentDialog} size="medium" color="info">
              <i className="bx bx-x" />
            </IconButton>
          </div>

          {
            contentSelected && (
              <Typography style={{ fontSize: '0.9em', fontWeight: '500', color: '#444444', marginBottom: '20px' }} variant="body1">
                {contentSelected.title} 
              </Typography>
            )
          }
          
          {
            selectFolderVisible ?
            (
              <Box style={importConfirmationStyle}>
                <div style={{ display: 'flex', flexGrow: 1, paddingRight: (isMobile ? '0' : '6px') }}>
                <SelectFolder 
                  colorBgLabel="#f6f6f6"
                  label="Selecione a pasta para importar"
                  isLoading={fetchingAllFolders}
                  folders={folders} 
                  currentFolderId={folder}
                  onSelect={setFolder}
                />
                </div>
                <div style={importConfirmationButtonStyle}>
                  <Button fullWidth onClick={handleImportContent} disabled={importing} size="large" color="primary" variant="contained">
                    {
                      importing && <CircularProgress size={14} style={{ color: '#888888', marginRight: '6px' }} />
                    }
                    { importing ? 'Aguarde...' : 'Importar' }
                  </Button>
                </div>

              </Box>
            ) :
            (
              <div style={buttonContainerStyle}>
                  <Button onClick={() => handleOpenPdf(contentSelected)} size="medium" color="info" variant="contained" style={buttonStyle} disabled={openingPdf}>
                    {(openingPdf ? 'Carregando...' : 'Ver PDF')}
                  </Button>
                  <Button onClick={() => setSelectFolderVisible(true)} size="medium" color="primary" variant="contained" style={{...buttonStyle, marginLeft: (isMobile ? '0' : '8px') }}>
                    { isMobile ? 'Importar' : 'Importar material' }
                  </Button>
              </div>
            )
          }
        </DialogContent>
      </Dialog>

      <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
      <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
    </Container>
  );
}

export default ContentRepository;
