import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Link, Button, Tooltip, IconButton, Divider, Box, TextField, Container, Typography, Card, CardHeader, CardContent, CardActions, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import CustomTextField from './CustomTextField';
import useSnackbar from './useSnackbar';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useUser } from './UserContext';
import { useLocation } from 'react-router-dom';
import SelectFolder from './SelectFolder';
import { SummaryIcon } from './MainIcons';
import LinkToMaterial from './LinkToMaterial';

function NewSummary() {
  const token = localStorage.getItem('token');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { user, fetchUser } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folder = queryParams.get('folder');
  const [fetchingAllFolders, setFetchingAllFolders] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(folder);
  
  const fetchAllFolders = async () => {
    setFetchingAllFolders(true);

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(`https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/folders/all`, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.allFolders) {
        setFolders(response.data.allFolders);  
      } else {
        setMessageError('Erro ao buscar pasta.');
      }
    } catch (error) {
      setMessageError('Erro ao buscar pasta.');
    }

    setFetchingAllFolders(false);
  };

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipToggle = () => {
    if (!openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 5000);
    }

    setOpenTooltip(!openTooltip);
  };

  const handleGeneratePDF = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/requests', { type: 'summary', text, folder: currentFolderId }, {
        headers: { Authorization: token }
      });

      const requestsIds = response.data.requestsIds;

      setTimeout(() => {
        navigate(`/app/folders${currentFolderId ? ('?folder=' + currentFolderId) : ''}`);
      }, 3000);

      setMessageInfo('Em alguns segundos seu material estará pronto...');

      setTimeout(async () => {
        try {
          await fetchUser();
        } catch(err) { }
      }, 200);
      
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAllFolders();
  }, []);

  useEffect(() => {
    if (!currentFolderId) {
      setCurrentFolderId((folders && folders.length > 0) ? folders[0]._id : null);
    }
  }, [folders]);

  return (
      <>
      {
        /*
        <Typography component="h3" variant="h3" style={{ textAlign: 'center', fontSize: (isMobile ? '1.05rem' : '1.18rem'), fontWeight: '400', color: '#1671dc', marginTop: '30px', marginBottom: '15px' }}>
          { (user && user.name) ? `${user.name.split(' ')[0]}` : '...' }, você tem <span style={{ fontWeight: '700', marginLeft: '5px' }}>{(user && user.credits != null) ? `${user.credits} ` : '... ' } crédito{(user && user.credits == 1) ? '' : 's' }</span>
        </Typography>
        */
      } 
      <Container component="main" maxWidth="xs" style={{ marginBottom: '30px' }}>
        <Card sx={{ 
          marginTop: '15px',
          '@media (min-width:600px)': {
            marginTop: '40px' 
          } 
        }}>
          <CardHeader
            sx={{
              position: 'relative',
              paddingLeft: '20px',
              '&::before': {
                content: '""', 
                position: 'absolute',
                left: '-3px',
                top: '9px',
                bottom: '9px',
                width: '6px',
                backgroundColor: '#b1900e',
                borderRadius: '3px'
              }
            }}
            title={
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SummaryIcon color="#000000" style={{ marginRight: '6px' }} /> <span>Novo Resumo</span>
                </div>
              </>
            }
            subheader="Insira abaixo um assunto de sua escolha e em alguns segundos você terá o seu resumo"
          />
          <CardContent style={{ padding: '0' }}>
            <Box style={{ padding: '16px' }}>
              <CustomTextField
                variant="outlined"
                autoComplete="off"
                margin="normal"
                style={{ margin: '0' }}
                fullWidth
                id="text"
                label="Assunto"
                placeholder="Digite aqui o assunto"
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Box>


            <Box style={{ padding: '16px' }}>
              <SelectFolder 
                isLoading={fetchingAllFolders}
                folders={folders} 
                currentFolderId={currentFolderId}
                onSelect={setCurrentFolderId}
              />
            </Box>

            <Box style={{ 
              flexDirection: 'column', 
              padding: '12px',
              paddingLeft: '24px', 
              backgroundColor: '#f6f6f6'
            }}>
              <Typography variant="body1" style={{ fontSize: '11px', fontWeight: '600', color: '#222222' }}>
                Exemplos:
              </Typography>
              <Typography variant="body1" style={{ fontSize: '11px', fontWeight: '500', color: '#666666' }}>
                <LinkToMaterial isMobile={isMobile} text="Poder Constituinte (Direito Constitucional)" url="https://aprend-pedidos.s3.amazonaws.com/pdfs/poder_constituinte_(direito_constitucion_20231215195331.pdf" />
              </Typography>
              <Typography variant="body1" style={{ fontSize: '11px', fontWeight: '500', color: '#666666' }}>
                <LinkToMaterial isMobile={isMobile} text="Direito Administrativo - Conceitos e Definições" url="https://aprend-pedidos.s3.amazonaws.com/pdfs/direito_administrativo_-_conceitos_e_def_20231215195321.pdf" />
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button
              onClick={handleGeneratePDF}
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            >
                {loading ? 'Aguarde...' : 'Gerar Resumo'}
            </Button>

            <Typography variant="body1" style={{ fontSize: '12px', fontWeight: '500', color: '#666666', paddingRight: '8px', alignSelf: 'flex-end', paddingTop: '5px' }}>
              Essa operação custa 1 crédito
            </Typography>

            {user && user.credits == 0 && (
              <Button
                onClick={() => navigate('/app/buy-credits')}f
                fullWidth
                variant="contained"
                color="info"
                size="medium"
                style={{ marginTop: '8px', marginLeft: '0' }}
              >
                Comprar Créditos
              </Button>
            )}
          </CardActions>
        </Card>

        <Button
          onClick={() => navigate(`/app/folders${currentFolderId ? ('?folder=' + currentFolderId) : ''}`)}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '8px' }}
        >
          Voltar
        </Button>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />

      </Container>
      </>
  );
}


function NewSummaryWrapper() {
  return (
    <Suspense fallback={<div>Carregando créditos...</div>}>
      <NewSummary />
    </Suspense>
  );
}

export default NewSummaryWrapper;
