import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

function CustomSnackbar({ open, onClose, message, severity }) {
  const theme = useTheme();

  // Função para obter a cor do texto baseado na severity
  const getTextColor = (severity) => {
    switch (severity) {
      case 'error':
        return theme.palette.error.dark;
      case 'warning':
        return theme.palette.warning.dark;
      case 'info':
        return theme.palette.info.dark;
      case 'success':
        return theme.palette.success.dark;
      default:
        return theme.palette.text.primary;
    }
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert 
        onClose={onClose} 
        severity={severity}
        sx={{ 
          bgcolor: getTextColor(severity), // Cor de fundo branca
          color: '#ffffff', // Define a cor do texto baseada na severity
          '.MuiAlert-message': { // Estilização específica para a mensagem do Alert
            color: '#ffffff', // Define a cor do texto baseada na severity
          },
          boxShadow: 1, // Usa o nível 1 do box shadow do tema
          '& .MuiAlert-icon': {
            color: '#ffffff', // Define a cor dos ícones baseada na severity
          }
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
