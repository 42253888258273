import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = (visible) => {
    setShowMenu(visible);
  };

  return (
    <LayoutContext.Provider value={{ showMenu, toggleMenu }}>
      {children}
    </LayoutContext.Provider>
  );
};
