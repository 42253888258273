import React from 'react';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import SubjectIcon from '@mui/icons-material/Subject';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

interface IconRendererProps {
    type: 'mindmap' | 'summary' | 'flashcard';
}

const RequestTypeIcon: React.FC<IconRendererProps> = ({ type }) => {
    switch (type) {
        case 'mindmap':
            return <BubbleChartIcon />;
        case 'summary':
            return <SubjectIcon />;
        case 'flashcard':
            return <ViewCarouselIcon />;
        default:
            return null; // ou você pode renderizar um ícone padrão ou mensagem de erro aqui
    }
};

export default RequestTypeIcon;
