import React from 'react';
import { Link, Typography } from '@mui/material';

function LinkToMaterial({ isMobile, text, url }) {
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      <Typography component="span" style={{ fontSize: '0.7rem' }} variant="body1">
        <i class="bx bx-file" style={{ marginRight: '5px' }} />
        {text}
      </Typography>
    </Link>
  );
}

export default LinkToMaterial;
