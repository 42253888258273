import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { MindMapIcon, FlashcardIcon, SummaryIcon } from './MainIcons';

interface BadgeRendererProps {
    type: 'mindmap' | 'summary' | 'flashcard';
}

const RequestChip: React.FC<BadgeRendererProps> = ({ type }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const style = {
        fontSize: '9px',
        width: isMobile ? '16px' : '80px',
        textAlign: 'center',
        color: '#ffffff',
        borderRadius: '3px',
        fontWeight: '700',
        padding: '2px 0'
    };

    switch (type) {
        case 'mindmap':
            return <MindMapIcon color="#6937ea" />;
        case 'summary':
            return <SummaryIcon color="#b1900e" />;
        case 'flashcard':
            return <FlashcardIcon color="#499c6a" />;
        default:
            return null;
    }
};

export default RequestChip;
