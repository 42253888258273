import React from 'react';
import { Box, Button, Container, Grid, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useUser } from './UserContext';

function Sudo() {
  const navigate = useNavigate();
  const { user } = useUser();

  const IconButtonWithText = ({ children, color, mainText, secondaryText, buttonText, onClick }) => {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          cursor: 'pointer', 
          padding: '20px 30px 30px 30px',
          borderRadius: '10px', 
          backgroundColor: '#ffffff',
          border: '1px solid #ffffff',
          minHeight: '100%',
          justifyContent: 'space-between',
          '&:hover': {
            border: '1px solid #cccccc',
          },
          '@media (min-width:600px)': {
            padding: '20px 20px 30px 20px'
          } 
        }} 
        onClick={onClick}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          {children}
          <Typography variant="body1" sx={{ marginTop: '15px', color: '#666666', fontWeight: '700', fontSize: '18px' }}>
            {mainText}
          </Typography>
          <Typography sx={{ textAlign: 'center', '@media (min-width:600px)': { textAlign: 'left' }, marginTop: '15px', color: '#666666', fontWeight: '500', fontSize: '13px' }}>
            {secondaryText}
          </Typography>
        </Box>
        <Box sx={{ alignSelf: 'center', marginTop: '15px' }}>
          <Typography sx={{ color: '#ffffff', fontWeight: '600', fontSize: '12px', borderRadius: '6px', background: color, padding: '6px 15px' }}>
            {buttonText}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
      <Container component="main" maxWidth="md">
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexDirection: 'column',
            margin: '15px 0 30px 0',
            '@media (min-width:600px)': {
              margin: '40px',
            } 
          }}>
          <Typography component="h3" variant="h3" style={{ fontWeight: '700', color: '#444444', marginTop: '5px' }}>
            Administração
          </Typography>
        </Box>

        <Grid container spacing={2} justifyContent="center" alignItems="stretch">
          <Grid item xs={12} md={4}>
            <IconButtonWithText
              mainText="Acervo"
              color="#6938ea"
              secondaryText="Gere, importe, edite, categorize e publique os materiais do acervo"
              buttonText="Gerenciar materiais"
              onClick={() => navigate('/sudo/manage-subjects')}
            >
              <i className="bx bx-book-content" style={{ color: '#6938ea', fontSize: '36px' }} />
            </IconButtonWithText>
          </Grid>


          <Grid item xs={12} md={4}>
            <IconButtonWithText
              mainText="Cursos por pasta"
              color="#cc0000"
              secondaryText="Gerencie e organize cursos por pasta para o Hotmart"
              buttonText="Gerenciar editais"
              onClick={() => navigate('/sudo/manage-folder-courses')}
            >
              <i className="bx bx-folder" style={{ color: '#cc0000', fontSize: '36px' }} />
            </IconButtonWithText>
          </Grid>


          <Grid item xs={12} md={4}>
            <IconButtonWithText
              mainText="Atualizar mapa inteligente"
              color="#179da3"
              secondaryText="Atualize o conteúdo de um mapa inteligente específico"
              buttonText="Atualizar mapa"
              onClick={() => navigate('/sudo/update-mindmap')}
            >
              <i className="bx bx-save" style={{ color: '#179da3', fontSize: '36px' }} />
            </IconButtonWithText>
          </Grid>

          {
          /*
            <Grid item xs={12} md={4}>
              <IconButtonWithText
                mainText="Editais"
                color="#cc0000"
                secondaryText="Crie, edite, organize e publique os editais"
                buttonText="Gerenciar editais"
                onClick={() => navigate('/sudo/manage-editais')}
              >
                <i className="bx bx-file" style={{ color: '#cc0000', fontSize: '36px' }} />
              </IconButtonWithText>
            </Grid>
          */
          }

          <Grid item xs={12} md={4}>
            
          </Grid>
        </Grid>

      </Container>
  );
}

export default Sudo;
