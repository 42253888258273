import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Typography, List, ListItem, ListItemText, ListItemIcon, IconButton, Breadcrumbs, Link } from '@mui/material';
import { Folder, ArrowBack, ArrowForward } from '@mui/icons-material';

export const findFolderById = (folderId, foldersList) => {
  for (const folder of foldersList) {
    if (folder._id === folderId) {
      return folder;
    }
    if (folder.children) {
      const found = findFolderById(folderId, folder.children);
      if (found) return found;
    }
  }
  return null;
};

const FolderNavigator = ({ folders, initialFolderId, onChange }) => {
  const [currentFolder, setCurrentFolder] = useState((folders && folders.length == 1) ? folders[0] : null);
  const [breadcrumb, setBreadcrumb] = useState([]);

  useLayoutEffect(() => {
    let path = [];
    let tempFolder = currentFolder;
    while (tempFolder) {
      path.push(tempFolder);
      tempFolder = findFolderById(tempFolder.parent, folders);
    }
    setBreadcrumb(path);
  }, [currentFolder, folders]);

  useLayoutEffect(() => {
    const _folder = findFolderById(initialFolderId, folders);
    setCurrentFolder(_folder || null);
  }, [initialFolderId]);

  const enterFolder = (folder) => {
    setCurrentFolder(folder);
  };

  const goBack = () => {
    if (currentFolder && currentFolder.parent) {
      const parentFolder = findFolderById(currentFolder.parent, folders);
      setCurrentFolder(parentFolder);
    } else {
      setCurrentFolder(null);
    }
  };

  useEffect(() => {
    onChange(currentFolder);
  }, [currentFolder]);

  const foldersToShow = currentFolder ? currentFolder.children : folders;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" style={{ color: '#666666' }}>
        {breadcrumb.map((folder, index) => (
          (0 == index) ?
          <Typography
            key={folder._id} 
            style={{ display: 'flex', alignItems: 'center', fontWeight: '600', color: '#136880', fontSize: '0.9rem' }}
          >
            <span style={{ position: 'relative', display: 'flex', marginRight: '8px' }}>
              <i className="bx bxs-folder" style={{ fontSize: '20px', color: '#136880' }} />
              <i className="bx bx-check" style={{
                  position: 'absolute',
                  fontSize: '16px',
                  color: '#ffffff',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
              }} />
            </span>
            {folder.name}
          </Typography>
          :
          <Link 
            key={folder._id} 
            color="inherit" 
            onClick={() => setCurrentFolder(folder)}
            style={{ cursor: 'pointer', color: '#666666', fontSize: '0.9rem' }}
          >
            {folder.name}
          </Link>
        ))}
      </Breadcrumbs>

      <div style={{ paddingLeft: '12px' }}>
        <List>
          {currentFolder && currentFolder.parent && (
            <ListItem button onClick={goBack} sx={{ padding: '8px', borderRadius: '8px', '&:hover': { backgroundColor: '#e5e5e5' } }}>
              <i className="bx bx-reply" style={{ fontSize: '23px', color: '#999999', marginRight: '8px' }} />
              <ListItemText primary=".." sx={{ 
                '.MuiListItemText-primary': {
                  fontSize: '0.9rem' 
                }
              }} />
            </ListItem>
          )}
          {foldersToShow && foldersToShow.map((folder) => (
            <ListItem button key={folder._id} onClick={() => enterFolder(folder)} sx={{ padding: '8px', borderRadius: '8px', '&:hover': { backgroundColor: '#e5e5e5' } }}>
              <i className="bx bx-folder" style={{ fontSize: '22px', marginRight: '8px', color: '#999999' }} />
              <ListItemText primary={folder.name} sx={{ 
                '.MuiListItemText-primary': {
                  fontSize: '0.9rem' 
                }
              }} />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};

export default FolderNavigator;
