import React from 'react';
import { Grid, Typography } from '@mui/material';

function RowComponent({ title, value }) {
  return (
  	<div style={{ background: '#ffffff', border: '1px solid #dddddd', borderRadius: '5px', padding: '10px 20px' }}>
		<Grid container spacing={2}>
		  <Grid item xs={6}>
		    <Typography variant="body1" style={{ fontSize: '13px', fontWeight: '700' }}>
		      {title}
		    </Typography>
		  </Grid>
		  <Grid item xs={6}>
		    <Typography variant="body1" style={{ textAlign: 'right', fontSize: '12px', fontWeight: '500' }}>
		      {value}
		    </Typography>
		  </Grid>
		</Grid>
    </div>
  );
}

export default RowComponent;
