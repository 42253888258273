import { useState } from 'react';

function useSnackbar() {
  const [messageInfo, setMessageInfo] = useState('');
  const [messageError, setMessageError] = useState('');

  return {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo: () => setMessageInfo(''),
    clearMessageError: () => setMessageError('')
  };
}

export default useSnackbar;
