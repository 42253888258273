import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CreditsDisplay from './CreditsDisplay';
import { Skeleton, Box, Button, Container, Grid, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from './CustomSnackbar';
import useSnackbar from './useSnackbar';

function Credits() {
  const [credits, setCredits] = useState(null);
  const [totalCredits, setTotalCredits] = useState(null);
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [intialized, setIntialized] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const fetchCredits = async () => {
    try {
      const response = await axios.get('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/payment/credits-balance', {
        headers: { Authorization: token }
      });

      setCredits(response.data.credits);
      setTotalCredits(response.data.totalCredits);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);  
      } else {
        setMessageError('Erro ao carregar créditos. Por favor, tente novamente.');
      }
    }
  };

  const fetchTransactionHistory = async () => {
    try {
      const response = await axios.get('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/payment/credits-sales-history', {
        headers: { Authorization: token }
      });
      setTransactionHistory(response.data.transactions);
    } catch (error) {
      setMessageError('Erro ao buscar histórico. Por favor, tente novamente.');
    }
  };

  useEffect(() => {
    fetchCredits();
    fetchTransactionHistory();
  }, [token]);

  useEffect(() => {
    if (!intialized && (credits != null && credits != undefined) && transactionHistory) {
      setIntialized(true);  
    }
  }, [credits, transactionHistory]);

  return (
      <Container component="main" maxWidth="xs">
        {
          !intialized ?
          (
            <div style={{ marginTop: '30px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ marginBottom: '15px' }} />
              <Skeleton variant="rectangular" width="100%" height={70} style={{ borderRadius: '4px' }} />
            </div>
          ) :
          (
            <>
              <Card sx={{ 
                marginTop: '15px',
                '@media (min-width:600px)': {
                  marginTop: '40px' 
                } 
              }}>
                <CardHeader
                  title="Saldo atual"
                />
                <CardContent>
                  <CreditsDisplay credits={credits} totalCredits={totalCredits} />
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => navigate(`/app/buy-credits`)}
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    Comprar créditos de IA
                  </Button>
                </CardActions>
              </Card>

              <div style={{ marginTop: '30px' }}>
                <Typography variant="h4" style={{ marginBottom: '15px', fontSize: '18px' }}>Últimas de Compras</Typography>

                {
                  (!transactionHistory || transactionHistory.length == 0) &&
                  <Box style={{ padding: '8px 16px', borderRadius: '4px', background: 'rgba(255, 255, 255, 0.8)', textAlign: 'center' }}>
                    <Typography variant="h6" component="span" style={{ color: '#165493', fontWeight: '700' }}>
                      Você ainda não comprou créditos de IA
                    </Typography>
                  </Box>
                }

                {transactionHistory && transactionHistory.map((transaction, index) => (
                  <Grid container key={index} style={{ padding: '8px 16px', borderRadius: '4px', background: ((index%2==0) ? 'rgba(255, 255, 255, 0.8)' : 'transparent') }}>
                    <Grid item xs={8}>
                      <Typography variant="body2" style={{fontSize: '11px', fontWeight: '500', color: '#888888' }}>
                        {format(new Date(transaction.date), 'dd/MMM/yyyy HH:mm', { locale: ptBR }).toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      <Typography variant="body2" style={{ fontSize: '11px', fontWeight: '600', color: '#57678b' }}>
                        {transaction.amount} créditos
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </div>

              <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
              <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
            </>
          )
        }
      </Container>
  );
}

export default Credits;
