import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, List, ListItem, ListItemText,
  Drawer, Divider, Typography, Box, useTheme, useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PixIcon from '@mui/icons-material/Pix';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ListIcon from '@mui/icons-material/List';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useUser } from './UserContext';
import { useLayout } from './LayoutContext';
import { MindMapIcon, FlashcardIcon, SummaryIcon, HomeIcon, 
  EmailIcon, CoinIcon, FaqIcon, ExitIcon, FolderIcon,
  ArchiveIcon } from './MainIcons';
import CircularProgress from '@mui/material/CircularProgress';

function Layout({ children }) {
  const { showMenu } = useLayout();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('token');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [permanentDrawer, setPermanentDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user, setUser, isLoading } = useUser();

  useEffect(() => {
    setPermanentDrawer(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isAuthenticated && location.pathname.startsWith('/app')) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate, location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  const handleUserCreditsClick = () => {
    if (location.pathname !== '/app/credits') {
      navigate('/app/credits');  
    }

    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  const menuItems = [
    user && !user.onlyFolderCourses && { label: 'Início', path: '/app/home', svgIcon: (color) => <HomeIcon color={color} /> },
    user && !user.onlyFolderCourses && { group: 'Ferramentas Inteligentes' },
    user && !user.onlyFolderCourses && { label: 'Mapa Inteligente', path: '/app/new-smartmap', secondaryPath: '/app/new-smartmap-from-content', svgIcon: (color) => <MindMapIcon color={color} /> },
    user && !user.onlyFolderCourses && { label: 'Flashcard', path: '/app/new-flashcard', secondaryPath: '/app/new-flashcard-from-content', svgIcon: (color) => <FlashcardIcon color={color} /> },
    user && !user.onlyFolderCourses && { label: 'Resumo', path: '/app/new-summary', svgIcon: (color) => <SummaryIcon color={color} /> },
    user && !user.onlyFolderCourses && { group: 'Geral' },
    //{ label: 'Acervo Completo', path: '/app/repository', svgIcon: (color) => <ArchiveIcon color={color} /> },
    { label: 'Meus Materiais', path: '/app/folders?folder=', svgIcon: (color) => <FolderIcon color={color} /> },
    user && !user.onlyFolderCourses && { label: 'Créditos de IA', path: '/app/credits', svgIcon: (color) => <CoinIcon color={color} /> },
    { label: 'Suporte', path: '/app/send-message', svgIcon: (color) => <EmailIcon color={color} /> }
  ];

  const mainStyle = {
    marginTop: (permanentDrawer || !showMenu) ? '0' : '64px',
    marginLeft: (permanentDrawer && showMenu) ? 240 : 0,
    minHeight: showMenu ? `calc(100vh - 64px)` : '100vh',
    width: `calc(100% - ${(permanentDrawer && showMenu) ? 240 : 0}px)`,
    overflow: 'auto',
    transition: theme.transitions.create(['margin', 'marginTop'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  const listItemStyle = {
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  };

  const selectedListItemStyle = {
    backgroundColor: 'inherit',
    color: '#a0ecf6',
    boxShadow: 'inset 0.15em 0 0 #a0ecf6',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  };

  const appBarSyle = { 
    boxShadow: 'none',
    background: '#1e242a'
  };

  const dividerStyle = { 
    border: 'none', 
    height: '1px', 
    backgroundColor: '#404249', 
    marginTop: '8px', 
    marginBottom: '8px'  
  };

  const isSelected = item => {
    return (
      location.pathname === item.path || 
      location.pathname === item.secondaryPath || 
      (item.path.includes('?') && item.path.startsWith(location.pathname)) || 
      (item.secondaryPath && item.secondaryPath.includes('?') && item.secondaryPath.startsWith(location.pathname))
    );
  }

  return (
    <>
    {
      (isLoading && !user) ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
            <CircularProgress style={{ width: '100px', height: '100px', color: '#888888' }} />
        </div>
      ) : 
      <>
      <div className="layout-container">
        {permanentDrawer}
        {showMenu && (
          <>
            {
              !permanentDrawer &&
              <AppBar position="fixed" style={appBarSyle}>
                <Toolbar style={{ display: 'flex', alignItems: 'center', paddingRight: '0', paddingLeft: '0' }}>
                  <IconButton style={{ marginLeft: '15px' }} edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
                    <i className="bx bx-menu" style={{ fontSize: '20px', color: '#ffffff' }}></i>
                  </IconButton>

                  <Box style={{
                    paddingLeft: '0',
                    paddingTop: '9px',
                    width: 'auto',
                    height: '64px'
                  }}>
                    <img src="/app_logo.png" alt="Estudo Inteligente" style={{ height: '40px' }} />
                  </Box>
                  {user && !user.onlyFolderCourses && !drawerOpen && (
                    <Box sx={{ 
                      marginLeft: 'auto', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      justifyContent: 'center',
                      padding: '0 20px', 
                      backgroundColor: 'rgba(255, 255, 255, 0.03)',
                      height: '64px',
                      cursor: 'pointer'
                    }}
                    onClick={handleUserCreditsClick}>
                      <span style={{ background: '#3e5065', padding: '3px 4px', borderRadius: '8px' }}>
                        <Typography variant="body2" sx={{ display: 'flex', fontWeight: '600', alignItems: 'center', color: '#ffffff', fontSize: '12px' }}>
                          {user.credits}
                          <CoinIcon size={14} color="#ffffff" style={{ marginLeft: '3px' }} />
                        </Typography>
                      </span>
                    </Box>
                  )}
                </Toolbar>
              </AppBar>
            }
            <Drawer
              variant={permanentDrawer ? "permanent" : "temporary"}
              anchor="left"
              open={permanentDrawer || drawerOpen}
              onClose={() => setDrawerOpen(false)}
              sx={{
                width: 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  backgroundColor: '#282f36', 
                  color: '#ffffff', 
                  boxShadow: 'none',
                  width: 240,
                  boxSizing: 'border-box',
                  borderRight: '1px solid #373f49',
                },
              }}
            >
              <Box style={{
                  paddingLeft: '0',
                  paddingTop: '13px',
                  paddingBottom: '8px',
                  width: 240,
                  backgroundColor: '#1e242a'
                }}>
                  <img src="/app_logo.png" alt="Estudo Inteligente" style={{ height: permanentDrawer ? '42px' : '40px', marginLeft: permanentDrawer ? '10px' : '0' }} />
                </Box>

              <Box
                display="flex"
                flexDirection="column"
                height="calc(100%)"
              >
                {
                  (
                    <Box flex="1">
                      <List>
                        {menuItems.map((item, index) => {
                          if (!item) return null;

                          if (item.divider) {
                            return <Divider key={"divider-" + index} style={dividerStyle} />;
                          } else if (item.group) {
                            return <ListItem
                                key={index}
                                style={{ padding: '6px 0 6px 22px' }}
                              >
                                <ListItemText sx={{ '.MuiListItemText-primary': { color: '#a2adaf', fontSize: '0.7rem', fontWeight: '700' } }} primary={item.group} />
                              </ListItem>;
                          } else {
                            return (
                              <ListItem
                                button
                                key={index}
                                onClick={() => {
                                  if (item.path) {
                                    navigate(item.path);
                                  } else if (item.action) {
                                    item.action();
                                  }
                                  setDrawerOpen(false);
                                }}
                                sx={isSelected(item) ? selectedListItemStyle : listItemStyle}
                                style={{ padding: '6px 0 6px 32px' }}
                              >
                                {
                                  item.iconClass &&
                                  <i className={`bx bx-${item.iconClass}`} style={{ fontSize: '20px' }}></i>
                                }
                                {
                                  !item.iconClass && item.svgIcon &&
                                  item.svgIcon( isSelected(item) ? '#a0ecf6' : '#ffffff')
                                }
                                <ListItemText sx={{ '.MuiListItemText-primary': { marginLeft: '12px', fontSize: '0.8rem', fontWeight: location.pathname === item.path ? '500' : '500', } }} primary={item.label} />
                              </ListItem>
                            );
                          }
                        })}
                      </List>
                    </Box>
                  )
                }

                <Box style={{ paddingBottom: '15px' }}>
                  {
                    user && !user.onlyFolderCourses && (
                      <Box sx={{ 
                        marginBottom: '10px', 
                        padding: '7px 10px', 
                        borderRadius: '8px',
                        background: '#1e242a',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: '30px',
                        marginRight: '30px',
                        cursor: 'pointer'
                      }}
                      onClick={handleUserCreditsClick}>
                        <>
                          <Typography component="span" sx={{ color: '#a0ecf6', fontWeight: '700', fontSize: '12px' }}>
                            Créditos de IA
                          </Typography>  
                          <Typography variant="span" sx={{ display: 'flex', alignItems: 'center', color: '#ffffff', fontWeight: '600', fontSize: '12px' }}>
                            {user.credits}
                            <CoinIcon size={14} color="#ffffff" style={{ marginLeft: '3px' }} />
                          </Typography>
                        </>
                      </Box>
                    )
                  }                

                  {
                    user && !user.onlyFolderCourses &&
                    (
                      <ListItem
                        button
                        onClick={() => {
                          navigate('/app/questions-answers');
                          setDrawerOpen(false);
                        }}
                        sx={location.pathname === '/app/questions-answers' ? selectedListItemStyle : listItemStyle}
                        style={{ padding: '6px 0 6px 32px' }}
                      >
                        <FaqIcon color={location.pathname === '/app/questions-answers' ? '#a0ecf6' : '#ffffff'} />
                        <ListItemText sx={{ '.MuiListItemText-primary': { marginLeft: '12px', fontSize: '0.8rem', fontWeight: location.pathname === '/app/questions-answers' ? '500' : '500', } }} primary="Perguntas e Respostas" />
                      </ListItem>
                    )
                  }

                  <ListItem
                    button
                    onClick={handleLogout}
                    sx={listItemStyle}
                    style={{ padding: '6px 0 6px 32px', ...(user && (user.onlyFolderCourses == true) ? { marginTop: '30px' } : {}) }}
                  >
                    <ExitIcon color={location.pathname === '/app/questions-answers' ? '#a0ecf6' : '#ffffff'} />
                    <ListItemText sx={{ '.MuiListItemText-primary': { marginLeft: '12px', fontSize: '0.8rem', fontWeight: '500' } }} primary="Encerrar Sessão" />
                  </ListItem>
                </Box>
              </Box>
            </Drawer>
          </>
        )}

        <main className="layout-content" style={mainStyle}>
          {children}
        </main>

        <style jsx>{`
          .layout-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .layout-content {
            width: 100%;
          }
        `}</style>
      </div>
      </>      
    }
    </> 
  );
}

export default Layout;