import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CreditsDisplay from './CreditsDisplay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton, Box, Button, Container, Grid, Typography, Select, MenuItem, FormControl, InputLabel, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import CustomSnackbar from './CustomSnackbar';
import IntegerField from './IntegerField';
import PriceComponent from './PriceComponent';
import useSnackbar from './useSnackbar';

function BuyCredits() {
   const prices = {
    10: 9.0,
    25: 20.0,
    50: 35.0,
    100: 60.0
  };

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState();
  const [amount, setAmount] = useState(50);
  const navigate = useNavigate();
  const {
    messageInfo,
    setMessageInfo,
    messageError,
    setMessageError,
    clearMessageInfo,
    clearMessageError
  } = useSnackbar();
  
  const token = localStorage.getItem('token');

  useEffect(() => {
    setPrice(prices[amount] || 0);
  }, [amount]);

  const handleBuyCredits = async () => {
    setLoading(true);

    try {
      const response = await axios.post('https://nu362t0oa2.execute-api.us-east-2.amazonaws.com/prod/payment/buy-credits', {
        amount
      }, {
        headers: { Authorization: token }
      });

      if (response && response.data && response.data.transactionId) {
        setMessageInfo('Redirecionando para o pagamento...');

        setTimeout(function() {
          navigate(`/app/transaction/${response.data.transactionId}`);
        }, 1500);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setMessageError(error.response.data.error);
      } else {
        setMessageError('Ocorreu um erro. Por favor, tente novamente.');
      }
    }

    setLoading(false);
  };

  return (
      <Container component="main" maxWidth="xs">
        <Card sx={{ 
          marginTop: '15px',
          '@media (min-width:600px)': {
            marginTop: '40px' 
          } 
        }}>
          <CardHeader
            title="Comprar créditos de IA"
            subheader="Quantos créditos de IA você deseja comprar?"
          />
          <CardContent>
            <FormControl variant="outlined" margin="normal" fullWidth style={{ margin: '0' }}>
              <Select
                labelId="amount-label"
                id="amount"
                value={amount}
                required
                IconComponent={ExpandMoreIcon} 
                onChange={(e) => setAmount(e.target.value)}
              >
                <MenuItem value={10}>10 créditos</MenuItem>
                <MenuItem value={25}>25 créditos</MenuItem>
                <MenuItem value={50}>50 créditos</MenuItem>
                <MenuItem value={100}>100 créditos</MenuItem>
              </Select>
            </FormControl>

            <div style={{ marginTop: '16px' }}>
              <PriceComponent price={price}  />
            </div>
          </CardContent>
          <CardActions>
            <Button
              onClick={handleBuyCredits}
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              disabled={loading}
            >
              {loading ? 'Aguarde...' : 'Ir para o pagamento'}
            </Button>
          </CardActions>
        </Card>

        <Button
          onClick={() => navigate('/app/credits')}
          fullWidth
          color="info"
          size="medium"
          style={{ marginTop: '10px' }}
        >
          Créditos de IA
        </Button>

        <CustomSnackbar open={Boolean(messageInfo)} onClose={clearMessageInfo} message={messageInfo} severity="info" />
        <CustomSnackbar open={Boolean(messageError)} onClose={clearMessageError} message={messageError} severity="error" />
      </Container>
  );
}

export default BuyCredits;
