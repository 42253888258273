const setupFonts = () => {
  const poppinsFont = new FontFace('Poppins', 'url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJbecmNE.woff2)');
  document.fonts.add(poppinsFont);
  poppinsFont.load();

  const montserratFont = new FontFace('Montserrat', 'url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXp-p7K4KLg.woff2)');
  document.fonts.add(montserratFont);
  montserratFont.load();
};

export default setupFonts;
