import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    h1: {
      fontWeight: '600',
      fontSize: '1.8rem', 
    },
    h2: {
      fontWeight: '600',
      fontSize: '1.3rem', 
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      } 
    },
    h3: {
      fontWeight: '600',
      fontSize: '1.18rem',
      '@media (min-width:600px)': {
        fontSize: '1.3rem', 
      } 
    },
    h4: {
      fontWeight: '600',
      fontSize: '1.05rem',
      '@media (min-width:600px)': {
          fontSize: '1.15rem', 
      } 
    },
    h5: {
      fontSize: '0.95rem', 
      fontWeight: '700',
    },
    h6: {
      fontSize: '0.8rem', 
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: "#d9d9d9",
      paper: "#f6f6f6",
    },
    primary: {
      main: "#30445a",
      contrastText: "#ffffff",
      light: "#3c597a"
    },
    secondary: {
      main: "#1f70c3",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2e93a1",
      contrastText: "#ffffff",
      light: "#71D0DD"
    },
    success: {
      main: "#48906b",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#da950f"
    },
    error: {
      main: "#e23030",
      light: "#f54040f",
      contrastText: "#ffffff",
    },
    gray: {
      main: '#888888',
      light: '#aaaaaa',
      dark: '#444444',
      contrastText: '#ffffff',
    },
    blue: {
      main: '#322c96',
      light: '#4c45bd',
      dark: '#161165',
      contrastText: '#ffffff',
    },
    mindmap: {
      main: '#6937ea',
      light: '#7443f1',
      dark: '#3d10b1',
      contrastText: '#ffffff',
    },
    summary: {
      main: '#b1900e',
      light: '#f8ce2b',
      dark: '#866b02',
      contrastText: '#ffffff',
    },
    flashcard: {
      main: '#499c6a',
      light: '#49d580',
      dark: '#126834',
      contrastText: '#ffffff',
    },
    danger: {
      main: '#784f4f',
      light: '#d6b7b7',
      dark: '#683333',
      contrastText: '#ffffff',
    },
    white: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff',
      contrastText: '#2e93a1',
    },
    action: {
      active: "#ffffff", 
      hover: "rgba(0, 58, 117, 0.1)",
    },
    text: {
      primary: "#444444",
      secondary: "#1f70c3"
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#999999',
        },
        iconOpen: {
          color: '#409da9',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
          boxShadow: '0 0 0 0.06em #dddddd'
        },
        selected: {
          backgroundColor: '#ffffff',
        }
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '6px 8px',
          backgroundColor: '#1e242a',
          color: '#ffffff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            '& input': {
              fontSize: 16,
            },
            '& textarea': {
              fontSize: 16,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#666666',
          '& svg': {
            color: '#666666',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '6px 14px',
          fontSize: '0.8rem',
          backgroundColor: '#ffffff',
          color: '#666666',
          '&:hover': {
            backgroundColor: '##f1f1f1',
          },
          '&.Mui-selected': {
            backgroundColor: '#ffffff',
            color: '#409da9',
            fontWeight: '500',
            '&:hover': {
              backgroundColor: '##f1f1f1',
            },
            '&::before': {
              content: '""', 
              position: 'absolute',
              left: '-4px',
              top: '5px',
              bottom: '5px',
              width: '8px',
              backgroundColor: '#409da9',
              borderRadius: '4px'
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: '#ffffff',
            '&::before': {
              content: '""', 
              position: 'absolute',
              left: '-4px',
              top: '5px',
              bottom: '5px',
              width: '8px',
              backgroundColor: '#888888',
              borderRadius: '4px'
            },
          },
          '&.Mui-focusVisible.Mui-selected': {
            backgroundColor: '#ffffff',
            '&::before': {
              content: '""', 
              position: 'absolute',
              left: '-4px',
              top: '5px',
              bottom: '5px',
              width: '8px',
              backgroundColor: '#409da9',
              borderRadius: '4px'
            },
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          boxShadow: ' none',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '16px',
          borderBottom: '1px solid #eaeaea'
        },
        title: {
          fontSize: '1.1rem',
          color: '#444444',
          fontWeight: '700'
        },
        subheader: {
          fontSize: '0.8rem',
          color: '#888888',
          fontWeight: '500'
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '16px',
          borderTop: '1px solid #eaeaea',
          flexDirection: 'column', 
          alignItems: 'flex-start'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          minWidth: '36px',
          textTransform: 'none',
          '@media (max-width:600px)': {
            fontSize: 16,
          },
        },
        sizeLarge: {
          padding: '12px 20px',
          fontWeight: '700'
        },
        sizeMedium: {
          padding: '8px 16px',
          fontWeight: '600'
        }
      }
    },
    MuiInputBase: { 
      styleOverrides: {
        input: {
          fontSize: '0.9rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d5d5d5',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#888888', 
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#46a5b2',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.Mui-focused': {
            color: '#46a5b2',
            borderColor: '#46a5b2',
          },
        },
      },
    },
  }
});

export default theme;
